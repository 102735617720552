import { memo, useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react';
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { CSVLink } from 'react-csv';
import { useTranslation } from "react-i18next";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as Images from "../../../images";
import ClinCellEditor from "./customCellEditor";

const faceField = {
  faceNo: {
    field: "faceNo",
    label: "Face No.",
    isIntVal: true
  },
  depth: {
    field: "depth",
    label: "Depth (m)",
    isFloatVal: true
  },
  memo_1: {
    field: "memo_1",
    label: "memo_1",
  },
  memo_2: {
    field: "memo_2",
    label: "memo_2"
  },
}
const markerField = {
  markerId: {
    field: "markerId",
    label: "Marker ID",
    isIntVal: true
  },
  X: {
    field: "X",
    label: "x (m)",
    isFloatVal: true
  },
  Z: {
    field: "Z",
    label: "z (m)",
    isFloatVal: true
  },
  size: {
    field: "size",
    label: "Size (cm)",
    isFloatVal: true
  },
  memo_1: {
    field: "memo_1",
    label: "memo_1"
  },
  memo_2: {
    field: "memo_2",
    label: "memo_2"
  },
}

const minMarkerSize = 1;
const maxMarkerSize = 100;

const faceKirahaMode = "face";
const markerKirihaMode = "marker";

const NoRowsOverlay = () => {
  return (
    <div
      className="text-no-data"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        fontSize: '16px',
        color: '#888',
      }}
    >
      No data available
    </div>
  );
};

const KirihaEditTableComponent = (props) => {
  const { isOpenEditKiriha, setIsOpenEditKiriha, kirihaData, handleUpdate, csvUploadedInfo, mode, dataCsv, isReadOnly } = props;

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [undoSize, setUndoSize] = useState(0);
  const [isInValid, setIsInValid] = useState(false);
  const [isInValidMarkerSize, setIsInValidMarkerSize] = useState(false);
  const [isInValidMarkerSizeOnSave, setIsInValidMarkerSizeOnSave] = useState(false);
  const [isCheckOnly, setIsCheckOnly] = useState(false);
  const [isShowExpanation, setIsShowExpanation] = useState(false);
  

  const initFaceKirahaTable = [
    {
      headerName: faceField.faceNo.label,
      field: faceField.faceNo.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} isCheckOnlyData onChangeIsCheckOnly={setIsCheckOnly} />,
      valueSetter: params => {
        setIsInValid(false);
        setIsCheckOnly(false);
        const { newValue, colDef: { min, max, field, isCheckOnly } } = params;
        const val = parseInt(newValue);
        
        if (val < min || val > max || isCheckOnly) return false;
        params.data[field] = isNaN(val) ? null : val;
        return true;
      },
      width: 100,
      min: 0,
      max: 999,
    },
    {
      headerName: faceField.depth.label,
      field: faceField.depth.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 110,
      min: 0.0,
      max: 9999.9,
    },
    {
      headerName: faceField.memo_1.label,
      field: faceField.memo_1.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
    {
      headerName: faceField.memo_2.label,
      field: faceField.memo_2.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
  ];
  const initMarkerKirahaTable = [
    {
      headerName: markerField.markerId.label,
      field: markerField.markerId.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} isCheckOnlyData onChangeIsCheckOnly={setIsCheckOnly} />,
      valueSetter: params => {
        setIsInValid(false);
        setIsCheckOnly(false);
        const { newValue, colDef: { min, max, field, isCheckOnly } } = params;
        const val = parseInt(newValue);
        if (val < min || val > max || isCheckOnly) return false;
        params.data[field] = isNaN(val) ? null : val;
        return true;
      },
      width: 110,
      min: 0,
      max: 999
    },
    {
      headerName: markerField.X.label,
      field: markerField.X.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} isNegativeData />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 100,
      min: -9999.9,
      max: 9999.9
    },
    {
      headerName: markerField.Z.label,
      field: markerField.Z.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(1);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(1) : null;
      },
      width: 100,
      min: 0,
      max: 9999.9
    },
    {
      headerName: markerField.size.label,
      field: markerField.size.field,
      cellDataType: "number",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValidMarkerSize} />,
      valueSetter: params => {
        setIsInValidMarkerSize(false);
        const { newValue, colDef: { min, max, field } } = params;
        const val = parseFloat(newValue).toFixed(2);
        if (val < min || val > max) return false;
        params.data[field] = isNaN(val) ? null : +val;
        return true;
      },
      valueFormatter: params => {
        const { value } = params;
        return value || value === 0 ? value.toFixed(2) : null;
      },
      width: 100,
      min: minMarkerSize,
      max: maxMarkerSize
    },
    {
      headerName: markerField.memo_1.label,
      field: faceField.memo_1.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
    {
      headerName: markerField.memo_2.label,
      field: faceField.memo_2.field,
      cellDataType: "string",
      cellEditor: params => <ClinCellEditor params={params} onChangeIsInValid={setIsInValid} />,
      valueSetter: params => {
        setIsInValid(false);
        const { newValue, colDef: { maxLength, field } } = params;
        const length = newValue.length;
        if (length > maxLength) return false;
        params.data[field] = newValue;
        return true;
      },
      maxLength: 64
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      editable: isReadOnly ? false : true,
      enableCellChangeFlash: true,
      sortable: false,
      resizable: false,
    };
  }, []);

  const heightTable = useMemo(() => {
    if (rowData.length) {
      return {
        height: 40 + rowData.length * 40 + 3
      }
    }
    return {
      height: 80
    }
  }, [rowData])

  const containerRef = useRef(null);
  const gridRef = useRef(null);
  const actionsRowRef = useRef(null);

  useEffect(() => {
    let fieldMode, columnDefsDefault;
    switch (mode) {
      case faceKirahaMode:
        fieldMode = faceField;
        columnDefsDefault = initFaceKirahaTable;
        setColumnDefs(initFaceKirahaTable);
        break;
      case markerKirihaMode:
        fieldMode = markerField;
        columnDefsDefault = initMarkerKirahaTable;
        setColumnDefs(initMarkerKirahaTable);
        break;
      default: break;
    }

    if (kirihaData.length && kirihaData[1] && kirihaData[1].split(',').some(Boolean)) {
      const kirihaDataHandle = [...kirihaData];
      kirihaDataHandle.shift();
      const rowDataCustom = kirihaDataHandle.map((item) => {
        const itemMap = item.split(',');
        const record = Object.keys(fieldMode).reduce((prev, val, ind) => ({
          ...prev,
          [val]: itemMap[ind] ?
            fieldMode[val].isIntVal ? parseInt(itemMap[ind]) :
            fieldMode[val].isFloatVal ? parseFloat(itemMap[ind]) :
            itemMap[ind]
            : undefined
        }), {})
        return record;
      })

      setRowData(rowDataCustom);
    } else {
      const fields = columnDefsDefault.map(item => item.field);
      const record = fields.reduce((prev, item) => {
        return { ...prev, [item]: undefined };
      }, {})

      const newRowData = [record];

      setRowData(newRowData);
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const gridWidth = columnDefs.reduce((prev, item) => {
        return prev + item.width;
      }, 0)
      containerRef.current.style.width = `${gridWidth + 2}px`;
    }
  }, [rowData, columnDefs])

  const onCellValueChanged = useCallback((params) => {
    if (mode === markerKirihaMode) {
      const { column: { colId }, rowIndex, value } = params;

      if (colId === markerField.size.field && Number(value)) {
        const cell = document.querySelector(`.ag-row[row-id="${rowIndex}"] > .ag-cell[col-id="${colId}"]`);
        if (cell) {
          cell.classList.remove("invalid_marker_size");
        }
      }

      // Check all valid size marker
      const zeroCell = [];
      gridRef.current.api.forEachNode(node => {
        const { size } = node.data;
        if (!Number(size)) {
          zeroCell.push(node.rowIndex);
        }
      })

      if (!zeroCell.length) setIsInValidMarkerSizeOnSave(false);
    }

    const undoSize = params.api.getCurrentUndoSize();
    setUndoSize(undoSize);
  }, [])

  const onGridReady = () => {
    const columnWidth = columnDefs.reduce((prev, item) => {
      return prev + item.width;
    }, 2);
    containerRef.current.style.width = `${columnWidth}px`;
  }

  const handleClearAllKirihaTable = () => {
    confirmAlert({
      title: "Do you want to delete all the data?",
      message: "",
      buttons: [
        {
          label: t("common.back"),
        },
        {
          label: "OK",
          onClick: () => {
            setRowData([]);
            setUndoSize(undoSize + 1);
          },
        },
      ],
    });
  }

  const handleSaveKirihaTable = async () => {
    const realRowData = [...rowData].map(item => {
      return Object.values(item).join(',');
    })

    const zeroCell = [];
    if (mode === markerKirihaMode) {
      realRowData.forEach((marker, index) => {
        const sizeVal = marker.split(",")[3];
  
        if (!Number(sizeVal) || Number(sizeVal) < minMarkerSize || Number(sizeVal) > maxMarkerSize) {
          zeroCell.push(index);
        }
      })
    }
    
    if (zeroCell.length) {
      let isFocusFirstZeroCell = false;
      
      zeroCell.forEach(rowIndex => {
        const cell = document.querySelector(`.ag-row[row-id="${rowIndex}"] > .ag-cell[col-id="${markerField.size.field}"]`);

        if (cell) {
          cell.classList.add("invalid_marker_size");
        }

        if (!isFocusFirstZeroCell) {
          isFocusFirstZeroCell = true;
          gridRef.current.api.startEditingCell({
            rowIndex,
            colKey: markerField.size.field
          })
        }
      })

      setIsInValidMarkerSizeOnSave(true);
    } else {
      const header = columnDefs.map(item => item.headerName).join(',');
      realRowData.unshift(header);
  
      const isUpdateSuccess = await handleUpdate(realRowData, mode);
      isUpdateSuccess && setUndoSize(0);
      setIsOpenEditKiriha(false);
    }
  }

  const handleAddNewRow = (index, data) => {
    const fields = columnDefs.map(item => item.field);
    
    const record = fields.reduce((prev, item) => {
      if (item === "Z") return { ...prev, Z: data?.Z };
      if (item === "size") return { ...prev, size: data?.size };
      return { ...prev, [item]: undefined };
    }, {})

    const newRowData = [...rowData];
    newRowData.splice(index + 1, 0, record)

    setRowData(newRowData);
    setUndoSize(undoSize + 1);
  }

  const handleDelRow = (index) => {
    const rowDataClone = [...rowData];
    rowDataClone.splice(index, 1);

    setRowData(rowDataClone);
    setUndoSize(undoSize + 1);
  }

  const handleScrollBody = (event) => {
    const { top } = event;

    if (actionsRowRef.current) {
      actionsRowRef.current.scrollTop = top;
    }
  }

  const handleCloseModal = () => {
    setIsOpenEditKiriha(false);
  }

  return (
    <Modal
      show={isOpenEditKiriha}
      onHide={handleCloseModal}
      centered
      dialogClassName="kiriha-modal-wrapper"
      fullscreen
      animation={false}
    >
      {isShowExpanation ?
        <>
          <Modal.Header>
            <button 
              className="btn-close-edit" 
              onClick={() => setIsShowExpanation(false)}
              style={{ marginLeft: "auto" }}
            >
              <Images.IconCloseSensors />
            </button>
          </Modal.Header>
          <Modal.Body className="kiriha-modal-body">
            <div className="explanation_wrap">
              {
                {
                  ja: <img src={require("../../../../assets/images/xyz_j.jpg")} />,
                  en: <img src={require("../../../../assets/images/xyz_e.jpg")} />,
                }[lang]
              }
            </div>
          </Modal.Body>
        </>
        :
        <>
          <Modal.Header>
            <h3 style={mode === "face" ? { marginRight: "auto" } : undefined}>
              {
                {
                  "face": "Face",
                  "marker": "Marker"
                }[mode]
              } data
            </h3>
            {mode === "marker" && 
              <button className="btn_xyz_explanation" onClick={() => setIsShowExpanation(true)}>
                <img src={require("../../../../assets/images/q.png")} />
              </button>
            }
            {csvUploadedInfo &&
              <CSVLink
                filename={csvUploadedInfo.filename}
                data={dataCsv}
                className="btn-download-csv"
              >
                <Images.IconDownloadCsv color="currentColor" /> CSV
              </CSVLink>
            }
            {!isReadOnly &&
              <>
                <button className="btn-clear-data" onClick={handleClearAllKirihaTable} disabled={!rowData.length || !Object.values(rowData[0]).some(Boolean)}>Clear all data</button>
                <button className="btn-save-csv" onClick={handleSaveKirihaTable} disabled={!undoSize}>OK</button>
              </>
            }
            <button className="btn-close-edit" onClick={handleCloseModal}><Images.IconCloseSensors /></button>
            {isCheckOnly ?
              <span className="mess-invalid">The input value must be unique</span>
              : 
              isInValid ? <span className="mess-invalid">Input a valid value</span>
              :
              (isInValidMarkerSize || isInValidMarkerSizeOnSave) && <span className="mess-invalid">Wrong marker size</span>
            }
          </Modal.Header>
          <Modal.Body className="kiriha-modal-body">
            <div className="kiriha-container">
              <div
                className="ag-theme-alpine"
                ref={containerRef}
                style={{...heightTable, maxHeight: '100%'}}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  undoRedoCellEditing={true}
                  onCellValueChanged={onCellValueChanged}
                  stopEditingWhenCellsLoseFocus={true}
                  onGridReady={onGridReady}
                  headerHeight={40}
                  rowHeight={40}
                  onBodyScroll={handleScrollBody}
                  noRowsOverlayComponent={NoRowsOverlay}
                  suppressMovableColumns={true}
                />
              </div>
              {!isReadOnly &&
                <div className="actions-row" ref={actionsRowRef}>
                  {rowData.length ? 
                    rowData.map((item, index) => (
                      <div className="item" key={index}>
                        <button className="btn-add-row" onClick={() => handleAddNewRow(index, item)}>＋</button>
                        <button className="btn-del-row" onClick={() => handleDelRow(index)}>－</button>
                      </div>
                    ))
                    :
                    <div className="item">
                      <button className="btn-add-row" onClick={() => handleAddNewRow(-1)}>＋</button>
                    </div>
                  }
                </div>
              }
            </div>
          </Modal.Body>
        </>
      }
    </Modal>
  )
}

export default memo(KirihaEditTableComponent);