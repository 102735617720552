import { memo, useEffect, useRef, useState } from "react";
import axios from "axios";
import validator from "validator";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import { t } from "i18next";
import { isMobileOnly } from "react-device-detect";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from "react-bootstrap";

import * as Constants from "../../utils/constants"
import * as Images from "../images";
import { getHeader } from "../../services/api.service";
import { timeConverter } from "../../utils/utils";
import PaginationComponent from "../pagination";
import SearchTable from "../searchTable";

const initFormSearch =  {
  direction: 'asc',
  sort: 'planNo',
  planName: null,
  planNo: null,
  dateFrom: null,
  dataTo: null
};

const tableHeaderDrone = [
  {
    title: "plan.drone.table_1",
  },
  {
    title: "plan.drone.table_2",
  },
  {
    title: "plan.drone.table_4",
  },
  {
    title: "plan.drone.table_5",
  },
  {
    title: "plan.drone.table_6",
  },
];
const tableHeaderRobot = [
  {
    title: "plan.robot.table_1",
  },
  {
    title: "plan.robot.table_2",
  },
  {
    title: "plan.robot.table_4",
  },
  {
    title: "plan.robot.table_5",
  },
  {
    title: "plan.robot.table_6",
  },
];

const PlanList = (props) => {
  const { handleEdit, addNewPlan } = props;
  const accountType = JSON.parse(localStorage.getItem("user")).accountType;

  const [planList, setPlanList] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState("LOADING"); // LOADING, OK, NOK
  const [planTotal, setPlanTotal] = useState(null);
  const [uniqPlanNo, setUniqPlanNo] = useState(null);
  const [formSearch, setFormSearch] = useState(initFormSearch);
  const [pageSize, setPageSize] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);

  const [isSort, setIsSort] = useState(false);
  const [sortName, setSortName] = useState(false);
  const [isShowSearchMobile, setIsShowSearchMobile] = useState(false);

  const [addPlan, setAddPlan] = useState({
    planNo: "",
    name: "",
    file: "",
  })

  const inputRef = useRef(null);
  const form = useRef(null);
  const checkBtnAdd = useRef(null);

  useEffect(() => {
    getUniqData();
    getPlanList();
  }, [])

  useEffect(() => {
    getPlanList();
  }, [pageSize, pageNumber, formSearch])

  const getPlanList = () => {
    const params = {
      ...formSearch,
      pageSize: pageSize,
      pageNumber: pageNumber,
    }

    axios.get(Constants.PLAN_SEARCH_URL, {params: params}, getHeader("application/json")).then((res) => {
      if (!res.data.items) {
        setPlanList([]);
        setLoadingStatus("NOK");
        setPlanTotal(0);
      } else {
        setPlanList(res.data.items);
        setLoadingStatus("OK");
        setPlanTotal(res.data.totalItems);
      }
    }).catch(function (error) {
      console.log(error);
    });
  }

  const getUniqData = () => {
    axios.get(Constants.PLAN_LIST_URL, getHeader()).then(res => {
      if(res.data) {
        const uniqPlanNo = res.data.map(item => item.planNo);
        setUniqPlanNo(uniqPlanNo);
      }
    })
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    setPageNumber(1);
  }

  const handleChangePagination = (curentPage) => {
    setPageNumber(curentPage);
  }

  const sort = (item) => {
    let sortNameNew, directionNew, isSortNew = false;
    if(item.includes("flight_plan")) {
      sortNameNew = "planName"
    } else if(item.includes("last_flight_date_time")) {
      sortNameNew = "inspectionNo"
    }

    if(sortName === item) {
      isSortNew = !isSort
    } else {
      isSortNew = true
    }

    directionNew = isSortNew ? 'asc' : 'desc'

    setFormSearch({...formSearch, sort: sortNameNew, direction: directionNew});
    setIsSort(isSortNew);
    setSortName(item);
  }

  const handleSearch = (event, dataSearch) => {
    event.preventDefault();
    
    setFormSearch({ ...formSearch, pageNumber: 1, ...dataSearch });
    setPageNumber(1);
    setIsShowSearchMobile(false);
  }

  const handleDownload = (e, item) => {
    e.stopPropagation();
    let request = Constants.PLAN_DOWNLOAD_URL + item.id;
    axios.get(request, { headers: getHeader("application/octet-stream").headers }).then((response) => {
      let contentType = "application/json;charset=utf-8;";

      const downloadData = response.data;
      console.log("downloadData", downloadData);

      downloadData.user.corpId = Math.floor(downloadData.user.corpId);

      const frontendVer = Constants.FE_VER;
      if (downloadData.app.ver === "") {
        downloadData.app.ver = frontendVer;
      }
      const flightMarkers = downloadData.flight.flightMarker;
      downloadData.flight.flightMarker = flightMarkers.map((marker) => {
        marker.id = Math.floor(marker.id);
        if (marker.markerDistanceMechanism) {
          delete marker.markerDistanceMechanism;
        }
        if (!marker?.markerDistanceMechanism) {
          marker.markerDistanceMechanism = "sensor";
        }
        if (!marker?.markerSize) {
          marker.markerSize = 20;
        }
        return marker;
      });

      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(downloadData)))],
        { type: contentType }
      );

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = "plan-" + item.planNo + ".json";
      a.click();
    }).catch(function (error) {
      console.log(error);
      NotificationManager.error(t("download.download_failed"), "", 2000);
    });
  }

  const handleDelete = (e, item) => {
    e.stopPropagation();

    confirmAlert({
      title: t("dialog_delete.message_text"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            let request = Constants.PLAN_DELETE_URL + item.id;
            axios.delete(request, { headers: getHeader().headers }).then((response) => {
              getPlanList();
              NotificationManager.success(t("dialog_delete.confirm_success"), "", 2000);
            }).catch(function (error) {
              NotificationManager.error(
                t("dialog_delete.confirm_error"),
                "",
                2000
              );
            });
          },
        },
        {
          label: t("dialog_delete.option_no"),
        },
      ],
    });
  }

  const fileUploadButton = (e) => {
    e.preventDefault();
    inputRef.current.click();
  }

  const handleChangeFile = (e, field) => {
    let object = addPlan;
    object[field] = e.target.files;
    setTimeout(() => {
      document.getElementById("btn-sub").click();
    }, 10);
  }

  const handleAddPlan = (e) => {
    e.preventDefault();
    //validate input data
    form.current.validateAll();

    if (checkBtnAdd.current.context._errors.length === 0) {
      if (addPlan.file[0]) {
        var formData = new FormData();

        formData.append("file", addPlan.file[0]);
        formData.append("name", addPlan.name);
        formData.append("planNo", addPlan.planNo);

        axios.post(Constants.PLAN_ADD_URL, formData, getHeader("multipart/form-data")).then((response) => {
          getPlanList();
          inputRef.current.value = null;
          if (accountType === "robot") {
            NotificationManager.success(t("plan.robot.added_move_plan"), "", 2000);
          } else {
            NotificationManager.success(t("plan.drone.added_flight_plan"), "", 2000);
          }
        }).catch((error) => {
          inputRef.current.value = null;
          const isPlanExisted = error.response.data.err_code?.includes("exist") ?? false;
          if (accountType === "robot") {
            NotificationManager.error(t("plan.robot.error_move_plan"), "", 2000);
          } else {
            NotificationManager.error(t(isPlanExisted ? "plan.drone.error_flight_plan_existed" : "plan.drone.error_flight_plan"), "", 2000);
          }
        });
      } else {
        if (accountType === "robot") {
          NotificationManager.error(t("plan.robot.select_file_plan"), "", 2000);
        } else {
          NotificationManager.error(t("plan.drone.select_file_plan"), "", 2000);
        }
      }
    } else {
      if (accountType === "robot") {
        NotificationManager.error(t("plan.robot.select_file_plan"), "", 2000);
      } else {
        NotificationManager.error(t("plan.drone.select_file_plan"), "", 2000);
      }
    }
  }

  const renderItemHeaderTable = (data) => {
    if (data != null) {
      return data.map((item, index) => {
        if (item.title.includes("table_1") || item.title.includes("table_2") || item.title.includes("table_4")) {
          return (
            <div
              onClick={() => sort(item.title)}
              className="list_sort clmn"
              key={index}
              style={{
                cursor: "pointer",
                userSelect: "none",
                color: "#473DAF",
              }}
            >
              <div className="l-sort">
                {t(item.title)}
                <span
                  style={{
                    transform: `${
                      isSort && sortName === item.title ? "rotate(180deg)" : ""
                    }`,
                  }}
                >
                  <Images.IconSort color="currentColor" />
                </span>
              </div>
            </div>
          );
        }
        return <div className="clmn" key={index}>{t(item.title)}</div>;
      });
    }
  }

  const renderItemTable = (data) => {
    return data.map((item, index) => {
      return (
        <div
          className="list-item"
          key={index}
          onClick={() => handleEdit(item.id)}
          style={{ cursor: "pointer" }}
        >
          <div className="clmn">
            <Images.IconScan />
            {item.name}
          </div>
          <div className="clmn">{item.planNo}</div>
          <div className="clmn">{timeConverter(Date.parse(item.createdTime) / 1000, t)}</div>
          <div className="clmn">
            <button
              className="table-btn"
              onClick={(e) => handleDownload(e, item)}
            >
              <Images.Icons name="IconDownload" color="currentColor" />
            </button>
          </div>
          <div className="clmn">
            <button
              className="table-btn"
              onClick={(e) => handleDelete(e, item)}
            >
              <Images.Icons name="IconDelete" color="currentColor" />
            </button>
          </div>
        </div>
      );
    });
  }

  const renderSearchTable = () => {
    if (uniqPlanNo) {
      return (
        <div className="plan-header">
          <SearchTable
            data={[
              {
                name: 'planName',
                label: t('Search_Pagination.plan_name'),
                typeSearch: 'text',
              },
              {
                name: 'planNo',
                label: t('Search_Pagination.plan_no'),
                typeSearch: 'select',
                subData: uniqPlanNo
              },
              {
                name: 'dateFrom dateTo',
                label: t('Search_Pagination.creation_date'),
                typeSearch: 'date',
              },
            ]}
            formSearch={formSearch}
            onSearch={handleSearch}
          />

          {!isMobileOnly &&
            <div className="actions-plan">
              <button
                onClick={addNewPlan}
                className="btn-add-plan"
              >
                <Images.Icons name="IconAddSquare" />
                {t("common.add")}
              </button>
              <Form
                className="import-plan"
                onSubmit={(e) => handleAddPlan(e)}
                ref={(c) => {
                  form.current = c;
                }}
              >
                <div hidden>
                  <input
                    id="fileUpload"
                    ref={inputRef}
                    type="file"
                    className="dps-input"
                    name="planName"
                    placeholder={accountType === "robot" ? t("plan.robot.table_2") : "plan.drone.plan_name"}
                    message={accountType === "robot" ? t("plan.robot.message1") : t("plan.drone.message1")}
                    accept=".json"
                    validations={[validator.required]}
                    onChange={(e) => handleChangeFile(e, "file")}
                  />
                </div>
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    checkBtnAdd.current = c;
                  }}
                />
                <button onClick={(e) => fileUploadButton(e)}>
                  {t("common.import")}
                </button>
                <button className="hidden" type="submit" id="btn-sub">
                  sub
                </button>
              </Form>
            </div>
          }
        </div>
      )
    }
  }

  if (loadingStatus === "LOADING") {
    return (
      <div className="loading">
        <img src={require("../../assets/images/loading.gif")} />
      </div>
    )
  }

  return (
    <div className="flight-selector">
      {isMobileOnly ? 
        <div className="header-result-mb">
          <button onClick={() => setIsShowSearchMobile(true)}>{t('Search_Pagination.search')}</button>

          <Modal
            show={isShowSearchMobile}
            centered
            className="form_search-mobile"
            size="lg"
            onHide={() => setIsShowSearchMobile(false)}
          >
            <Modal.Body>
              {renderSearchTable(t)}
            </Modal.Body>
          </Modal>
        </div>
        :
        renderSearchTable()
      }
      {loadingStatus === "NOK" ?
        <h1 className="txt-red txt-notice">
          {accountType === "robot" ? t("plan.robot.add_robot_plan") : t("plan.drone.no_data_flight_plan")}
        </h1>
        :
        <>
          {isMobileOnly ?
            <div className="result-list-mb">
              {planList.map((plan) => (
                <div className="item" key={plan.id} onClick={() => handleEdit(plan.id)}>
                  <p className="plan-name">
                    <Images.Icons name="IconScan" color="#000" />
                    {plan.name}
                  </p>

                  <p className="plan-no">PlanNo. {plan.planNo}</p>

                  <span className="time">{timeConverter(Date.parse(plan.createdTime) / 1000, t)}</span>
                </div>
              ))}
            </div>
            :
            <div className="wrap_table">
              <div className="dps-list-f flight-plan-list">
                <div className="header">
                  {renderItemHeaderTable(accountType === "robot" ? tableHeaderRobot : tableHeaderDrone)}
                </div>
                <div className="scroll-bar body">
                  {renderItemTable(planList)}
                </div>
              </div>
            </div>
          }
          
          <div className="footer-page">
            {!isMobileOnly && 
              <p className="total-list">{planTotal} {t('plan.total')}</p>
            }
            <PaginationComponent
              total={planTotal} 
              pageSize={pageSize} 
              pageNumber={pageNumber}
              handleChangePageSize={handleChangePageSize}
              handleChangePagination={handleChangePagination} 
            />
          </div>
        </>
      }
    </div>
  )
}

export default memo(PlanList);