import React, { Component, Suspense } from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { isMobileOnly, isTablet } from "react-device-detect";

import * as Images from "../components/images";
import * as Constants from "../utils/constants";
import Menu from "../components/menu";
import PlanContent from "../components/plan/planContent";
import HeaderMobile from "../components/HeaderMobile"
import PlanList from "../components/plan/planList";
import { getHeader } from "../services/api.service";
import { generateUUIDv4 } from "../utils/utils";

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droneTypes: [],
      isOpenDetail: false,
      isShowMenu: false,
      planDetail: null,
      action: "view", // read-only, edit, add
      tab: "view",
    };

    //action: view, add, edit, map, map_scale, map_animation
    this.renderFlightPlanArea.bind(this);
    this.handleShowMenu.bind(this);

    document.documentElement.style.overflow = "hidden";
  }

  componentDidMount() {
    this.getDroneTypes();

    if (
      this.props.history?.action === "PUSH" &&
      this.props.location?.state?.planId
    ) {
      this.handleEdit(this.props.location.state.planId, true);
    }
  }

  getDroneTypes() {
    axios.get(Constants.DRONE_TYPE_URL, getHeader()).then(res => {
      this.setState({
        droneTypes: res.data
      })
    })
  };

  handleEdit(id, isReadOnly = false) {
    axios.get(Constants.PLAN_URL + id, getHeader()).then(res => {
      const planDetail = this.convertFlightMarkerFromPlanDetail(res.data);
      this.setState({
        planDetail,
        tab: "view",
        action: isReadOnly ? "read-only" : "edit",
        isOpenDetail: true,
      });
    })
  }

  fileUploadButton(e) {
    e.preventDefault();
    this.inputRef.current.click();
  }

  addNewPlan(e) {
    e.preventDefault();
    this.setState({
      action: "add",
      tab: "view",
      isOpenDetail: true,
    });
  }

  handleShowMenu() {
    this.setState({ isShowMenu: !this.state.isShowMenu });
  }

  render() {
    const { isShowMenu } = this.state;

    return (
      <Suspense fallback="loading">
        <HeaderMobile />
        <div
          className={
            "dps-menu-hamburger-button " +
            (isTablet
              ? "dps-menu-hamburger-button-tablet " +
                (isShowMenu ? "" : "dps-menu-hamburger-button-not-show")
              : "")
          }
          onClick={() => this.handleShowMenu()}
        >
          <Images.HamburgerButton />
        </div>
        <div
          className={
            "dps-menu " +
            (isTablet
              ? "dps-menu-tablet " + (isShowMenu ? "" : "dps-menu-not-show")
              : "")
          }
        >
          <Menu currentRouter={this.props.location.pathname} />
        </div>
        {isShowMenu && isTablet && <div className="dps-menu-back-ground"></div>}
        <div
          className={"dps-content " + (isTablet ? "dps-content-tablet" : "")}
        >
          <div className="dps-content-area">
            <div className={"dps-main-content" + (isMobileOnly ? " flight-plan-mb" : "")}>
              <div className="dps-main-content-area flight-plan">
                {this.renderFlightPlanArea()}
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    );
  }

  renderFlightPlanArea() {
    if (this.state.isOpenDetail) {
      return (
        <div className="flight-plan-detail">
          <PlanContent
            action={this.state.action}
            tabView={this.state.tab}
            plan={(this.state.action === "edit" || this.state.action === "read-only") ? this.state.planDetail : null}
            droneTypes={this.state.droneTypes}
            handleChangeTab={this.handleChangeTab.bind(this)}
            createCallBack={this.createPlanSuccess.bind(this)}
            handleChangeAction={this.handleChangeAction.bind(this)}
          />
        </div> 
      )
    }

    return (
      <div className="flight-plan-area">
        <PlanList 
          handleEdit={(id, isReadOny) => this.handleEdit(id, isReadOny)}
          addNewPlan={this.addNewPlan.bind(this)}
        />
      </div>
    );
  }


  createPlanSuccess() {
    this.setState({
      action: "edit",
    });
  }

  convertFlightMarkerFromPlanDetail(data) {
    const planContent = data.planContent;
    if (planContent.flight && Array.isArray(planContent.flight.flight_marker)) {
      const flightData = planContent.flight.flight_marker.map(marker => {
        return {
          ...marker,
          markerId: generateUUIDv4()
        };
      });

      const newPlanContent = {
        ...planContent,
        flight: {
          ...planContent.flight,
          flight_marker: flightData
        }
      };

      return { ...data, planContent: newPlanContent };
    }
    return data;
  }

  handleChangeAction (action) {
    this.setState({ action });
  }

  handleChangeTab(tabName) {
    this.setState({
      tab: tabName,
    });
  }
}

export default withTranslation()(Plan);