import { memo, useEffect, useRef, useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import { t } from "i18next";
import cloneDeep from "lodash/cloneDeep";
import { PanelGroup, Panel } from "../resize-panels/resizePanel";

import { doGet, getHeader } from "../../services/api.service";
import { timeConverter, timeMinSecConverter, timeSmallConverter, timeConverterFull } from "../../utils/utils";
import * as Constants from "../../utils/constants";
import * as Images from "../images";
import PlayingTracking from "./playingTracking";
import ImgaesViewComponent from "./imagesView";
import MapPreview from "../map/mapPreview";
import VideoResultComponent from "./videoResult";
import ResultSensorComponent from "./resultSensor";
import { isMobileOnly } from "react-device-detect";
import MapPreviewStatusKirihaMode from "../map/KirihaMode/mapPreviewStatusKirihaMode";

const initialOptionChart = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: tooltipItems => tooltipItems[0].label,
      },
    }
  },
  scales: {
    x: {
      type: 'linear',
      title: {
        display: true,
        text: "Time[s]",
        align: "end"
      },
      ticks: {
        stepSize: undefined,
        precision: 0,
        callback: value => value,
      },
      max: undefined,
      min: undefined
    },
    y: {
      type: 'linear',
      max: undefined,
      min: 0,
      ticks: {
        stepSize: undefined,
        precision: 0,
        callback: value => value,
      },
    }
  },
  elements: {
    line: {
      borderJoinStyle: 'round'
    }
  }
};

const initialChartSetting = {
  statusLegendList: [],
  dataChart: null,
  optionsChart: initialOptionChart,
  isNormalize: false,
  // Axis X
  stepSizeX: undefined,
  minX: null,
  maxX: null,
  isConvertHour: false,
  // Axis Y
  stepSizeY: undefined,
  minY: null,
  maxY: null,
}

const colorSensor = [
  '166, 206, 227', 
  '31, 120, 180', 
  '178, 223, 138', 
  '51, 160, 44', 
  '251, 154, 153', 
  '227, 26, 28', 
  '253, 191, 111', 
  '255, 127, 0', 
  '202, 178, 214', 
  '106, 61, 154'
];

const ResultDetailComponent = (props) => {
  const corpId = JSON.parse(localStorage.getItem("user")).corpId;
  const corpKey = JSON.parse(localStorage.getItem("user")).corpKey;
  const accountType = JSON.parse(localStorage.getItem("user")).accountType;
  const {
    droneId,
    dataActive,
    updateIsViewDetail,
  } = props;

  const [deviceIcons, setdeviceIcons] = useState(null);
  const [droneDetail, setDroneDetail] = useState(null);
  const [allAlert, setAllAlert] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isShowOptionsAlert, setIsShowOptionsAlert] = useState(false);
  const [isShowsAlert, setIsShowsAlert] = useState(false);
  const [activeInspection, setActiveInspection] = useState(null);
  const [takeoffUtc, setTakeoffUtc] = useState(0);
  const [currentMarker, setCurrentMarker] = useState(0);
  const [isShowFormDownloadAndDelete, setIsShowFormDownloadAndDelete] = useState(false);
  const [flightHistory, setFlightHistory] = useState(null);
  const [currentSelectedDevice, setCurrentSelectedDevice] = useState(null);
  const [resultActive, setResultActive] = useState(null);
  const [dataConvertForPreview, setDataConvertForPreview] = useState(null);
  const [dataConvertForMap, setDataConvertForMap] = useState(null);
  const [elementMainView, setElementMainView] = useState("video"); //video, sensor, map, images
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [sizeMain, setSizeMain] = useState(null);

  // Sensor Info
  const [chartSetting, setChartSetting] = useState(initialChartSetting);
  const [dataSensors, setDataSensors] = useState(null);
  const [sensorCsv, setSensorCsv] = useState(null);
  const [sensorsInfo, setSensorsInfo] = useState(null);
  const [sensorsChart, setSensorsChart] = useState(null);
  const [isMultiDate, setIsMultiDate] = useState(null);
  const [isViewChart, setIsViewChart ] = useState(true);
  const [isSettingLegendMB, setIsSettingLegendMB] = useState(false);
  const [isDisplayAllSensor, setIsDisplayAllSensor] = useState(true);

  // Kiriha
  const [faceIdFlying, setFaceIdFlying] = useState(null);

  const droneInfoRef = useRef(null);
  const btnAlertOptionsRef = useRef(null);
  const formAlertOptionsRef = useRef(null);
  const alertHistoryRef = useRef(null);
  const btnDownloadAndDeleteRef = useRef(null);
  const optionDoawnloadAndDeleteRef = useRef(null);

  useEffect(() => {
    fetchData();
    getInspectionDetail(dataActive);
  }, [])

  useEffect(() => {
    if (activeInspection) {
      setDataConvertForPreview(convertDataForPreview(activeInspection));
      setDataConvertForMap(convertDataForMap(activeInspection));
     
      !isDisplayAllSensor && getSensorByCurrentTime();
    }
  }, [currentTime, isDisplayAllSensor])

  useEffect(() => {
    if (isDisplayAllSensor && dataSensors) {
      setSensorCsv(convertSensorCsv(dataSensors));
      setSensorsInfo(convertSensorInfo(dataSensors));
      setSensorsChart(dataSensors);
    } else {
      getSensorByCurrentTime();
    }
  }, [isDisplayAllSensor])

  useEffect(() => {
    if(formAlertOptionsRef.current && !isMobileOnly) {
      const { bottom, left } = btnAlertOptionsRef.current.getBoundingClientRect();
      formAlertOptionsRef.current.style.left = left + "px";
      formAlertOptionsRef.current.style.top = bottom + 2 + "px";
    }
  }, [isShowOptionsAlert])

  useEffect(() => {
    if(alertHistoryRef.current) {
      const { bottom } = droneInfoRef.current.getBoundingClientRect();
      alertHistoryRef.current.style.top = bottom - 1 + "px";
    }
  }, [isShowsAlert])

  useEffect(() => {
    if(optionDoawnloadAndDeleteRef.current && !isMobileOnly) {
      const { bottom, left } = btnDownloadAndDeleteRef.current.getBoundingClientRect();
      optionDoawnloadAndDeleteRef.current.style.left = left + "px";
      optionDoawnloadAndDeleteRef.current.style.top = bottom + 2 + "px";
    }
  }, [isShowFormDownloadAndDelete])

  const fetchData = () => {
    const promiseGetDeviceIcons = axios.get(Constants.MASTER_DATA_URL + "icon", getHeader());
    const promiseDroneStatus = axios.get(Constants.DEVICE_STATUS_URL + droneId, getHeader());
    const promiseAllAlert = axios.get(Constants.DEVICE_ALERT_ALL_URL + droneId, { params: { pageSize: 9999999, pageNumber: 1 } }, getHeader());

    Promise.allSettled([
      promiseGetDeviceIcons,
      promiseDroneStatus,
      promiseAllAlert,
    ]).then(responseArr => {
      responseArr[0].value && responseArr[0].status === 'fulfilled' && setdeviceIcons(responseArr[0].value.data);
      responseArr[1].value && responseArr[1].status === 'fulfilled' && setDroneDetail(responseArr[1].value.data);
      responseArr[2].value && responseArr[2].status === 'fulfilled' && setAllAlert(responseArr[2].value.data);
    }) 
  }

  const getInspectionDetail = (device) => {
    const { deviceId, deviceNo, inspectionNo, planName, planNo } = device;

    setCurrentSelectedDevice(deviceNo);
    setResultActive(inspectionNo);

    getInspectionResultDetail(inspectionNo, deviceId, deviceNo);

    doGet(Constants.DEVICE_RESULT_URL + deviceId + '/history', {planNo, planName}, (res) => {
      setFlightHistory(res.data);
    })
  }

  const getInspectionResultDetail = async (inspectionNo, deviceId, deviceNo) => {
    try {
      const res = await axios.get(
      Constants.DEVICE_RESULT_URL + deviceId + "/inspection/" + inspectionNo,
      getHeader()
    );

      const takeoffUtc = +res.data.deviceStatus.find(item => item.status === "takeoff").content.utc;
      
      setCurrentTime(0);
      setActiveInspection(res.data);
      setTakeoffUtc(takeoffUtc);
      setCurrentMarker(0);
      setDataConvertForPreview(convertDataForPreview(res.data))
      setDataConvertForMap(convertDataForMap(res.data));
      setFaceIdFlying(res.data.inspection.faceId);

      getSensor(corpId, deviceNo, inspectionNo, takeoffUtc);
    } catch (error) {}
    localStorage.setItem("currentTimeVideo", 0);
    sessionStorage.setItem("isVideoPlay", 0)
  }

  const getSensor = (corpId, droneId, flightNo, takeoffUtc) => {
    axios.get(Constants.SENSORS_INFO, {
      params: { corpId, droneId, flightNo },
      headers: {
        corpKey: corpKey
      }
    }).then(res => {
      const dataHandle = {...res.data};
      const counterDays = dataHandle.meas_t_arr.reduce((prev, item) => {
        const date = new Date(item * 1000).getDate();
        if (!prev.includes(date)) {
          return prev.concat(date);
        }
        return prev;
      }, []);

      setSensorCsv(convertSensorCsv(dataHandle));
      setSensorsInfo(convertSensorInfo(dataHandle));
      setSensorsChart(dataHandle);
      setDataSensors(res.data);
      setIsMultiDate(counterDays.length > 1);

      initialSettingChart(dataHandle, takeoffUtc);
    }).catch((e) => {
      setSensorCsv(null);
      setSensorsInfo(null);
      setSensorsChart(null);
      setDataSensors(null);
    })
  }

  const getSensorByCurrentTime = () => {
    if(takeoffUtc && dataSensors) {
      const dataHandle = {...dataSensors};
      const currentIndexDataRender = dataHandle.meas_t_arr.findLastIndex(item => item <= +takeoffUtc + +currentTime)
      dataHandle.marker_arr = dataSensors.marker_arr.slice(0, currentIndexDataRender + 1);
      dataHandle.meas_t_arr = dataSensors.meas_t_arr.slice(0, currentIndexDataRender + 1);
      dataHandle.sensor_meas_arr = dataSensors.sensor_meas_arr.map(item => {
        return {
          ...item,
          value_arr: item.value_arr.slice(0, currentIndexDataRender + 1)
        }
      });

      setSensorCsv(convertSensorCsv(dataHandle));
      setSensorsInfo(convertSensorInfo(dataHandle));
      setSensorsChart(dataHandle);
    }
  }

  const convertSensorCsv = (data) => {
    return data.meas_t_arr.map((item, index) => {
      const sensorMeas = data.sensor_meas_arr.map((sensor) => {
        return {
          [sensor.sensor + "(" + sensor.unit + ")"]: sensor.value_arr[index] !== "NaN" ? sensor.value_arr[index] : "",
        };
      });

      return {
        Marker: data.marker_arr[index],
        Time: timeConverter(item, t),
        ...Object.assign({}, ...sensorMeas),
      };
    });
  }

  const convertSensorInfo = (data) => {
    const marker = "marker";
    const time = "time";

    let mapContentSensors = [],
      unitSensor = {},
      uniqSensor = [marker, time];

    data.sensor_meas_arr.map((item) => {
      Object.assign(unitSensor, { [item.sensor]: item.unit });
      uniqSensor.push(item.sensor);
    });

    data.meas_t_arr.map((item, index) => {
      const sensor = {};
      sensor[marker] = data.marker_arr[index];
      sensor[time] = timeConverterFull(item, t);

      const sensorMeas = data.sensor_meas_arr.map((sensor) => {
        return {
          [sensor.sensor]:
            sensor.value_arr[index] !== "NaN" ? sensor.value_arr[index] : "",
        };
      });

      mapContentSensors.push({
        ...sensor,
        ...Object.assign({}, ...sensorMeas),
      });
    });

    return { mapContentSensors, uniqSensor, unitSensor };
  }

  const initialSettingChart = (sensorCharts, takeoffUtc) => {
    if(sensorCharts) {
      const { isNormalize, isConvertHour } = chartSetting;

      const labels = sensorCharts.meas_t_arr.map(item => +item - takeoffUtc);
      const datasets = sensorCharts.sensor_meas_arr.map((item, index) => {
        const rgb = colorSensor[index];
        let dataNormalize;
        const maxAbsItem = Math.max(...item.value_arr.map(Math.abs).filter(value => !isNaN(value)));

        if(isNormalize) {
          dataNormalize = item.value_arr.map((it, _, arr) => {
            if(it !== "NaN") {
              return +it / maxAbsItem;
            }
            return it
          } );
        }
    
        return {
          label: item.sensor + " [" + item.unit + "]",
          data: isNormalize ? dataNormalize : item.value_arr,
          borderColor: `rgb(${rgb})`,
          backgroundColor: `rgb(${rgb}, 0.5)`,
        }
      });
      const min = Math.min(...sensorCharts.sensor_meas_arr.map(item => Math.min(...item.value_arr.filter(it => !isNaN(it)))));
      const max = Math.max(...sensorCharts.sensor_meas_arr.map(item => Math.max(...item.value_arr.filter(it => !isNaN(it)))));
      const options = {...initialOptionChart};
      options.scales.y.min = min < 0 ? min : 0;
      options.scales.y.max = undefined;
      options.scales.x.min = 0;
      options.scales.x.max = undefined;
      options.scales.x.ticks.callback = val => isConvertHour ? timeSmallConverter(takeoffUtc + val) : val;
  
      const newChartSetting = {...initialChartSetting};
      newChartSetting.statusLegendList = sensorCharts.sensor_meas_arr.map(_ => false);
      newChartSetting.isNormalize = isNormalize;
      newChartSetting.isConvertHour = isConvertHour;
      newChartSetting.dataChart = { labels, datasets };
      newChartSetting.optionsChart = options;
      newChartSetting.maxX = labels[labels.length - 1];
      newChartSetting.minX = 0;
      newChartSetting.maxY = max;
      newChartSetting.minY = min < 0 ? min : 0;
  
      setChartSetting(newChartSetting);
    }
  }

  const handleDownload = (e, flightNo) => {
    e.stopPropagation();
    let request = Constants.RESULT_DOWNLOAD_URL;
    const params = {
      droneId: currentSelectedDevice,
      flightNo: flightNo,
      corpId: corpId,
    };
    axios
      .get(request, {
        headers: getHeader("application/json").headers,
        params,
      })
      .then((response) => {
        //let contentType = "blob";
        let contentType = "application/json;charset=utf-8;";
        var blob = new Blob(
          [decodeURIComponent(encodeURI(JSON.stringify(response.data)))],
          { type: contentType }
        );
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = corpId + "-" + flightNo + ".json";
        a.click();
      })
      .catch(function (error) {
        NotificationManager.error(
          t("download.download_failed"),
          t("common.error"),
          2000
        );
      }).finally(() => {
        setIsShowFormDownloadAndDelete(false);
      });
  }

  const handleDelete = (e) => {
    e.preventDefault();
    const { inspection: { deviceId, inspectionNo } } = activeInspection;
    setIsShowFormDownloadAndDelete(false);

    confirmAlert({
      title: t("dialog_delete.message_text"),
      message: "",
      buttons: [
        {
          label: t("common.ok"),
          onClick: () => {
            let request = Constants.RESULT_DELETE_URL + deviceId + "/inspection/" + inspectionNo;
            axios.delete(request, { headers: getHeader().headers }).then(() => {
              updateIsViewDetail(false);
              NotificationManager.success(t("dialog_delete.confirm_success"), "", 2000);
            }).catch(function (error) {
              NotificationManager.error(t("dialog_delete.confirm_error"), "", 2000);
            });
          },
        },
        {
          label: t("dialog_delete.option_no"),
        },
      ],
    });
  }

  const handleChangeResultDetail = (e) => {
    const { deviceId, deviceNo } = dataActive;
    let { value } = e.target;

    getInspectionResultDetail(value, deviceId, deviceNo);

    setResultActive(value);
    setIsDisplayAllSensor(true);
  }

  const convertDataForMap = (data) => {
    let mapContentDisplay = [];
    let flightPlanContent;
    let deviceStatus = data.deviceStatus;
    let lastPointInPlan =
      data.planContent.flight_marker[data.planContent.flight_marker.length - 1];

    if (data.inspection.mapContent) {
      flightPlanContent = data.inspection.mapContent;
    } else {
      if (accountType === "robot") {
        flightPlanContent = data.inspection.jsonContent.flight.flight_marker;
      } else {
        let takeoffItem = {
          id: Constants.TAKEOFF_MARKER_ID,
          isTakeoff: true,
        };
        let landingItem = {
          id: Constants.LANDING_MARKER_ID,
          isLanding: true,
        };
        flightPlanContent = [
          takeoffItem,
          ...data.inspection.jsonContent.flight.flight_marker,
          landingItem,
        ];
      }
    }

    var takeoff = deviceStatus.find(function (result) {
      return result.status === "takeoff";
    });
    let currentTimeVideo =
      Number(takeoff.content.utc) + Number(currentTime);

    flightPlanContent.map((item, index) => {
      if (item.isTakeoff) {
        var takeoff = deviceStatus.find(function (result) {
          return result.status === "takeoff";
        });
        item = { ...takeoff, ...item };
        currentTimeVideo =
          Number(item.content.utc) + Number(currentTime);

        mapContentDisplay.push(item);
      } else if (item.isLanding) {
        let markerInfo;
        deviceStatus
          .filter((filterItem) => filterItem.status === "landed")
          .map((markerStatus) => {
            markerInfo = { ...markerStatus };
          });

        item = { ...markerInfo, ...item };
        if (item.content.utc && item.content.utc < currentTimeVideo) {
          item.trackingPassItem = true;
        }
        mapContentDisplay.push(item);
      } else {
        let markerInfo, markerContent;
        let trackingPassItem = false;

        let devicePlan = cloneDeep(data.planContent);
        let planMarker = devicePlan.flight_marker;
        let makerArrTime = null;
        let lastestStatus = -1;
        let arrStatus = deviceStatus.filter(
          (filterItem) => filterItem.status === "marker_arv"
        );
        for (let markerStatus of arrStatus) {
          if (markerStatus.content.utc < currentTimeVideo) {
            if (markerStatus.status === "marker_arv") {
              planMarker.some(function (itemP, indexP, objectP) {
                if (itemP.id === markerStatus.content.marker_id) {
                  objectP.splice(indexP, 1);
                  return true;
                }
                return false;
              });
            }
          } else {
            lastestStatus = markerStatus.id;
            break;
          }
        }

        deviceStatus
          .filter(
            (filterItem) =>
              filterItem.status !== "takeoff" &&
              ("" + filterItem.content.id === "" + item.id ||
                filterItem.content.marker_id === "" + item.id)
          )
          .map((markerStatus) => {
            if (markerStatus.content.utc < currentTimeVideo) {
              markerInfo = { ...markerStatus };
              markerContent = { ...markerContent, ...markerStatus.content };

              trackingPassItem = true;

              if (markerStatus.status === "marker_arv") {
                makerArrTime = +markerStatus.content.utc;
              }

              if (markerStatus.status === "marker_left") {
                if (
                  planMarker.length === 0 ||
                  !containsObject(lastPointInPlan, planMarker)
                ) {
                  item["nextMarker"] = Constants.LANDING_MARKER_ID;
                } else {
                  item["nextMarker"] = planMarker[0].id;
                }
              }
            } else {
              if (
                trackingPassItem &&
                markerStatus.status === "image" &&
                (lastestStatus < 0 || markerStatus.id < lastestStatus)
              ) {
                // let url = markerStatus.content.url;
                // if (!markerContent.url) {
                //   markerContent.url = markerStatus.content.url;
                // } else {
                //   if (markerStatus.content.url) {
                //     let arr = markerStatus.content.url.split(" ");
                //     for (let el of arr) {
                //       if (el && !markerContent.url.includes(el.trim())) {
                //         markerContent.url += " " + el.trim();
                //       }
                //     }
                //   }
                // }
                // markerStatus.content.url = markerContent.url;
                // markerContent = { ...markerContent, ...markerStatus.content };
              } else {
                if (!trackingPassItem) {
                  markerInfo = { ...markerStatus };
                  markerContent = { ...markerContent, ...markerStatus.content };
                }
              }
            }
          });
        item = { ...markerInfo, ...item };
        item["content"] = markerContent;
        item["makerArrTime"] = makerArrTime;
        item["trackingPassItem"] = trackingPassItem;

        if (trackingPassItem) {
          mapContentDisplay.push(item);
        } else {
          item["status"] = "pending";
          mapContentDisplay.push(item);
        }
      }
    });
    return mapContentDisplay;
  }

  const convertDataForPreview = (data) => {
    let mapContentDisplay = [];
    const { deviceStatus } = data;
    let flightPlanContent = data.inspection.jsonContent.flight.flight_marker;

    if (accountType !== "robot") {
      let takeoffItem = {
        id: 0,
        isTakeoff: true,
      };
      let landingItem = {
        id: -1,
        isLanding: true,
      };

      flightPlanContent = [
        takeoffItem,
        ...data.inspection.jsonContent.flight.flight_marker,
        landingItem,
      ];
    } else {
      flightPlanContent = data.inspection.jsonContent.flight.flight_marker;
    }

    const medias = deviceStatus.filter(item => item.status === "image");
    const isBtwMarker = !!deviceStatus.find(item => item.status === "btw_marker");
    const checkCurrentData = Boolean(medias.length && medias[0].content.utc || data.inspection.jsonContent.kirihaMode || isBtwMarker); //The old data does not have the UTC information for the images.

    mapContentDisplay = checkCurrentData ? convertCurrentData(data, flightPlanContent) : convertOldData(data, flightPlanContent);

    return mapContentDisplay;
  };

  const convertCurrentData = (data, flightPlanContent) => {
    let mapContentDisplay = [];
    const { deviceStatus: allData } = data;
    const deviceStatus = allData.filter(item => item.status !== "btw_marker")
    let latestIndex = 0,
      latestMarker,
      currentTimeVideo = 0;

    flightPlanContent.map((item, i, arr) => {
      if (accountType === "robot") {
        var takeoff = deviceStatus.find(function (result) {
          return result.status === "takeoff";
        });
        currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime) + Number(data.inspection.delay_t) * -1;
      }

      if (item.isTakeoff) {
        const takeoff = deviceStatus.find((it) => it.status === "takeoff");
        mapContentDisplay.push({ ...takeoff, ...item });
        latestMarker = 0;
        latestIndex = 1;
        currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime) + Number(data.inspection.delay_t) * -1;
        return;
      }
      if (item.isLanding) {
        const landing = deviceStatus.find((it) => it.status === "landed");
        mapContentDisplay.push({ ...landing, ...item });
        latestMarker = -1;
        if (
          landing.content.utc < currentTimeVideo &&
          currentMarker !== Constants.LANDING_MARKER_ID
        ) {
          setCurrentMarker(Constants.LANDING_MARKER_ID);
        }
        return;
      }

      let markerContent = {},
        trackingPassItem = false,
        makerArrTime = null,
        makerLeftTime = null,
        markerInfo;

      for (let index = latestIndex; index < deviceStatus.length; index++) {
        const markerStatus = deviceStatus[index];

        if (
          markerStatus.type !== "media" && 
          markerStatus.status !== "alert" && 
          markerStatus.status === "marker_arv" && 
          (latestIndex !== index || +markerStatus.content.marker_id !== +latestMarker)
        ) {
          latestMarker = markerStatus.content.marker_id;
          latestIndex = index;
          break;
        }
      }

      for (let index = latestIndex; index < deviceStatus.length; index++) {
        const markerStatus = deviceStatus[index];
        if(markerStatus.status === "alert" || markerStatus.type === "media") continue;
        if (
          +markerStatus.content.marker_id !== +latestMarker || 
          +item.id !== +latestMarker || 
          (markerStatus.status === "marker_arv" && index !== latestIndex) ||
          markerStatus.status === "landed"
        ) break;

        if (markerStatus.status === "marker_arv") {
          makerArrTime = markerStatus.content.utc;
        }
        
        markerInfo = { ...markerStatus };
        markerContent = {
          ...markerStatus.content,
          ...markerContent,
        };

        if(makerArrTime < currentTimeVideo) {
          trackingPassItem = true;
        }

        if(markerStatus.status === "marker_left") {
          makerLeftTime = markerStatus.content.utc;
          if(markerStatus.content.utc > currentTimeVideo) {
            item["status"] = "marker_arv"
          } else {
            item["status"] = "marker_left"
          }
        }
      }

      item = { ...markerInfo, ...item };
      item["content"] = markerContent;
      item["trackingPassItem"] = trackingPassItem;
      item["makerArrTime"] = makerArrTime;
      item["makerLeftTime"] = makerLeftTime;

      if (trackingPassItem) {
        mapContentDisplay.push(item);
      } else {
        item["status"] = "pending";
        mapContentDisplay.push(item);
      }
    });

    const medias = deviceStatus.filter(item => item.status === "image");

    if(medias.length) {
      mapContentDisplay = mapContentDisplay.map((item, index, arr) => {
        if(index < arr.length - 1) {
          if(item.isTakeoff) {
            const markerLeftImages = medias.filter(it => {
              if(arr[index + 1].makerArrTime) return +it.content.utc >= +item.content.utc && +it.content.utc < +arr[index + 1].makerArrTime
              return +it.content.utc >= +item.content.utc
            });
            let urlsMarkerLeft = "";
            if(markerLeftImages.length) {
              urlsMarkerLeft = markerLeftImages.map(it => it.content.url).join("");
            }
            return {
              ...item, 
              content: {
                ...item.content,
                markerLeftImages: urlsMarkerLeft
              }
            }
          }

          if(!item.makerLeftTime) {
            const markerImages = medias.filter(it => +it.content.utc >= +item.makerArrTime);
            let urlsMarker = "";
            if(markerImages.length) {
              urlsMarker = markerImages.map(it => it.content.url).join("");
            }

            return {
              ...item,
              content: {
                ...item.content,
                url: urlsMarker,
              }
            };
          }

          const markerImages = medias.filter(it => +it.content.utc >= +item.makerArrTime && +it.content.utc < +item.makerLeftTime);
          let markerLeftImages = medias.filter(it => {
            if(item.nextMarker === -1) return +it.content.utc >= +item.makerLeftTime;
            if(arr[index + 1].makerArrTime) return +it.content.utc >= +item.makerLeftTime && +it.content.utc < +arr[index + 1].makerArrTime;
            return +it.content.utc >= +item.makerLeftTime;
          });
          let urlsMarker = "", urlsMarkerLeft = "";

          if(markerImages.length) {
            urlsMarker = markerImages.map(it => it.content.url).join("");
          }

          if(markerLeftImages.length) {
            urlsMarkerLeft = markerLeftImages.map(it => it.content.url).join("");
          }

          return {
            ...item,
            content: {
              ...item.content,
              url: urlsMarker,
              markerLeftImages: urlsMarkerLeft
            }
          };
        } 
        return item;
      });
    }

    return mapContentDisplay;
  }

  const convertOldData = (data, flightPlanContent) => {
    const mapContentDisplay = [];
    const { deviceStatus } = data;
    let latestIndex = 0,
      latestMarker,
      currentTimeVideo = 0;

    flightPlanContent.map((item, i, arr) => {
      if (accountType === "robot") {
        var takeoff = deviceStatus.find(function (result) {
          return result.status === "takeoff";
        });
        currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime);
      }

      if (item.isTakeoff) {
        const takeoff = deviceStatus.find((it) => it.status === "takeoff");
        mapContentDisplay.push({ ...takeoff, ...item });
        latestMarker = 0;
        latestIndex = 1;
        currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime);
        return;
      }
      if (item.isLanding) {
        const landing = deviceStatus.find((it) => it.status === "landed");
        mapContentDisplay.push({ ...landing, ...item });
        latestMarker = -1;
        if (
          landing.content.utc < currentTimeVideo &&
          currentMarker !== Constants.LANDING_MARKER_ID
        ) {
          setCurrentMarker(Constants.LANDING_MARKER_ID);
        }
        return;
      }

      let markerContent = {},
        trackingPassItem = false,
        markerUrl = "",
        makerArrTime = null,
        markerInfo;

      for (let index = latestIndex; index < deviceStatus.length; index++) {
        const markerStatus = deviceStatus[index];
        if (!(+markerStatus.content.marker_id === +latestMarker || +markerStatus.content.id === +latestMarker) && markerStatus.status !== "alert") {
          latestMarker =
            markerStatus.content.marker_id || markerStatus.content.id;
          latestIndex = index;
          break;
        }
      }

      for (let index = latestIndex; index < deviceStatus.length; index++) {
        const markerStatus = deviceStatus[index];
        if(markerStatus.status === "alert") continue;
        if (!(+markerStatus.content.marker_id === +latestMarker || +markerStatus.content.id === +latestMarker) || markerStatus.status === "landed") break;

        if (markerStatus.status === "marker_arv") {
          makerArrTime = markerStatus.content.utc;
        }
        
        if (markerStatus.content.url) {
          markerUrl += markerStatus.content.url;
          markerUrl = [...new Set(markerUrl.split(" "))].join(" ")
        }
        markerInfo = { ...markerStatus };
        markerContent = {
          ...markerStatus.content,
          ...markerContent,
          url: markerUrl || null,
        };

        if(makerArrTime < currentTimeVideo) {
          trackingPassItem = true;
        }

        if(markerStatus.status === "marker_left") {
          if(markerStatus.content.utc > currentTimeVideo) {
            item["status"] = "marker_arv"
          } else {
            item["status"] = "marker_left"
          }
        }
      }

      item = { ...markerInfo, ...item };
      item["content"] = markerContent;
      item["trackingPassItem"] = trackingPassItem;
      item["makerArrTime"] = makerArrTime;

      if (trackingPassItem) {
        mapContentDisplay.push(item);
      } else {
        item["status"] = "pending";
        mapContentDisplay.push(item);
      }
    });

    return mapContentDisplay;
  }

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id == obj.id && list[i].direction == obj.direction) {
        return true;
      }
    }

    return false;
  }

  const changeViewMain = (element) => {
    setElementMainView(element); //video, sensor, map, images
  }

  const handleDoubleClickPanel = (element) => {
    if (elementMainView !== element && !isSettingLegendMB) {
      setElementMainView(element); //video, sensor, map, images
    }
  }

  const handleResizeLayout = (sizes) => {
    setSizeMain(sizes[0]);
  }

  const convertDeviceStatusKirihaModeByTime = (deviceStatus, currentTimeVideo) => {
    let lastIndexStatusByTime = deviceStatus.findIndex(item => Number(item.content.utc) >= currentTimeVideo);
    if (lastIndexStatusByTime === -1) lastIndexStatusByTime = deviceStatus.length;
    return cloneDeep(deviceStatus).splice(0, lastIndexStatusByTime);
  }

  const handleVideoEnd = () => {
    initialSettingChart(sensorsChart, takeoffUtc);
    setIsDisplayAllSensor(true);
  }

  // Render handle
  const renderDeviceIcon = (c, item) => {
    if (accountType === "robot") {
      let iconUrl;
      deviceIcons.map((item) => {
        if (item.key === "ROBOT_ICON" && item.value) {
          iconUrl = Constants.getResourceUrl() + item.value;
        }
      });

      if (!iconUrl) {
        iconUrl = Images.IconRobot;
      }

      return (
        <img
          alt=""
          className={"device-icon-tag " + (c ? c : "")}
          src={iconUrl}
        />
      );
    } else if (item) {
      const { deviceInfo } = item;
      if (deviceInfo.imagePath) {
        let iconUrl = Constants.getResourceUrl() + deviceInfo.imagePath;

        return (
          <img
            alt=""
            className={
              "device-icon-tag " +
              (c ? c : "") +
              (deviceInfo.status === "idle" ? "not-found" : "")
            }
            src={iconUrl}
          />
        );
      } else {
        return (
          <Images.Icons
            name="IconPlane"
            className={
              "device-icon-tag " +
              (c ? c : "") +
              (deviceInfo.status === "idle" ? "not-found" : "")
            }
          />
        );
      }
    } else {
      return (
        <Images.Icons name="IconPlane" className="device-icon-tag empty" />
      );
    }
  };

  const renderFlightHeight = (device) => {
    const { inspection: { jsonContent: { kirihaMode, flight: { flight_marker } } }, deviceStatus } = device;
    const deviceStatusArv = deviceStatus.filter(item => item.status === "marker_arv");
    let currentMarkerIndex = null;
    for (let index = deviceStatusArv.length - 1; index >= 0; index--) {
      const element = deviceStatusArv[index];

      if (+element.content.utc - takeoffUtc < currentTime) {
        currentMarkerIndex = index;
        break;
      }
    }

    const deviceLanded = deviceStatus.findLast(item => item.status === "landed");
    const isLanded = +deviceLanded.content.utc - takeoffUtc <= currentTime;

    let heightTry = 100,
        maxHeight = 0,
        height = currentTime > 0.01 && currentMarkerIndex !== null ? flight_marker[currentMarkerIndex]?.height : 0;
    flight_marker.forEach((item) => {
      if (maxHeight < +item.height) {
        maxHeight = Math.ceil(+item.height) + 3;
      }
    });

    if (kirihaMode) {
      const deviceStatusNotMedia = deviceStatus.filter(item => item.type !== "media");
      const ind = deviceStatusNotMedia.findIndex(item => +item.content.utc > takeoffUtc + currentTime);

      const deviceStatusInTimeVideo = ind !== -1 ? cloneDeep(deviceStatusNotMedia).slice(0, ind) : deviceStatusNotMedia;
      
      const { content: { dronePosition } } = deviceStatusInTimeVideo.at(-1);
      
      if (dronePosition) {
        if (dronePosition.z !== null) height = Number(dronePosition.z);
      }
    }

    if (isLanded) {
      height = 0;
    }

    heightTry = maxHeight * 25;

    let position = heightTry * (height / maxHeight);

    const getHeightDrone = {
      transform: `translate(-50%, -${position}px)`,
    };

    const getLineStep = () => {
      let temp = "";
      Array.from(Array(maxHeight).keys()).forEach((item, index, arr) => {
        temp += `0 ${(item + 1) * 25}px 0 #fff`;
  
        if (index !== arr.length - 1) {
          temp += ", ";
        }
      })

      return temp;
    }

    return (
      <div className="flight-height">
        <div className="flight-height_content">
          {Array.from(Array(maxHeight + 1).keys()).map(item => (
            <span className="step_height">{item}m</span>
          ))}
        </div>
        <div className="flight-height_try">
          <div
            className="try_vertical"
            style={{ height: heightTry + "px" }}
          ></div>
          <div className="try_horizontal" style={{ boxShadow: getLineStep() }}></div>
          <div className="drone_position" style={getHeightDrone}></div>
        </div>
      </div>
    );
  }

  const renderMapPreview = (device) => {
    const { inspection: { mapImage, jsonContent: { kirihaMode }, kirihaData, delay_t }, deviceStatus } = device;

    const deviceStatusNotMedia = deviceStatus.filter(item => item.type !== "media");

    if (kirihaData) {
      const { markerPosition } = kirihaData;
      let takeoff = deviceStatusNotMedia.find((x) => x.status === "takeoff");
      let currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime) + Number(delay_t) * -1;
      const deviceStatusHandle = currentTime === 0 ? deviceStatusNotMedia : convertDeviceStatusKirihaModeByTime(deviceStatusNotMedia, currentTimeVideo);
      
      return (
        <MapPreviewStatusKirihaMode
          inspection={device.inspection}
          deviceStatus={deviceStatusHandle}
          sizeMain={sizeMain}
          elementMainView={elementMainView}
          isFullScreenMode={isFullScreenMode}
          markerPosition={markerPosition}
        />
      )
    } else {
      let takeoff = deviceStatus.find((x) => x.status === "takeoff");
      let landed = deviceStatus.find((x) => x.status === "landed");
      let currentTimeVideo = Number(takeoff.content.utc) + Number(currentTime);
  
      let arvMarkers = deviceStatus.filter(
        (x) => x.status == "marker_arv"
      );
      arvMarkers = [takeoff, ...arvMarkers, landed];
      let endMarker =
        arvMarkers.find((x) => x.content.utc >= currentTimeVideo) || landed;
      let startMarker =
        arvMarkers.reverse().find((x) => x.content.utc <= currentTimeVideo) ||
        takeoff;
  
      let startMarkerId =
        startMarker.status == "takeoff" ? 0 : startMarker.content.marker_id;
      if (startMarker.status == "landed") {
        startMarkerId = -1;
      }
  
      let endMarkerId = +endMarker.content.marker_id || -1;
      if (endMarker.status == "landed") {
        endMarkerId = -1;
      }
      if (currentTime < 0.001) {
        endMarkerId = 1;
      }
  
      let isKiriha = false;
  
      const kirihaSt = deviceStatus.find(item => item.status === 'kiriha_st');
      const kirihaEnd = deviceStatus.find(item => item.status === 'kiriha_end');
  
      if(kirihaSt && kirihaEnd) {
        const timeKirihaSt = Number(kirihaSt.content.utc);
        const timeKirihaEnd = Number(kirihaEnd.content.utc);
  
        if (currentTimeVideo >= timeKirihaSt && currentTimeVideo <= timeKirihaEnd) {
          isKiriha = true;
        } else {
          isKiriha = false;
        }
      }
  
      return (
        <MapPreview
          url={mapImage}
          markers={dataConvertForMap}
          activeInspection={device}
          currentMarker={startMarkerId}
          currentTime={currentTime}
          endMarker={endMarkerId}
          sizeMain={sizeMain}
          elementMainView={elementMainView}
          isFullScreenMode={isFullScreenMode}
          isKiriha={isKiriha}
          t={t}
        />
      );
    }
  };

  const renderVideo = (device) => (
    <VideoResultComponent 
      data={device}
      currentTime={currentTime}
      updateCurrentTime={(newTime) => setCurrentTime(newTime)}
      elementMainView={elementMainView}
      setIsDisplayAllSensor={setIsDisplayAllSensor}
      onVideoEnd={handleVideoEnd}
    />
  )

  const renderPreviewSensor = (device, isMainView) => {
    return (
      <ResultSensorComponent
        isMainView={isMainView}
        sensorCsv={sensorCsv}
        sensorsInfo={sensorsInfo}
        sensorsChart={sensorsChart}
        chartSetting={chartSetting}
        isMultiDate={isMultiDate}
        takeoffUtc={takeoffUtc}
        handleUpdateChartSetting={(newSetting) => setChartSetting(newSetting)}
        colorSensor={colorSensor}
        sizeMain={sizeMain}
        planNo={device.inspection.planNo}
        isViewChart={isViewChart}
        handleUpdateIsViewChart={(status) => setIsViewChart(status)}
        isSettingLegendMB={isSettingLegendMB}
        setIsSettingLegendMB={(status) => setIsSettingLegendMB(status)}
        isDisplayAllSensor={isDisplayAllSensor}
        onChangeIsDisplayAllSensor={setIsDisplayAllSensor}
      />
    )
  }

  const renderMainContent = () => {
    // Drone status
    const { deviceInfo, deviceAlert } = droneDetail;
    const haveAlert = deviceAlert && deviceAlert.length > 0;

    // Result data
    const { takeoffMemo, inspection: { planName, inspectionNo } } = activeInspection;

    return (
      <div className="drone-detail-status">
        <div className="drone-detail-status-info">
          <div className="device-item">
            <div 
              className="drone device" 
              ref={droneInfoRef} 
            >
              <div className="device-info-wrap">
                {renderDeviceIcon('', droneDetail)}
                <div className='device-info'>
                  <p className="type">{deviceInfo.deviceType}</p>
                  <p className="name">{deviceInfo.deviceID}</p>
                </div>
              </div>
              
              {haveAlert &&
                <Images.IconAlert />
              }
              <button
                className={"btn-alert" + (isShowOptionsAlert ? " active" : "")}
                onClick={() => setIsShowOptionsAlert(!isShowOptionsAlert)}
                ref={btnAlertOptionsRef}
              >
                <Images.IconDots />
              </button>

              {isShowOptionsAlert &&
                <div className="form-option" ref={formAlertOptionsRef}>
                  <div 
                    className="item" 
                    onClick={() => {
                      setIsShowsAlert(true)
                      setIsShowOptionsAlert(false)
                    }}
                  >Alert history</div>
                  <div className="item bg-reset"></div>
                </div>
              }
            </div>
          </div>
          
          <div className="flight-plan">
            {planName}
          </div>
          {faceIdFlying && 
            <p className="face-id">
              <Images.IconKirihaIcon />
              <span>Face ID: {faceIdFlying}</span>
            </p>
          }

          {flightHistory && 
            <div className="main-action-result">
              <div className="dps-select">
                <select
                  className="mr-0"
                  name="drone_id"
                  value={resultActive}
                  onChange={(e) => handleChangeResultDetail(e)}
                >
                  {flightHistory.map((item) => {
                    return (
                      <option value={item.inspectionNo} key={item.inspectionNo}>
                        {timeConverter(item.inspectionNo, t)}
                      </option>
                    );
                  })}
                </select>
                <Images.Icons name="IconSelect" color="currentColor" />
              </div>

              <button
                className={"btn-delete-download" + (isShowFormDownloadAndDelete ? " active" : "")}
                ref={btnDownloadAndDeleteRef}
                onClick={() => setIsShowFormDownloadAndDelete(!isShowFormDownloadAndDelete)}
              >
                <Images.IconDots />
              </button>

              {isShowFormDownloadAndDelete &&
                <div className="form-option" ref={optionDoawnloadAndDeleteRef}>
                  <div 
                    className="item" 
                    onClick={(e) => handleDownload(e, inspectionNo)}
                  >Download Log</div>
                  <div 
                    className="item"
                    onClick={(e) => handleDelete(e)}
                  >Delete</div>
                </div>
              }
            </div>
          }

          {takeoffMemo &&
            <div className="flight-takeoff-memo">
              <p className="text">
                <strong>Flight memo :</strong>
                {takeoffMemo}
              </p>
            </div>
          }

          {isShowsAlert &&
            <div className="alert-history" ref={alertHistoryRef}>
              <div className="alert-history-header">
                <h5>Alert history</h5>
                <button onClick={() => setIsShowsAlert(false)}>
                  <Images.IconClose color="currentColor" />
                </button>
              </div>

              <div className="alert-history-body">
                {allAlert.alerts && allAlert.alerts.length ? allAlert.alerts.map(item => (
                  <div className="alert-history-item">
                    <span>
                      {timeMinSecConverter(+item.utc - +takeoffUtc) + " | " + timeSmallConverter(item.utc)}
                    </span>
                    <p>{item.msg}</p>
                  </div>
                  )) : undefined
                }
              </div>

            </div>
          }

          <PlayingTracking
            dataConvertForPreview={dataConvertForPreview}
            data={activeInspection}
            currentTime={currentTime}
          />
        </div>

        <div className="drone-detail-status-content">
          <PanelGroup
            className="panel-wrapper" 
            onLayout={handleResizeLayout}
            minMain={40}
            minSub={20}
            defaultMain={70}
          >
            <Panel
              className={
                (elementMainView === "video" ? "main" : "sub sub-l") + 
                (elementMainView === "video" && isFullScreenMode ? " main-full-screen" : "")
              }
              id={elementMainView === "video" ? "panel-main" : ""}
              onDoubleClick={() => handleDoubleClickPanel("video")}
            >
              {renderVideo(activeInspection)}
            </Panel>

            <Panel
              className={
                (elementMainView === "map" ? "main" :
                elementMainView === "video" ? "sub sub-l" : "sub sub-r") +
                (elementMainView === "map" && isFullScreenMode ? " main-full-screen" : "")
              }
              id={elementMainView === "map" ? "panel-main" : ""}
              onDoubleClick={() => handleDoubleClickPanel("map")}
            >
              {renderMapPreview(activeInspection)}
            </Panel>

            {elementMainView !== "images" &&
              <Panel
                className={
                  (elementMainView === "sensor" ? "main" :"sub sub-r") +
                  (elementMainView === "sensor" && isFullScreenMode ? " main-full-screen" : "")
                }
                id={elementMainView === "sensor" ? "panel-main" : ""}
                onDoubleClick={() => handleDoubleClickPanel("sensor")}
              >
                {renderPreviewSensor(activeInspection, elementMainView === "sensor" ? true : false)}
              </Panel>
            }

            {elementMainView === "images" &&
              <Panel
                className={("main") + (elementMainView === "images" && isFullScreenMode ? " main-full-screen" : "")}
                id={"panel-main"}
              >
                <ImgaesViewComponent device={activeInspection} />
              </Panel>
            }

            <div className={"main-actions ct-scroll" + (isFullScreenMode ? " main-actions-full-screen" : "")}>
              <div className="actions">
                <button 
                  className="btn-full-screen"
                  onClick={() => setIsFullScreenMode(!isFullScreenMode)}
                >
                  {isFullScreenMode ?
                    <Images.IconCloseFullScreen color="currentColor" />
                    :
                    <Images.IconFullScreen color="currentColor" />
                  }
                </button>

                <button 
                  className={"btn-stream" + (elementMainView === 'video' ? ' active' : "")} 
                  onClick={() => changeViewMain("video")}
                >
                  <Images.IconCamera color="currentColor" />
                </button>

                <button 
                  className={"btn-images" + (elementMainView === 'images' ? ' active' : "")}
                  onClick={() => changeViewMain("images")}
                >
                  <Images.IconPictureList color="currentColor" />
                </button>

                <button 
                  className={"btn-map" + (elementMainView === 'map' ? ' active' : "")} 
                  onClick={() => changeViewMain("map")}
                >
                  <Images.IconMapProcess color="currentColor" />
                </button>

                <button 
                  className={"btn-sensor" + (elementMainView === 'sensor' ? ' active' : "")}
                  onClick={() => changeViewMain("sensor")}
                >
                  <Images.SensorIcon color="currentColor" />
                </button>
              </div>
              {renderFlightHeight(activeInspection)}
            </div>
          </PanelGroup>
        </div>
      </div>
    )
  }

  const renderMainContentMB = () => {
    // Drone status
    const { deviceInfo, deviceAlert } = droneDetail;
    const haveAlert = deviceAlert && deviceAlert.length > 0;

    // Result data
    const { takeoffMemo, inspection: { planName, inspectionNo } } = activeInspection;

    return (
      <div className="drone-detail-status">
        <div className="drone-detail-status-info">
          <div className="device-item">
            <div 
              className="drone device" 
              ref={droneInfoRef} 
            >
              <div className="device-info-wrap">
                {renderDeviceIcon('', droneDetail)}
                <div className='device-info'>
                  <p className="type">{deviceInfo.deviceType}</p>
                  <p className="name">{deviceInfo.deviceID}</p>
                </div>
              </div>
              
              {haveAlert &&
                <Images.IconAlert />
              }
              <button
                className={"btn-alert" + (isShowOptionsAlert ? " active" : "")}
                onClick={() => setIsShowOptionsAlert(!isShowOptionsAlert)}
                ref={btnAlertOptionsRef}
              >
                <Images.IconDots />
              </button>

              {isShowOptionsAlert &&
                <div className="form-option" ref={formAlertOptionsRef}>
                  <div 
                    className="item" 
                    onClick={() => {
                      setIsShowsAlert(true)
                      setIsShowOptionsAlert(false)
                    }}
                  >Alert history</div>
                  <div className="item bg-reset"></div>
                </div>
              }
            </div>
          </div>
          
          <div className="flight-plan">
            {planName}
          </div>
          {faceIdFlying && 
            <p className="face-id">
              <Images.IconKirihaIcon />
              <span>Face ID: {faceIdFlying}</span>
            </p>
          }

          {flightHistory && 
            <div className="main-action-result">
              <div className="dps-select">
                <select
                  className="mr-0"
                  name="drone_id"
                  value={resultActive}
                  onChange={(e) => handleChangeResultDetail(e)}
                >
                  {flightHistory.map((item) => {
                    return (
                      <option value={item.inspectionNo} key={item.inspectionNo}>
                        {timeConverter(item.inspectionNo, t)}
                      </option>
                    );
                  })}
                </select>
                <Images.Icons name="IconSelect" color="currentColor" />
              </div>

              <button
                className={"btn-delete-download" + (isShowFormDownloadAndDelete ? " active" : "")}
                ref={btnDownloadAndDeleteRef}
                onClick={() => setIsShowFormDownloadAndDelete(!isShowFormDownloadAndDelete)}
              >
                <Images.IconDots />
              </button>

              {isShowFormDownloadAndDelete &&
                <div className="form-option" ref={optionDoawnloadAndDeleteRef}>
                  <div 
                    className="item" 
                    onClick={(e) => handleDownload(e, inspectionNo)}
                  >Download Log</div>
                  <div 
                    className="item"
                    onClick={(e) => handleDelete(e)}
                  >Delete</div>
                </div>
              }
            </div>
          }

          {takeoffMemo &&
            <div className="flight-takeoff-memo">
              <p className="text">
                <strong>Flight memo :</strong>
                {takeoffMemo}
              </p>
            </div>
          }

          {isShowsAlert &&
            <div className="alert-history" ref={alertHistoryRef}>
              <div className="alert-history-header">
                <h5>Alert history</h5>
                <button onClick={() => setIsShowsAlert(false)}>
                  <Images.IconClose color="currentColor" />
                </button>
              </div>

              <div className="alert-history-body">
                {allAlert.alerts && allAlert.alerts.length ? allAlert.alerts.map(item => (
                  <div className="alert-history-item">
                    <span>
                      {timeMinSecConverter(+item.utc - +takeoffUtc) + " | " + timeSmallConverter(item.utc)}
                    </span>
                    <p>{item.msg}</p>
                  </div>
                  )) : undefined
                }
              </div>
            </div>
          }

          <PlayingTracking
            dataConvertForPreview={dataConvertForPreview}
            data={activeInspection}
            currentTime={currentTime}
          />
        </div>

        <div className="drone-detail-status-content">
          <div className="drone-detail-status-content-wrap-mb">
            <div className="main-actions">
              <div className="actions">
                <button 
                  className={"btn-sensor" + (elementMainView === 'sensor' ? ' active' : "")}
                  onClick={() => changeViewMain("sensor")}
                >
                  <Images.SensorIcon color="currentColor" />
                </button>

                <button 
                  className={"btn-map" + (elementMainView === 'map' ? ' active' : "")} 
                  onClick={() => changeViewMain("map")}
                >
                  <Images.IconMapProcess color="currentColor" />
                </button>

                <button 
                  className={"btn-images" + (elementMainView === 'images' ? ' active' : "")}
                  onClick={() => changeViewMain("images")}
                >
                  <Images.IconPictureList color="currentColor" />
                </button>

                <button 
                  className={"btn-stream" + (elementMainView === 'video' ? ' active' : "")} 
                  onClick={() => changeViewMain("video")}
                >
                  <Images.IconCamera color="currentColor" />
                </button>
              </div>
            </div>

            {elementMainView === "images" &&
              <div className="card-content active">
                <ImgaesViewComponent device={activeInspection} />
              </div>
            }

            <div 
              className={"card-content" + (elementMainView === "video" ? " active" : "")}
              onClick={() => handleDoubleClickPanel('video')}
            >
              {renderVideo(activeInspection)}
            </div>

            <div 
              className={"card-content" + (elementMainView === "map" ? " active" : "")}
              onClick={() => handleDoubleClickPanel('map')}
            >
              {renderMapPreview(activeInspection)}
            </div>

            <div 
              className={"card-content" + (elementMainView === "sensor" ? " active" : "")}
              onClick={() => handleDoubleClickPanel('sensor')}
            >
              {renderPreviewSensor(activeInspection, elementMainView === "sensor" ? true : false)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (droneDetail && activeInspection) {
    if (isMobileOnly) return renderMainContentMB();
    return renderMainContent();
  }
}

export default memo(ResultDetailComponent);