import React, { Component } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  Router
} from "react-router-dom";

import AuthService from "../services/auth.service";
import { withTranslation } from "react-i18next";

// Admin screens
import User from "../screens/admin/user";
import Profile from "../screens/admin/profile";
import Icon from "../screens/admin/icon";
import Parameter from "../screens/admin/parameter";
import DroneType from "../screens/admin/drone-type";
import PrivacySupports from "../screens/admin/privacy-supports";

//General screens
import Login from "../screens/login";
import Logout from "../screens/logout";
import Testing from "../screens/testing";
import Support from "../screens/support";
import TermsOfUse from "../screens/terms-of-use";
import Privacy from "../screens/privacy";

//User screens
import Home from "../screens/home";
import Status from "../screens/status";
import InspectionResult from "../screens/inspectionResult";
import Setting from "../screens/setting";
import Avatar from "../screens/avatar";
import ChargingStation from "../screens/chargingStation";
import Plan from "../screens/plan";
import Scan from "../screens/scan";
import Schedule from "../screens/schedule";

import axios from "axios";
import { createBrowserHistory } from "history";
import { isMobileOnly, MobileOnlyView, isTablet } from "react-device-detect";
import { isMobile } from "react-device-detect";

// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { REFRESH_TOKEN_URL } from "../utils/constants";
import ResetPassword from "../components/ResetPassword";
import StationType from "../screens/admin/station-type";

export const history = createBrowserHistory();

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  if (localStorage.getItem("user") == null) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { referrer: location },
        }}
      />
    );
  } else {
    if (!isMobileOnly) {
      return (
        <div className={isTablet ? "check_is_tablet" : ""}>
          <Route {...rest} render={({ location }) => children} />
          <NotificationContainer />
        </div>
      );
    } else {
      return (
        <>
          <MobileOnlyView>
            <div className="dps-mobile-view">
              <Route {...rest} render={({ location }) => children} />
            </div>
          </MobileOnlyView>
        </>
      );
    }
  }
}

function PrivateRouteDesktop({ children, ...rest }) {
  let location = useLocation();
  if (localStorage.getItem("user") == null) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { referrer: location },
        }}
      />
    );
  } else {
    if (!isMobileOnly) {
      return (
        <>
          <Route {...rest} render={({ location }) => children} />
          <NotificationContainer />
        </>
      );
    } else {
      return (
        <>
          <MobileOnlyView>
            <h1 className="mobileNotice">
              これはデスクトップでのみレンダリングされます
            </h1>
          </MobileOnlyView>
        </>
      );
    }
  }
}

function PrivateRouteMobile({ children, ...rest }) {
  let location = useLocation();
  if (localStorage.getItem("user") == null) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { referrer: location },
        }}
      />
    );
  } else {
    if (!isMobileOnly) {
      return (
        <>
          <h1 className="mobileNotice">
            これはデスクトップでのみレンダリングされます
          </h1>
        </>
      );
    } else {
      return (
        <>
          <div className="dps-mobile-view">
            <Route {...rest} render={({ location }) => children} />
          </div>
        </>
      );
    }
  }
}

function PublicRoute({ children, ...rest }) {
  let location = useLocation();

  if (
    (localStorage.getItem("user") != null) &
    (history.location.pathname === "/")
  ) {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user.roles.includes("ROLE_ADMIN")) {
      return (
        <Redirect
          to={{
            pathname: "/user",
            state: { referrer: location },
          }}
        />
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/status",
            state: { referrer: location },
          }}
        />
      );
    }
  } else {
    if (!isMobileOnly) {
      return (
        <>
          <Route {...rest} render={({ location }) => children} />
          <NotificationContainer />
        </>
      );
    } else {
      return (
        <MobileOnlyView>
          <div className="dps-mobile-view">
            <Route {...rest} render={({ location }) => children} />
          </div>
        </MobileOnlyView>
      );
    }
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        return new Promise((resolve, reject) => {
          const originalReq = error.config;
          if (
            error.response &&
            error.response.status === 401 &&
            error.response.data.path !== "/api/auth/signin"
          ) {
            if (error.config && !error.config.__isRetryRequest) {
              originalReq.__isRetryRequest = true;
              let currentUser = JSON.parse(localStorage.getItem("user"));
              const { refreshToken } = currentUser;
              const payload = { refreshToken: refreshToken };
              let res = fetch(REFRESH_TOKEN_URL, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json;charset=utf-8",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "*",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                },
                body: JSON.stringify(payload),
              })
                .then((res) => res.json())
                .then((res) => {
                  currentUser["accessToken"] = res.accessToken;
                  currentUser["backendVersion"] = res.backendVersion;
                  localStorage.setItem("user", JSON.stringify(currentUser));
                  // Update new access token
                  originalReq.headers["Authorization"] =
                    "Bearer " + res.accessToken;
                  return axios(originalReq);
                });

              resolve(res);
            } else {
              history.push("/logout");
            }
          }
          reject(error);
        });
      }
    );
  }

  componentDidMount() {
    if (!localStorage.getItem("developerMode")) {
      localStorage.setItem("developerMode", "false");
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/reset-password/:id" component={ResetPassword} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/status" component={Status} />
          <PrivateRoute exact path="/logout" component={Logout} />
          <PrivateRoute exact path="/inspection-result" component={InspectionResult} />
          <PrivateRoute exact path="/plan" component={Plan} />
          <PrivateRouteDesktop exact path="/user" component={User} />
          <PrivateRouteDesktop exact path="/setting/profile" component={Profile} />
          <PrivateRouteDesktop exact path="/setting/icon" component={Icon} />
          <PrivateRouteDesktop exact path="/setting/parameter" component={Parameter} />
          <PrivateRouteDesktop exact path="/setting/drone-type" component={DroneType} />
          <PrivateRouteDesktop exact path="/setting/station-type" component={StationType} />
          <PrivateRouteDesktop exact path="/setting/privacy-supports" component={PrivacySupports} />
          <PrivateRouteDesktop exact path="/schedule" component={Schedule} />
          <PrivateRouteDesktop exact path="/setting/email" component={Setting} />
          <PrivateRouteDesktop exact path="/setting/avatar" component={Avatar} />
          <PrivateRouteDesktop exact path="/setting/station" component={ChargingStation} />
          <PublicRoute exact path="/content/support" component={Support} />
          <PublicRoute exact path="/content/privacy" component={Privacy} />
          <PublicRoute exact path="/content/terms-of-use" component={TermsOfUse} />
          <PrivateRouteMobile exact path="/scan" component={Scan} />

          {/* <PublicRoute component={NoMatchPage} /> */}

          <PublicRoute exact path="/testing" component={Testing} />
        </Switch>
      </Router>
    );
  }
}

export default withTranslation("dps")(App);
