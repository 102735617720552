import { memo, useState } from "react";
import { t } from "i18next";
import { faceKirahaMode, markerKirihaMode } from "./planContent";
import { cloneDeep } from "lodash";
import { IconArrowDown, IconCloseSensors } from "../images";

const initModal = {
  open: false,
  mode: null,
  data: null
}

const PlanContentMobile = (props) => {
  const { isReadOnly, plan, faceKirihaData, markerKirihaData, handleBackToStatusPage } = props;
  const KIRIHA_MODE = JSON.parse(localStorage.getItem("user")).kirihaMode;

  const [modal, setModal] = useState(initModal);

  const handleShowData = (mode) => {
    let data;

    switch (mode) {
      case faceKirahaMode:
        data = faceKirihaData;
        break;

      case markerKirihaMode:
        data = markerKirihaData;
        break;
    
      default: data = null; break;
    }

    setModal({ open: true, mode, data });
  }

  const renderModalData = () => {
    const { mode, data } = modal;
    const dataClone = cloneDeep(data);
    const isNoData = !dataClone.length;

    let headerData, bodyData, title;

    switch (mode) {
      case faceKirahaMode:
        title = "Face Data";
        if (!isNoData) {
          const firstData = dataClone.shift();
          const headerDataSplit = firstData.split(",");
          
          headerData = [headerDataSplit[0], headerDataSplit[1]];
          bodyData = dataClone.map(item => {
            const [ id, val_1 ] = item.split(",");
            return { id, val_1 };
          });
        }
        break;

      case markerKirihaMode:
        title = "Marker Data";
        if (!isNoData) {
          const firstData = dataClone.shift();
          const headerDataSplit = firstData.split(",");
          
          headerData = [headerDataSplit[0], headerDataSplit[1], headerDataSplit[2], headerDataSplit[3]];
          bodyData = dataClone.map(item => {
            const [ id, val_1, val_2, val_3 ] = item.split(",");
            return { id, val_1, val_2, val_3 };
          });
        }
        break;
    
      default: break;
    }

    return (
      <div className="data-modal">
        <div className="header">
          <h4>{title}</h4>
          <button onClick={() => setModal(initModal)}><IconCloseSensors /></button>
        </div>

        {isNoData ? 
          <p className="no-data">No data</p>
          :
          <table className="table-data">
            <thead>
              <tr>
                {headerData.map(val => (
                  <th>{val}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bodyData.map(row => (
                <tr>
                  {Object.values(row).map(val => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    )
  }

  if (plan) {
    return (
      <div className="plan-content-mb">
        {modal.open && modal.data ? renderModalData() : 
          <>
            <div className="plan-info">
              {isReadOnly && 
                <button className="btn-goback" onClick={handleBackToStatusPage}>
                  <IconArrowDown color="#fff" />
                  {t("plan.back_to_status")}
                </button>
              }

              <p>
                <label>{t("map.flight_plan_number")}</label>
                : {plan.flight?.flight_plan_no}
              </p>
              <p>
                <label>{t("plan.drone.plan_name")}</label>
                : {plan.flight?.flight_plan_name}
              </p>
              <p>
                <label>{t("plan.drone.drone_type")}</label>
                : {plan.drone?.type}
              </p>
              <p>
                <label>{t("plan.drone.place")}</label>
                : {plan.user?.place}
              </p>
              <p>
                <label>{t("plan.drone.division")}</label>
                : {plan.user?.division}
              </p>
              {KIRIHA_MODE && 
                <>
                  <br />
                  <p>
                    <label>{t("plan.kiriha_label")}</label>
                    : {plan.kirihaMode ? "ON" : "OFF"}
                  </p>
                  <p>
                    <label>{t("plan.capture_distance")}</label>
                    : {plan.captureDistance}
                  </p>
                </>
              }
            </div>

            <div className="action-data">
              {KIRIHA_MODE && <button onClick={() => handleShowData(faceKirahaMode)}>Face Data</button>}
              <button onClick={() => handleShowData(markerKirihaMode)}>Marker Data</button>
            </div>

            <table className="flight-marker">
              <thead>
                <tr>
                  <th><span>Marker</span></th>
                  <th><span>{t('plan.marker.drone.height')}</span></th>
                  <th><span>{t('plan.marker.drone.wall_distance')}</span></th>
                  <th><span>{t('plan.marker.drone.landing_port')}</span></th>
                </tr>
              </thead>
              <tbody>
                {plan.flight?.flight_marker?.map(item => {
                  if (item.id === "kiriha") {
                    return (
                      <tr className="marker-item">
                        <td>{item.id}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )
                  }

                  return (
                    <tr className="marker-item">
                      <td>{item.id}</td>
                      <td>{item.height}</td>
                      <td>{item.wall_distance}</td>
                      <td>
                        {item.isStation === "false" || item.isStation === "else" ? t("common.no") : item.isStation }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        }
      </div>
    )
  }
}

export default memo(PlanContentMobile);