import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useLayoutEffect
} from "react";
import {  timeMinSecConverter, timeSmallConverter } from "../../utils/utils";
import axios from "axios";
import * as Constants from "../../utils/constants";
import { getHeader } from "../../services/api.service";
import PreviewGallery from "./previewGallery";
import picture from "../../assets/images/svg/picture.svg";


const PeviceTracking = forwardRef((props, ref) => {
  const { data, dataConvertForPreview, currentTime } = props;
  const { inspection: {jsonContent: {flight: {flight_marker}}, id: inspectionId, delay_t} } = data;

  const [mediaList, setMediaList] = useState(null);
  const [mediaTime, setMediaTime] = useState(null);
  const [mediaPreview, setMediaPreview] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currentMarkerPreview, setCurrentMarkerPreview] = useState(null);
  const [heightBrowser, setHeightBrowser] = useState(0);
  const [stationStart, setStationStart] = useState(null);
  const [stationEnd, setStationEnd] = useState(null);

  const playingWrapper = useRef(null)

  const flightProcessRef = useRef(null);
  const processWrapRef = useRef(null);
  const droneRef = useRef(null);

  const heightDrone = useRef(0);
  const isDroneLeftPosition = useRef(false);

  // useImperativeHandle(ref, () => ({
  //   resetCurrentMarker() {
  //     setCurrentMarker(0);
  //   },
  // }));

  useEffect(() => {
    processWrapRef.current?.addEventListener('scroll', autoHandleShadowProcessBar);

    return () => {
      processWrapRef.current?.removeEventListener('scroll', autoHandleShadowProcessBar);
    }
  }, [])

  useEffect(() => {
    flight_marker[0].isStation !== 'false' ? setStationStart(flight_marker[0].isStation) : setStationStart(null);
    flight_marker[flight_marker.length - 1].isStation !== 'false' ? setStationEnd(flight_marker[flight_marker.length - 1].isStation) : setStationEnd(null);
  }, [data])

  useEffect(() => {
    autoScrollWhenPlayVideo();
  }, [currentTime, heightDrone.current]);

  useEffect(() => {
    autoScrollWhenPlayVideo();
    autoHandleShadowProcessBar();
  }, [useWindowSize()])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setHeightBrowser(window.innerHeight);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return heightBrowser;
  }

  const autoScrollWhenPlayVideo = () => {
    const heightWrap = processWrapRef.current.clientHeight;
    const heightScroll = processWrapRef.current.scrollHeight;

    if (heightScroll > heightWrap) {
      const positonDrone = isDroneLeftPosition.current ? 14 : 17;
      processWrapRef.current.scrollTop = 12 + heightDrone.current - heightWrap / 2 - positonDrone;
    }
  };

  const autoHandleShadowProcessBar = () => {
    const heightWrap = processWrapRef.current.clientHeight;
    const scrollTop = processWrapRef.current.scrollTop;
    const heightScroll = processWrapRef.current.scrollHeight;

    if (scrollTop) {
      flightProcessRef.current.classList.add("shadow-t");
    } else {
      flightProcessRef.current.classList.remove("shadow-t");
    }

    if(heightScroll > scrollTop + heightWrap) {
      flightProcessRef.current.classList.add("shadow-bt");
    } else {
      flightProcessRef.current.classList.remove("shadow-bt");
    }
  }

  const renderTakeoffMarker = (data) => {
    const { content } = data;
    return (
      <div  className={"marker-item marker-station marker-takeoff" + (currentTime > 0.01 + Number(delay_t) ? " tracking-pass-item" : "")} key={Math.random()}>
        <div className="icon">
          <img src={require("../../assets/images/cs-opened.png")} alt="" className={stationStart ? "" : " empty"} />
        </div>
        <div className="marker-info marker-station-info">
          <p className={"name" + (stationStart ? "" : " empty")}>{stationStart ? stationStart : "Empty"}</p>
          <p className="time">
            {timeMinSecConverter(0) +  " | " + timeSmallConverter(+content.utc)}
          </p>

          {content && content.markerLeftImages && 
            <button
              onClick={() => handleOpen(data, true)}
              className="capture-letf"
            >
              <img
                src={picture}
                alt=""
              />
            </button>
          }
        </div>
      </div>
    )
  }

  const renderLandingMarker = (data, utcTakeoff, isLanded, isLandedNotSuccessPlan) => {
    return (
      <div 
        className={
          "marker-item marker-station marker-landing" + 
          (isLanded ? " tracking-pass-item" : "") + 
          (isLandedNotSuccessPlan ? " not-success-plan" : "")
        } 
        key={Math.random()}
      >
        <div className="icon">
          <img src={require("../../assets/images/cs-opened.png")} alt="" className={stationEnd ? "" : " empty"} />
        </div>
        <div className="marker-info marker-station-info">
          <p className={"name" + (stationEnd ? "" : " empty")}>{stationEnd ? stationEnd : "Empty"}</p>
          <p className="time">
            {timeMinSecConverter(+data.content.utc - utcTakeoff) +  " | " + timeSmallConverter(+data.content.utc)}
          </p>
        </div>
        <span className="line"></span>
      </div>
    )
  }

  const renderMarkerItem = (data, utcTakeoff, isCurrentMarker) => {
    const { trackingPassItem, makerArrTime, content } = data;
    return (
      <div className={"marker-item " + (trackingPassItem ? 'tracking-pass-item' : '')} key={Math.random()}>
        <div className="marker-item-icon">
          <span style={isCurrentMarker ? { color: "#FFF" } : undefined}>{data.id}</span>
        </div>

        {makerArrTime &&
          <div className="marker-info">
            <span className="time marker-arv">
              {timeMinSecConverter(makerArrTime - utcTakeoff) +  " | " + timeSmallConverter(makerArrTime)}
            </span>

            {content.url && 
              <button
                onClick={() => handleOpen(data, false)}
                className="capture"
              >
                <img
                  src={picture}
                  alt=""
                />
              </button>
            }
            
            {content.markerLeftImages && 
              <button
                onClick={() => handleOpen(data, true)}
                className="capture-letf"
              >
                <img
                  src={picture}
                  alt=""
                />
              </button>
            }
          </div>
        }
        <span className="line"></span>
      </div>
    )
  }

  const renderResultTracking = (mapContentDisplay) => {
    const utcTakeoff = mapContentDisplay[0].content?.utc || 0;
    let nextMarker = 0,
      markerArv = false,
      markerLeft = false,
      styleDronePlay;

    [...mapContentDisplay].forEach((item, index, arr) => {
      if (
        item.status === "marker_arv" ||
        item.status === "image" ||
        item.status === "video"
      ) {
        markerLeft = false;
        markerArv = true;
      }
      if (item.status === "marker_left") {
        markerArv = false;
        markerLeft = true;
      }
      if (!item.isTakeoff && !item.trackingPassItem) {
        nextMarker = index;
        arr.length = index + 1;
      }
    });

    if (nextMarker === 1) {
      // drone playing position takeoff
      styleDronePlay = 60 + 28; 
      markerLeft = true;
    } else if (markerArv) {
      // drone playing position marker_arv
      let height = 60 + (nextMarker - 1) * 28 + (nextMarker - 1) * 34;
      styleDronePlay = height;
    } else if (markerLeft) {
      // drone playing position marker_left
      let height = 60 + nextMarker * 28 + (nextMarker - 1) * 34;
      styleDronePlay = height;
    }
    
    const dataLanded = mapContentDisplay.find(item => item.status === "landed");
    const isLanded = Number(dataLanded.content.utc) - Number(utcTakeoff) < currentTime;


    let isLandedNotSuccessPlan = false;

    for (let index = 0; index < mapContentDisplay.length; index++) {
      const item = mapContentDisplay[index];
      if (isLanded && !item.isTakeoff && !item.isLanding && !item.trackingPassItem) {
        isLandedNotSuccessPlan = true;
        break;
      }
    }

    if (!currentTime) styleDronePlay = 0;
    if (isLanded) {
      let height = 120 + nextMarker * 28 + (nextMarker - 1) * 34;
      styleDronePlay = height;
    }

    heightDrone.current = styleDronePlay;
    isDroneLeftPosition.current = markerLeft;

    return (
      <>
        <PreviewGallery
          mediaList={mediaList}
          mediaTime={mediaTime}
          mediaPreview={mediaPreview}
          isOpen={isEdit}
          handleClose={handleClose}
          currentMarker={currentMarkerPreview}
        />

        <div className="status-process" ref={playingWrapper}>
          {mapContentDisplay.map((item, index) => {
            if(item.isTakeoff) return renderTakeoffMarker(item);
            if(item.isLanding) return renderLandingMarker(item, utcTakeoff, isLanded, isLandedNotSuccessPlan);
            return renderMarkerItem(item, utcTakeoff, markerArv && nextMarker - 1 === index);
          })}

          <div className="drone-process" style={{ "--height": `${styleDronePlay}px` }} ref={droneRef}>
            {currentTime >= Number(delay_t) &&
              <div 
                className="drone" 
                style={{
                  "--width": markerLeft ? '16px': '34px',
                  transform: markerLeft ? 'translateY(-6px)' : 'translateY(0)',
                  display: !currentTime || isLanded ? "none" : undefined
                }}
              ></div>
            }
          </div>
        </div>
      </>
    );
  };

  const handleOpen = (item, isMarkerLeft) => {
    const { marker_id, utc, url, markerLeftImages } = item.content;
    setMediaPreview([]);
    setMediaList(isMarkerLeft ? markerLeftImages : url);
    setMediaTime(utc);
    setIsEdit(true);
    setCurrentMarkerPreview(marker_id);

    axios.all((isMarkerLeft ? markerLeftImages : url).trim().split(" ").map((item) => axios.get(Constants.MEDIA_URL + item + "/inspectionId/" + inspectionId, getHeader())))
      .then(
        axios.spread((...response) => {
          const newMediaPreview = response.map((res) => res.data);
          if(newMediaPreview[0]?.utc) {
            newMediaPreview.sort((a, b) => a.utc - b.utc)
          }

          setMediaPreview(newMediaPreview);
        })
      );
  };

  const handleClose = () => {
    setMediaList(null);
    setMediaTime(null);
    setMediaPreview([]);
    setIsEdit(false);
  };


  if (data != null) {
    return (
      <div className="flight-process" ref={flightProcessRef}>
        <div className="wrap-result-tracking ct-scroll" ref={processWrapRef}>
          <div className="result-tracking">
            {renderResultTracking(dataConvertForPreview)}
          </div>
        </div>
      </div>
    )
  }
});

export default PeviceTracking;