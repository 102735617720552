let lang_ja = {
  "invalid.param": "Invalid parameters",
  "another.error": "保存できません",
  "get.success": "Get data successfully",
  "post.success": "保存しました",
  "post.err": "未保存",
  "put.success": "保存しました",
  "put.err": "未保存",
  "delete.success": "Delete data successfully",
  "name.used": "Name is used",
  "name.empty": "Name is empty",
  "exist.drone.type": "Drone type exists",
  "500": "Internal server error",
  "400": "Bad request",
  "401": "Unauthorized",
  "404": "Request not found",
  "exist.plan.number": "フライトプラン番号が正しくありません",
  "data.empty": "Empty data",
  "language": "言語",
  "common": {
    "none": "none",
    "type": "メディアタイプ",
    "hold_t": "ホールド時間（秒）",
    "rotate": "回転（度）",
    "angle": "方向角度",
    "drone": "Drone",
    "robot": "ロボット",
    "yes": "有",
    "no": "なし",
    "left": "左",
    "right": "右",
    "turn": "回転",
    "up": "上昇",
    "down": "下降",
    "sensor": "センサー",
    "marker": "マーカー",
    "landing": "着陸",
    "landed": "Landed",
    "takeoff_btn": "Take Off",
    "takeoff": "Takeoff",
    "right_90": "右90度回転",
    "left_90": "左90度回転",
    "image": "Image",
    "video": "Video",
    "save": "保存",
    "back": "戻る",
    "ok": "はい",
    "cancel": "Cancel",
    "confirm_back": "Do you want to go back?",
    "map_mode": "マップモード",
    "developer_mode": "デベロッパーモード",
    "please_select": "選択してください",
    "departure": "出発",
    "arrival": "到着",
    "error": "エラー",
    "please_enter": "会社名",
    "item": "項目",
    "value": "バリュー",
    "message_set_value": "値を入力してください",
    "success": "成功した!",
    "message_input": "入力してください",
    "battery": "バッテリー",
    "not_found": "Not Found",
    "preparing": "充電していません",
    "charging": "Charging",
    "no-signal": "信号なし",
    "ready": "Ready",
    "on_the_way": "Flight",
    "during_the_move": "移動中",
    "NA": "-----",
    "invalid_email": "は有効なメールではありません",
    "Sun": "日",
    "Mon": "月",
    "Tue": "火",
    "Wed": "水",
    "Thu": "木",
    "Fri": "金",
    "Sat": "土",
    "*": "*",
    "add": "新規追加",
    "add_st": "追加",
    "import": "インポート",
    "msg1": "メッセージ 1",
    "msg2": "メッセージ 2",
    "year": "/",
    "month": "/",
    "date": "",
    "others": "その他",
    "or": "または",
    "validate_num": "番号を入力してください",
    "validate_ranger": "の数値",
    "des_refresh_time" : "「みつかりません」と表⽰するまでの時間",
    "unit": "分",
    "time": "時間",
    "today": "今日",
    "update": "更新",
    "sec": "秒",
    "des_clear_emergency_time": "緊急停止解除時間",
  },
  "login": {
    "welcome": "ようこそ",
    "in_notice": "システムにログインしてください",
    "button": "ログイン",
    "email_message": "メールアドレスを入力してください",
    "email_label": "メールアドレス",
    "email_placeholder": "電子メール",
    "validation_email": "は有効なメールではありません",
    "password_message": "パスワードを入力してください",
    "password_label": "パスワード",
    "password_placeholder": "パスワード",
    "error": "メールアドレスとパスワードを確認してください",
    "invalid_email": "有効なメールアドレスを入力してください",
    "forgot_pass": "パスワードを忘れた",
    "support": "サポート・",
    "terms_of_use": "利用規約・",
    "privacy": "プライバシーポリシー"
  },
  "user": {
    "title": "ユーザーリスト",
    "total": "件",
    "table": {
      "header_1": "ID",
      "header_2": "会社名",
      "header_3": "電子メールアドレス",
      "header_4": "パスワード",
      "header_5": "Kiriha",
      "header_6": "アクション"
    },
    "message": {
      "add_user": "ユーザーを追加しました",
      "validate": "正しく入力してください",
      "edit_info": "ユーザー情報を編集する",
      "confirm_delete_text": "このアカウントのすべてのコンポーネントを削除してもよろしいでしょうか？"
    },
    "3rd_api": "Corp-key",
    "first_corp_key": "corpKey1",
    "second_corp_key": "corpKey2",
    "3rd_change_api": "変更",
    "no_result": "No user",
    "wall_distance": "壁からの距離",
    "constant": "Constant",
    "user_edit": "User Editable",
    "wrong_wall_distance": "Wrong wall distance",
  },
  "schedule": {
    "title": "フライトスケジュール",
    "total": "件",
    "edit": "編集",
    "save": "保存",
    "label.date": "日付",
    "label.select": "選択してください",
    "label.title": "スケジュール名",
    "label.valid_flag": "有効にする",
    "label.drone_id": "対象ドローン",
    "label.plan": "フライトプラン",
    "label.marker": "マーカー",
    "label.flight_date_ime": "フライト日時",
    "label.repeat": "繰り返す",

    "select.none": "なし",
    "select.every_week": "毎週",
    "select.monthly": "毎月",
    "select.every_year": "毎年",
    "select.every_day": "毎日",

    "label.minute": "分",
    "label.hour": "時",
    "label.day": "日",
    "label.month": "月",
    "label.dow": "曜日",
    "label.action": "アクション",
    "label.select_plan": "Select plan",
    "label.select_drone_id": "Select Drone No",
    "label.no_schedule": "フライトスケジュールは登録されていません",

    "label.name": "スケジュール名",
    "label.flight_schedule": "飛行日時",
    "label.status": "ステータス",
    "label.drone": "ドローン",
    "label.flight_plan": "フライトプラン",
    "label.number_of_flights": "飛行回数",
    "label.last_flight_date_and_time": "最終飛行日時",
    "valid": "有効",
    "invalid": "無効",

    "pls_choose": "曜日を選んでください",
    "pls_choose_house_minute": "時間と分を入力してください",
  },
  "setting": {
    "menu": {
      "profile": "管理者情報",
      "icon": "アイコン",
      "parameter": "パラメータ",
      "droneType": "ドローンタイプ",
      "stationType": "ステーションタイプ",
      "privacySupports": "プラサポ利用規約編集",
    },
    "stationType": {
      "edit": "ステーションタイプを編集",
      "create": "ステーションタイプを作成 ",
    },
    "droneType": {
      "table": {
        "header_1": "No",
        "header_2": "名前",
        "header_3": "飛行時間",
        "header_4": "飛行距離",
        "header_5": "最高速度",
        "header_6": "画像",
        "header_7": "アクション"
      },
      "file_select": "ファイル選択",
      "edit": "ドローンのタイプを編集する",
      "create": "ドローンタイプを作成",
      "required": "ドローンのタイプ名は必須です",
    },
    "icon": {
      "default_icon": "ドローンアイコン",
      "upload_img": "画像をアップロード"
    },
    "chargingStation": {
      "edit": "ドッキングステーションを編集"
    },

    "update_success": "正常に更新されました",
    "update_fail": "アップデートに失敗しました",
    "do_you_want_delete_it": "削除しますか？",
    "it_has_been_deleted": "削除しました",
    "could_not_be_deleted": "削除できませんでした",
    "error": "エラー",
    "email_added": "メールが追加されました",
    "could_not_add_email": "メールを追加できませんでした",
    "mail_existed": "メールはすでに存在しています",
    "corporate_intelligence": "企業情報",
    "email_management": "メール管理",
    "please_add_an_email_address_to_send_an_alert_email": "アラートメールを送信するためのメールアドレスを追加してください",
    "can_not_get_ip_address": "Can not get IP address. Please check your network.",
    "charging_station": "ドッキングステーション",
    "please_add_a_charging_station": " ドッキングステーションを追加してください。",
    "charging_station_added": "ドッキングステーションが追加されました。",
    "charging_station_existed": "ドッキングステーションは登録されています",
    "could_not_add_charging_station": "ドッキングステーションを追加できませんでした。",
    "station_id": "ステーションID",
    "charging_station_deleted": "削除しました。"
  },
  "plan": {
    "plan_name": "フライトプラン名",
    "total": "件",
    "next_tab-2": "2 ルート確認設定へ",
    "next_tab-3": "3  ステータス・結果MAP設定へ",
    "btn_pre_tab": "戻る",
    "btn_next_tab": "次へ",
    "csv_upload_success": "アップロードしました",
    "csv_delete_success": "削除しました",
    "kiriha_label": "切羽モード",
    "dialog_kiriha": "マーカー情報が失うことがありますがよろしいですか",
    "capture_distance": "撮影距離(m)",
    "dialog_duplicate_plan": "フライトプランを複製します。よろしいですか？",
    "duplicate_text": "複製する",
    "back_to_status": "飛行ページに戻る",
    "tab_2": {
      "upload": {
        "text_btn": "アップロード",
        "title": "1. 図面画像アップロード",
        "desc_1": "図面画像をアップロードしてください。",
        "desc_2": "より正確な確認をするには上面図をアップロードします。",
        "label": "図面画像",
        "selected": "選択する",
      },
      "set_scale": {
        "text_btn": "スケール",
        "title": "2. 図面画像スケール",
        "desc_1": "アップロードした図面の縮尺を設定してください。",
        "desc_2": "図面をドラッグして、スケールを入力します。",
        "label": "スケール"
      },
      "set_first_marker": {
        "text_btn": "マーカー",
        "title": "3. マーカーを設置",
        "desc_1": "最初のマーカー位置をクリックして、",
        "desc_2": "スタート地点と進行方向を設定します。",
        "btn_clear": "リセット",
      },
      "set_show_route": {
        "text_btn": "ルート",
        "title": "4. ルート確認",
        "desc_1": "設定した飛行ルートをご確認ください。",
        "desc_2": "飛行ルートを変更する場合は",
        "desc_3": "にて編集します。",
        "btn_go_to_tab_update": "1 飛行ルート設定",
      },
      "kirihaMode": {
        "upload": {
          "title": "1. Upload the drawing image",
          "desc": "Upload a top view for a more accurate review.",
          "selected": "Select File",
        },
        "setting": {
          "title": "2. Set the origin and calibration point",
          "desc": "Place the origin and calibration point on the image and enter the xy-coordinates of the calibration point.",
        },
        "confirm": {
          "title": "3. Confirm the route",
        }
      }
    },
    "tab_3": {
      "upload": {
        "text_btn": "アップロード",
        "title": "1. 図面画像アップロード",
        "desc_1": "飛行結果の動画と連動して確認するための",
        "desc_2": "おおよそのプレビューマップ設定です。",
        "desc_3": "ルート確認設定と同じ図面画像の場合は設定が不要です。",
        "label": "図面画像"
      },
      "setting_map": {
        "text_btn": "マーカー",
        "title": "2. マーカーを設置",
        "desc_1": "マーカーの位置を設定します。",
        "desc_2": "クリックもしくはドラッグ&ドロップで配置します。",
      },
    },
    "app": {
      "date": "作成日時",
      "ver": "App ver"
    },
    "drone": {
      "flight_route": "飛行ルート",
      "frm_ver": "Firmware Ver.",
      "place": "飛行場所",
      "division": "部門",
      "title": "フライトプラン",
      "plan_no": "フライトプラン番号",
      "plan_name": "フライトプラン名",
      "created_time": "作成日時",
      "drone_type": "ドローンタイプ",
      "speed": "飛行速度（m/s）",
      "fTemp": "fTemp",
      "emergency_marker": "Emergency Marker",
      "number_of_markers": "マーカー数",
      "total_distance": "水平飛行距離",
      "table_1": "プラン名",
      "table_2": "プランNo.",
      "table_3": "ステータス",
      "table_4": "作成日時",
      "table_5": "ダウンロード",
      "table_6": "削除",
      "tab_1": "飛行ルート設定",
      "tab_2": "ルート確認設定",
      "tab_2_kiriha": "座標設定 / ルート確認",
      "tab_3": "ステータス・結果MAP設定",
      "added_flight_plan": "フライトプランを追加しました",
      "error_flight_plan": "フライトプランを更新できませんでした",
      "error_flight_plan_existed": "フライトプラン番号がすでに登録されています",
      "select_file_plan": "フライトプランファイルを指定してください",
      "no_data_flight_plan": "フライトプランがありません。",
      "message1": "フライトプラン名を入力してください",
      "travel_number": "移動プラン番号",
      "flight_number": "フライトナンバー",
    },
    "robot": {
      "title": "移動プランファイル",
      "plan_no": "",
      "drone_type": "",
      "table_1": "ID",
      "table_2": "移動プラン名",
      "table_3": "アクション",
      "table_4": "table_4",
      "table_5": "table_5",
      "table_6": "table_6",
      "added_move_plan": "移動プランを追加しました",
      "error_move_plan": "移動プランを追加できませんでした",
      "select_file_plan": "移動プランファイルを指定してください",
      "add_robot_plan": "ロボットの計画を立ててください",
      "message1": "移動プラン名を入力してください"

    },
    "marker_name": "マーカーリスト",
    "marker": {
      "add_route": "ルート追加",
      "drone": {
        "hover_t": "ホバリング時間（秒）",
        "place": "飛行場所",
        "height": "高さ（m）",
        "angle": "方向角度（度）",
        "wall_distance": "壁からの距離（m）",
        "landing_port": "ドッキングステーション",
        "direction": "飛行方向",
        "marker_distance": "次のマーカーまでの距離（m）",
        "marker_distance_mechanism": "マーカー認識方式",
        "marker_size": "マーカーサイズ（cm）",
        "mTemp": "mTemp",
        "mTemp2": "mTemp2",
      },
      "robot": {}
    },
    "error": {
      "empty_plan_no": "Empty plan no",
      "invalid_plan_no": "フライトプラン番号は101〜299で設定してください",
      "empty_plan_name": "フライトプラン名を入力してください",
      "invalid_drone_type": "Invalid drone type",
      "invalid_marker_id": "マーカーIDは1〜100で設定してください",
      "invalid_same_marker_id": "マーカーIDは前後で同一でなければなりません",
      "invalid_speed": "飛行速度が正しくありません。",
      "invalid_marker_distance": "マーカー{{id}}の[次のマーカーまでの距離]が正しくありません",
      "invalid_wall_distance": "壁からの距離が正しくありません。",
      "invalid_marker_height": "高さが正しくありません。",
      "invalid_direction": "飛行方向が正しくありません",
      "invalid_first_marker": "最初のマーカーの方向は左または右でなければなりません",
      "invalid_csv": "フライトプランを保存後にアップロードしてください。",
      "invalid_station": "ドッキングステーションが正しくありません",
    }
  },
  "home": {
    "title": "ダッシュボード",
    "preview_schedule": "次のフライトスケジュール",
    "preview_result": "最新の飛行結果",
    "drone_title": "ドローン",
  },
  "status": {
    "title-status": "ドローン",
    "switch_view": "一覧表示",
    "robot": {
      "status_text": "ロボットステータス",
      "message_1": "この機能を使用するには、ロボットを追加してください"
    },
    "drone": {
      "status_text": "ドローン",
      "message_1": "この機能を使用するには、ドローンを追加してください"
    },
    "show_history": "アラート履歴",
    "alert": "アラート",
    "clear": "Clear",
    "take_off_now": {
      "success": "離陸命令を送信しました",
      "error": "離陸命令の送信に失敗しました",
      "check_plan_takeoff": "ドッキングステーションが異なるため離陸できません",
      "pls_select_plan": "フライトプランを選択してください"
    },
    "small_marker": "small marker",
    "preview_schedule_title": "フライトスケジュール",
    "preview_result_title": "飛行結果",
    "preview_result_alert": "アラート履歴",
    "preview_link": "一覧を見る",
    "preview_link_add": "新規作成",
    "default_select": "フライトプラン",
    "emergency_stop": {
      "label": "緊急停止しますか",
      "label_clear": "緊急停止をクリアしますか",
      "stop_text": "緊急停止",
      "clear_text": "クリア",
    },
  },
  "result": {
    "total": "件",
    "alert": "アラート情報",
    "display_message": "ステータス表示",
    "small_marker": "small marker",
    "dowload": "ダウンロード",
    "compare": "比較する",
    "flight_result": '飛行結果',
    "text_zoom": "高解像度画像を読み込む",
    "label_select": "飛行日時",
    "no_data_result": "検索結果がありません。",

    "table.drone": "ドローン",
    "table.flight_plan": "プラン名",
    "table.ID": "プランNo.",
    "table.number_of_flights": "飛行回数",
    "table.last_flight_date_time": "最終飛行日時",
    "btn_sensor": "センサー測定値",
    "selected":"個選択"
  },

  "avatar": {
    "notifi_success": "更新済み",
    "notifi_error": "設定できません",
    "place_holder": "フライトプラン名",
    "setting_avatar": "企業情報",
    "setting_mail": "メール管理",
    "upload_image": "画像をアップロード",
    "button_update": "プロフィール写真を変更",
    "main_title": "設定",
    "notifi_error2": "更新エラーできません",
    "notifi_error3": "ユーザーの更新が失敗する",
    "detail_setting_mode": "詳細設定モード",
    "user_notification_url": "User Notification API URL"
  },

  "fgpassword": {
    "place_holder": "メール",
    "loading_text": "参加する",
    "link_login": "サインイン"
  },
  "planMap": {
    "update_plan": "移動プランを更新しました",
    "updated_flight_plan": "フライトプランを更新しました",
    "could_not_add_move_plan": "移動プランを追加できませんでした",
    "the_flight_plan": "フライトプランを更新できませんでした",
    "map": "地図",
    "travel_plan_name": "移動プラン名",
    "flight_plan_name": "フライトプラン名",
    "Please_enter_the_move_plane_name": "移動プラン名を入力してください",
    "Please_enter_the_flight_plane_name": "フライトプラン名を入力してください",
    "update": "更新",
    "edit_marker": "Edit marker",

    "info": {
      "not_set_image": "地図をアップロードしてください",
      "not_set_scale": "スケールを設定してください",
      "set_first_marker_empty_plan": "プランがない場合、最初のマーカーを設定できません。",
      "not_set_direction": "第１マーカーの方向をセットしてください"
    }

  },
  "planView": {
    "travel_plan_number": "移動プラン番号",
    "robot_type": "ロボットタイプ",
    "moving_speed": "移動速度",
    "moving_distance": "移動距離",
    "marker_direction": "マーカー方向",
    "direction_of_movement": "移動方向",
    "moving_angle": "移動角度"
  },
  "menu-admin": {
    "manager": "管理者",
    "setting": "設定",
    "logout": "ログアウト",
    "do_you_want_to_logout": "ログアウトしますか？"
  },
  "menu": {
    "dashboard": "ダッシュボード",
    "drone_status": "ドローン",
    "check_the_result": "飛行結果",
    "flight_file": "フライトプラン",
    "robot_status": "ロボットステータス",
    "report": "レポート",
    "schedule": "フライトスケジュール"
  },
  "mobileMenu": {
    "status": "ステータス",
    "scanning": "スキャニング",
    "textHome": "ホーム",
    "textSetting": "設定",
    "textLogout": "ログアウト",
  },
  "scan": {
    "flight_plan_id": "フライトプランID",
    "enter_flight_plan_id": "フライトプランID を入力",
    "is_not_set": "は設定されていません",
    "please_enter_the_move_plan_number": "移動プラン番号を入力してください",
    "please_enter_your_flight_plan_number": "フライトプラン番号を入力してください",
    "the_marker_does_not_load": "マーカーが読み込みません。再度スキャンしてください。",
    "scaning_multiple_marker": "マーカーをスキャンできませんでした。",
    "at_the_time_of_creation": "作成⽇時",
    "speed": "⾶⾏速度",
    "water_travel_distance": "⽔平⾶⾏距離",
    "marker_ID": "マーカーID",
    "distance_to_the_next_marker": "次のマーカーまで距離",
    "capture_the_image": "画像をキャプチャする",
    "please_enter": "入力してください",
    "outward": "Outward",
    "return": "Return",
    "btn-plan": "マーカーを読み取る",
    "title-scan": "スキャン",
    "validate_input_required": "プラン番号を入力してください",
    "validate_input_format": "フライトプラン番号が正しくありません",
  },
  "dialog_delete": {
    "message_text": "削除しますか？",
    "option_yes": "OK",
    "option_no": "キャンセル",
    "confirm_success": "削除しました",
    "confirm_error": "削除できませんでした",
    "confirm_success2": "ユーザーを削除しました",
    "confirm_error2": "ユーザーの削除に失敗",
    "deleted_drone": "削除しました",
    "deleted_robot": "ロボットが削除されました",
    "error_delete_robot": "ロボットを削除できません",
    "error_delete_drone": "ドローンを削除することはできません"
  },

  "map": {
    "upload_image": "地図画像をアップロードしてください",
    "menu_text": "レポート",
    "menu_action": "結果確認",
    "mode_map": "マップモード",
    "red_text": "結果がありません",
    "robot_list": "ロボットリスト",
    "drone_list": "ドローンリスト",
    "flight_plan_number": "フライトプラン番号",
    "flight_number": "フライトナンバー",
    "flight_name": "フライト名",
    "flight_time": "飛行日時"
  },
  "video": {
    "video_text": "ビデオ",
    "button_zoom": "ズーム",
    "battery_info": "バッテリー",
  },

  "mobile_app": {
    "drone": {
      "select_drone": "ドローンを選択"
    },
    "robot": {
      "select_robot": "ロボットを選択"
    },
  },

  "download": {
    "download_failed": "ダウンロードに失敗しました"
  },
  "mode": {
    "normal_mode": "Normal",
    "map_mode": "Map",
    "dual_mode": "⽐較"
  },
  "drone_selected": {
    "not_selected": "ドローンを選択してください",
  },
  "drone_info": {
    "drone_id": "ドローンID",
    "flight_plan_no": "フライトプランナンバー",
    "flight_name": "フライト名",
    "battery": "バッテリー"
  },
  "reset_password": {
    "message_send_email": "再設定用のメールを送信しました。メールに記載されているURLにアクセスし、再設定をおこなってください。",
    "message_error_password": "パスワードが一致しません.",
    "message_success_password": "パスワードを正常に変更.",
    "header_modal_send_mail": "パスワードをお忘れですか",
    "email_address": "電子メールアドレス",
    "send_request": "リクエストを送信",
    "btn_reset_password": "パスワードを再設定する",
    "back_login": "戻る ログイン",
    "confirm_password": "パスワードを認証する",
    "password": "パスワード",
    "validate_send_email": "完全な情報を入力してください。",
    "error_invalid_email": "有効なメールアドレスを入力してください。",
    "validate_send_password": "完全な情報を入力してください。",
    "error_invalid_password": "パスワードは 3 文字以上にする必要があります。",
    "error_response_data": "ユーザーは存在しません！"
  }, 
  "privacy_supports" : {
    "confirm_alert": "変更を保存しますか？",
    "support": "サポート",
    "terms_of_service": "利用規約",
    "privacy_policy": "プライバシーポリシー",
    "last_update": "更新"
  },
  "Search_Pagination": {
    "search": "検索",
    "pageNum": "１ページあたりの表示数",
    "checkDate": '終了日は開始日より大きくなければなりません。',
    "device_no": "ドローン",
    "flight_plan": "プラン名",
    "plan_no": "プランNo.",
    "creation_date": "作成日時",
    "last_flight_date": "最終飛行日時",
    "flight_date": "飛行日時",
    "plan_name": "プラン名",
    "schedule_name": "スケジュール名",
    "format-date": "年/月/日",
  },
  "kiriha": {
    "label": "切羽 観察中",
  }
};
export default lang_ja;