import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import ZoomFitComponent from "./ZoomFitComponent";
import { KeepScale } from 'react-zoom-pan-pinch';

import { STATION_TYPE_URL, getResourceUrl } from "../../../utils/constants";
import { IconDefaultChargingStation, IconDroneFlyingPreview, IconMarkerPreview, IconObservingKiriha } from "../../images";
import { calculateAngle, generatePointMark, generateRatio } from "../../../utils/kiriha";
import axios from "axios";
import { getHeader } from "../../../services/api.service";

export const POINT_CATEGORY = {
  ONWARD: "onward",
  RETURN: "return",
  KIRIHA: "kiriha"
}

const imgStyles = {
  objectFit: "contain",
}

const viewPointCategoryDefault = {
  [POINT_CATEGORY.ONWARD]: true,
  [POINT_CATEGORY.RETURN]: true,
  [POINT_CATEGORY.KIRIHA]: true,
}

const MapPreviewStatusKirihaMode = (props) => {
  const {
    inspection: {
      mapImage,
      kirihaData,
    },
    deviceStatus,
    sizeMain,
    elementMainView,
    isFullScreenMode,
  } = props;

  const [scale, setScale] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [viewPointCategory, setViewPointCategory] = useState(viewPointCategoryDefault);

  const [stationTypesDetail, setStationTypesDetail] = useState(null);
  
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [imgWidth, setImgWidth] = useState(null);
  const [imgHeight, setImgHeight] = useState(null);

  const containerRef = useRef(null);
  const wrappreRef = useRef(null);
  const imgRef = useRef(null);

  const isKirihaStatus = useMemo(() => {
    let kirihaStatus = false;
    for (let i = 0; i < deviceStatus.length; i++) {
      const element = deviceStatus[i];
      if(!kirihaStatus && element.status === "kiriha_st") kirihaStatus = true;
      if(kirihaStatus && element.status === "kiriha_end") {
        kirihaStatus = false;
        break;
      }
    }

    return kirihaStatus;
  }, [deviceStatus])

  const pointCategoryIndex = useMemo(() => {
    if (deviceStatus.length) {
      const firstKirihaStIndex = deviceStatus.findIndex(item => item.status === "kiriha_st");
      const firstKirihaEndIndex = deviceStatus.findIndex(item => item.status === "kiriha_end");
  
      let onwardSt = 0, onwardEnd, 
          returnSt, returnEnd, 
          kirihaSt, kirihaEnd;
  
      if (firstKirihaStIndex > 0 && firstKirihaEndIndex > 0) {
        onwardEnd = firstKirihaStIndex - 1;
        kirihaSt = firstKirihaStIndex;
        kirihaEnd = firstKirihaEndIndex;
        returnSt = firstKirihaEndIndex + 1;
        returnEnd = deviceStatus.length - 1;
      } else if (firstKirihaStIndex > 0 && firstKirihaEndIndex === -1) {
        onwardEnd = firstKirihaStIndex - 1;
        kirihaSt = firstKirihaStIndex;
        kirihaEnd = deviceStatus.length - 1;
      } else if (firstKirihaStIndex === -1) {
        onwardEnd = deviceStatus.length - 1;
      }
  
      return {
        [POINT_CATEGORY.ONWARD]: [onwardSt, onwardEnd],
        [POINT_CATEGORY.RETURN]: [returnSt, returnEnd],
        [POINT_CATEGORY.KIRIHA]: [kirihaSt, kirihaEnd]
      }
    }
  }, [deviceStatus])

  const deviceStatusHandle = useMemo(() => {
    if (deviceStatus.length) {
      const newDeviceStatusHandle = {
        [POINT_CATEGORY.ONWARD]: [],
        [POINT_CATEGORY.RETURN]: [],
        [POINT_CATEGORY.KIRIHA]: [],
      }
  
      deviceStatus.forEach((item, index) => {
        const category = getPointCategory(index);
        if (category && item.type !== "media") {
          newDeviceStatusHandle[category].push(item);
        }
      })
  
      return newDeviceStatusHandle;
    }
  }, [pointCategoryIndex, deviceStatus])

  // 
  const angle = useMemo(() => {
    if (kirihaData) {
      const { originPosition, calibration, axis } = kirihaData;
      return calculateAngle(
        { x: originPosition.left, y: originPosition.top },
        { x: calibration.left, y: calibration.top },
        axis, zoom
      )
    }
  }, [kirihaData, zoom])
  
  const pointMark = useMemo(() => {
    if (kirihaData) {
      const { originPosition } = kirihaData;
  
      return generatePointMark(
        { x: originPosition.left + 1, y: originPosition.top },
        { x: originPosition.left, y: originPosition.top },
        angle, zoom
      )
    }
  }, [kirihaData, zoom])

  const ratioAxis = useMemo(() => {
    if (kirihaData) {
      const { originPosition, calibration, axis } = kirihaData;
  
      return generateRatio(
        { x: originPosition.left, y: originPosition.top },
        pointMark,
        { x: calibration.left, y: calibration.top },
        axis, angle, zoom
      );
    }
  }, [kirihaData, pointMark, angle, zoom])

  useEffect(() => {
    if (kirihaData) {
      const { stationPosition } = kirihaData;
      getStationTypeDetal(stationPosition);
    }
  }, []);

  const getStationTypeDetal = async (stationPs) => {
    const stationTypeIds = [...new Set(stationPs.map(item => item.stationTypeId))];

    if (stationTypeIds && stationTypeIds.length) {
      try {
        const response = await Promise.allSettled(
          stationTypeIds.map(item => axios.get(STATION_TYPE_URL + "/" + item, getHeader()).then(res => res.data))
        )
  
        if (response && response.length) {
          const stationTypeList = [];
  
          response.forEach(item => {
            if (item.status === "fulfilled" && item.value) {
              stationTypeList.push({
                id: item.value.id,
                path: item.value.imagePath
              })
            }
          })
  
          setStationTypesDetail(stationTypeList);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setStationTypesDetail(null);
    }
  }

  const imageOnLoad = () => {
    if (imgRef.current) {
      const zoomX = containerRef.current.clientWidth / imgRef.current.clientWidth;
      const zoomY = containerRef.current.clientHeight / imgRef.current.clientHeight;
  
      let newZoom = Math.min(zoomX, zoomY);
      imgStyles.width = imgRef.current.clientWidth * newZoom;
      imgStyles.height = imgRef.current.clientHeight * newZoom;
      
      setZoom(Math.min(imgStyles.width / imgRef.current.naturalWidth, imgStyles.height / imgRef.current.naturalHeight));
      setContainerWidth(containerRef.current.clientWidth);
      setContainerHeight(containerRef.current.clientHeight);
      setImgWidth(imgStyles.width);
      setImgHeight(imgStyles.height);
    }
  }

  const renderMarker = (data) => {
    const { originPosition, markerPosition } = data;

    if (markerPosition?.length) {
      return markerPosition.map(marker => {
        const point = rotatePoint(marker);

        return (
          <div 
            className="marker-preview" 
            style={{
              transform: `translate(
                ${originPosition.left * zoom + point.x}px, 
                ${originPosition.top * zoom + point.y}px
              )`
            }}
          >
            <div className="marker-icon">
              <KeepScale>
                <IconMarkerPreview />
              </KeepScale>
            </div>
          </div>
        )
      })
    }
  }

  const renderStation = (data) => {
    const { originPosition, stationPosition } = data;

    if (stationPosition?.length) {
      return stationPosition.map(station => {
        if (station.x !== null && station.y !== null) {
          const point = rotatePoint({ x: station.x, y: station.y });
          const url = stationTypesDetail?.find(item => item.id === station.stationTypeId)?.path;

          return (
            <div 
              className="station-preview" 
              style={{
                transform: `translate(
                  ${originPosition.left * zoom + point.x}px, 
                  ${originPosition.top * zoom + point.y}px
                )`
              }}
            >
              <KeepScale>
                <div className="station-icon" style={url ? undefined : { background: "transparent", boxShadow: "unset" }}>
                  {url ?
                    <img 
                      alt="station"
                      src={getResourceUrl() + url}
                    />
                    :
                    <IconDefaultChargingStation />
                  }
                </div>
              </KeepScale>
            </div>
          )
        }
      })
    }
  }

  const renderPoint = (data) => {
    return Object.keys(data).map(keyPoint => {
      if (viewPointCategory[keyPoint]) {
        let isPsOnMarker = false;
        return data[keyPoint].map((item, index, arr) => {
          const { originPosition } = kirihaData;
          const { content: { dronePosition }, status } = item;
          const pointPs = rotatePoint({ x: dronePosition.x, y: dronePosition.y });

          if (keyPoint !== POINT_CATEGORY.KIRIHA) {
            if (status === "marker_arv") isPsOnMarker = true;
            if (index > 1 && index < arr.length - 1 && arr[index - 1].status === "marker_left") isPsOnMarker = false;
          }
          
          return (
            <div 
              className={
                "point-flight" + 
                (index === data.length - 1 ? " delay-view" : "") + 
                ` ${keyPoint}` + 
                (isPsOnMarker ? " on-marker" : "")
              }
              style={{
                transform: `translate(
                  calc(${originPosition.left * zoom + pointPs.x}px - 50%),
                  calc(${originPosition.top * zoom + pointPs.y}px - 50%)
                )`
              }}
            >
              <KeepScale>
                <span className="point-icon"></span>
              </KeepScale>
            </div>
          )
        })
      }
    })
  }

  const renderDrone = (data) => {
    const { originPosition } = kirihaData;
    const currentPoint = data[data.length - 1];
    const { content: { dronePosition } } = currentPoint;
    const dronePs = rotatePoint({ x: dronePosition.x, y: dronePosition.y });

    return (
      <div 
        className="drone-flight"
        style={{
          transform: `translate(
            calc(${originPosition.left * zoom + dronePs.x}px - 50%), 
            calc(${originPosition.top * zoom + dronePs.y}px - 50%)
          )`
        }}
      >
        <KeepScale>
          <div className="drone-icon">
            <span className="ripple pinkBg"></span>
            <span className="ripple pinkBg"></span>
            <IconDroneFlyingPreview />
          </div>
        </KeepScale>
      </div>
    )
  }

  const renderLine = (data) => {
    const { originPosition } = kirihaData;
    const lineObj = {};

    Object.entries(data).forEach(([keyPoint, items]) => {
      lineObj[keyPoint] = items.map(item => {
        const { dronePosition } = item.content;
        return rotatePoint({ x: dronePosition.x, y: dronePosition.y });
      });
    });
    
    Object.entries(lineObj).forEach(([keyPoint, lines]) => {
      const prevKey =
        keyPoint === POINT_CATEGORY.KIRIHA ? POINT_CATEGORY.ONWARD :
        keyPoint === POINT_CATEGORY.RETURN ? POINT_CATEGORY.KIRIHA :
        null;
    
      if (prevKey && lineObj[prevKey]) {
        lines.unshift(lineObj[prevKey][lineObj[prevKey].length - 1]);
      }
    });

    return Object.keys(lineObj).map(keyPoint => {
      if (viewPointCategory[keyPoint]) {
        return lineObj[keyPoint].map((item, index, arr) => {
          if(index >= arr.length - 1 || !item) return null;
          return (
            <svg 
              key={index} 
              className={"w-100 h-100 status-line" + (index === arr.length - 2 ? " delay-view" : "") + ` ${keyPoint}`}
            >
              <line 
                x1={originPosition.left * zoom + item.x} y1={originPosition.top * zoom + item.y} 
                x2={originPosition.left * zoom + arr[index + 1].x} y2={originPosition.top * zoom + arr[index + 1].y} 
                style={{
                  strokeWidth: `${1.5 / scale}`,
                }}
              />
            </svg>
          )
        })
      }
    })
  }

  const rotatePoint = (currentPoint) => {
    const alpha = (angle * Math.PI) / 180;
    const cosAlpha = Math.cos(alpha);
    const sinAlpha = Math.sin(alpha);
    
    const deltaX = Number(currentPoint.x) * ratioAxis;
    const deltaY = Number(currentPoint.y) * ratioAxis * -1;
    
    const xPrime = deltaX * cosAlpha - deltaY * sinAlpha;
    const yPrime = deltaX * sinAlpha + deltaY * cosAlpha;

    return({ x: xPrime, y: yPrime });
  }

  function getPointCategory (index) {
    let category;
    Object.keys(pointCategoryIndex).forEach(item => {
      const start = pointCategoryIndex[item][0];
      const end = pointCategoryIndex[item][1];
      if (start !== undefined && end !== undefined && index >= start && index <= end) category = item;
    })

    return category;
  }

  if (mapImage) {
    return (
      <div className="map-preview-kiriha-mode" ref={containerRef}>
        <ZoomFitComponent
          sizeMain={sizeMain}
          elementMainView={elementMainView}
          isFullScreenMode={isFullScreenMode}
          viewPointCategory={viewPointCategory}
          setViewPointCategory={setViewPointCategory}
          zoom={zoom}
          scale={scale}
          updateScale={setScale}
          handleResize={imageOnLoad}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
        >
          <div className="status-map-view" ref={wrappreRef}>
            <img
              src={getResourceUrl() + mapImage}
              onLoad={imageOnLoad}
              ref={imgRef}
              style={{ ...imgStyles }}
            />
            {kirihaData && 
              <>
                {renderMarker(kirihaData)}
                {renderStation(kirihaData)}
              </>
            }
            {kirihaData && deviceStatusHandle && (
              <>
                {renderPoint(deviceStatusHandle)}
                {renderDrone(deviceStatus)}
                {renderLine(deviceStatusHandle)}
              </>
            )}
          </div>
        </ZoomFitComponent>
            
        {isKirihaStatus &&
          <label className="kiriha-label kiriha-blink">
            <IconObservingKiriha />
          </label>
        }
      </div>
    )
  }

  return (
    <p style={{ width: "100%", textAlign: "center", padding: "150px 0" }}>
      Not Setting
    </p>
  )
}

export default memo(MapPreviewStatusKirihaMode);