import React, {useRef, useContext, useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import {useDrag} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { KeepScale } from "react-zoom-pan-pinch";
import { MapContext } from "../../plan/Tab/planMapKirihaMode";
import { IconPoint } from "../../images";

export const MarkerDraggableKirihaMode = ({ 
  onUpdateDraggingStatus, 
  onUpdateDisplayTypeDrag,
  onUpdatePsDrag,
  displayType, 
  positionFormSettingCalibration,
  updatePositionFormSettingCalibration,
  onHiddenFormSettingCalibration
}) => {
  const {
    originPosition,
    calibration,
    axis,
    onChangeAxis,
    zoom,
    isReadOnly
  } = useContext(MapContext);

  const [dataAxis, setDataAxis] = useState(axis);

  const [{ isDragging, opacity, itemCurrent }, drag, dragPreview] = useDrag({
    item: { type: 'marker', displayType },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0 : 1,
      itemCurrent: monitor.getItem()
    }),
    begin: (monitor) => {
      const clientOffset = monitor.getClientOffset();
      const point = displayType === "origin" ? document.getElementById("origin-point") : document.getElementById("calibration-point");
      if (clientOffset && point) {
        const rect = point.getBoundingClientRect();
        const x = clientOffset.x - rect.left;
        const y = clientOffset.y - rect.top;
        onUpdatePsDrag({ x, y });
      }
    }
  });

  const modalFormSettingCalibration = useRef(document.createElement('div'));

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
    document.body.appendChild(modalFormSettingCalibration.current);

    return () => {
      document.body.removeChild(modalFormSettingCalibration.current);
    };
  }, []);

  useEffect(() => {
    onUpdateDraggingStatus(isDragging);
  }, [isDragging])
  
  useEffect(() => {
    if (isDragging) {
      onUpdateDisplayTypeDrag(itemCurrent.displayType)
    }
  }, [itemCurrent, isDragging])

  // Origin
  if (displayType === "origin") {
    const xPs = originPosition.left ? originPosition.left * zoom : 30;
    const yPs = originPosition.top ? originPosition.top * zoom : 30;

    return (
      <div
        ref={isReadOnly ? null : drag}
        id='origin-point'
        className='origin-point'
        style={{
          opacity,
          transform: `translate(${xPs}px, ${yPs}px)`,
          cursor: isReadOnly ? "default" : undefined
        }}
      >
        <div>
          <KeepScale>
            <IconPoint /> 
          </KeepScale>
        </div>
      </div>
    )
  }

  // Calibration

  const renderFormSettingCalibration = () => {
    if (!positionFormSettingCalibration) return null;

    return ReactDOM.createPortal(
      <div 
        className='form-setting-calibration'
        style={{
          top: positionFormSettingCalibration.top - 180,
          left: positionFormSettingCalibration.left > window.innerWidth - 155 ? "unset"  : positionFormSettingCalibration.left + 35,
          right: positionFormSettingCalibration.left > window.innerWidth - 155 ? 10  : "unset",
        }}
      >
        <div className='form-head'>
          <label>Calibration</label>
          <button className='btn-close' onClick={onHiddenFormSettingCalibration}></button>
        </div>
        <div className='row-control'>
          <label>x[m]</label>
          <input type='number' name='x' min={0} value={dataAxis.x} onChange={handleChangeInput} />
        </div>
        <div className='row-control'>
          <label>y[m]</label>
          <input type='number' name='y' min={0} value={dataAxis.y} onChange={handleChangeInput} />
        </div>
        <button className='btn-sub' onClick={handleSavaDataAxis}>OK</button>
      </div>
      , modalFormSettingCalibration.current
    );
  }

  const handleChangeInput = (event) => {
    const { value, name } = event.target;
    setDataAxis({ ...dataAxis, [name]: value });
  }

  const handleSavaDataAxis = () => {
    onChangeAxis(dataAxis);
    onHiddenFormSettingCalibration();
  }

  const handleShowFormSt = (e) => {
    if (!isReadOnly) {
      const { x, y } = e.target.getBoundingClientRect();
  
      updatePositionFormSettingCalibration({ top: y + 15, left: x + 15 });
    }
  }

  if (displayType === "calibration") {
    const xPs = calibration.left ? calibration.left * zoom : 65;
    const yPs = calibration.top ? calibration.top * zoom : 30;

    return (
      <>
        <div
          ref={isReadOnly ? null : drag}
          id='calibration-point'
          className='calibration-point'
          style={{
            opacity,
            transform: `translate(${xPs}px, ${yPs}px)`,
            zIndex: 1,
            cursor: isReadOnly ? "default" : null
          }}
          onClick={handleShowFormSt}
        >
          <div>
            <KeepScale style={{ pointerEvents: 'none' }}>
              <IconPoint color="#49A7DD" />
            </KeepScale>
          </div>
        </div>
  
        {renderFormSettingCalibration()}
      </>
    )
  }

  return null;
};
