import React from "react";
import PropTypes from "prop-types";
import * as Images from "./images";
import {confirmAlert} from 'react-confirm-alert'; // Import
import {createBrowserHistory} from "history";
import {withTranslation} from "react-i18next";

const history = createBrowserHistory({forceRefresh: true});

class MobileMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      menuDrones: {
        body: [
          {
            icon: "IconDashboard",
            title: "menu.dashboard",
            url: "/home",
          },
          {
            icon: "IconPlane",
            title: "menu.drone_status",
            url: "/status",
          },
          {
            icon: "IconChart",
            title: "menu.check_the_result",
            url: "/inspection-result",
          },
          {
            icon: "IconScan",
            title: "menu.flight_file",
            url: "/plan",
          },
          {
            icon: "IconScan",
            title: "mobileMenu.scanning",
            url: "/scan",
          }
        ],
        footer: [
          {
            icon: "IconSetting",
            title: "menu-admin.setting",
            url: "/setting",
          },
          {
            icon: "",
            title: "menu-admin.logout",
            url: "/logout",
          },
        ],
      },
      menuRobots: {
        body: [
          {
            icon: "IconRobot",
            title: "mobileMenu.status",
            url: "/status",
          },
          {
            icon: "IconChart",
            title: "menu.check_the_result",
            url: "/inspection-result",
          },
          {
            icon: "IconScan",
            title: "mobileMenu.scanning",
            url: "/scan",
          },
        ],
        footer: [
          {
            icon: "IconLogout",
            title: "menu-admin.logout",
            url: "/logout",
          }
        ],
      },
    }
    this.handleLogout.bind(this);
  };

  handleLogout(e, t) {
    e.preventDefault();
    confirmAlert({
      title: t('menu-admin.do_you_want_to_logout'),
      message: '',
      buttons: [
        {
          label: t('common.ok'),
          onClick: () => {
            history.push('/logout');
          }
        },
        {
          label: t('common.back')
        }
      ]
    });
  }

  renderItemMenu(data, t) {
    return data.map((item, index) => {
      if (item.url === "/logout") {
        return (
          <div key={index}>
            <a
              href="#"
              onClick={(event) => this.handleLogout(event, t)}
              className={
                this.props.currentRouter.includes(item.url)
                  ? "active menu-item"
                  : "menu-item"
              }
            >
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter === item.url
                  ? "choose-btn"
                  : "display-none"
              }
            >
            </div>
          </div>
        );
      } else if (item.url === "/setting") {
        return (
          <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="menu-item" onClick={this.props.onShowFormSetting}>
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
            <a
              href={item.url}
              className={
                this.props.currentRouter.includes(item.url)
                  ? "active menu-item"
                  : "menu-item"
              }
              
            >
              <Images.Icons name={item.icon} color="currentColor" />
              <span className="icon-text">{t(item.title)}</span>
            </a>
            <div
              className={
                this.props.currentRouter === item.url
                  ? "choose-btn"
                  : "display-none"
              }
            >
            </div>
          </div>
        );
      }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <section className="tab-menu f-column">
        <div className="mheader">
          {this.state.user?.accountType === "robot"
            ? this.renderItemMenu(this.state.menuRobots.body, t)
            : this.renderItemMenu(this.state.menuDrones.body, t)}
        </div>

        <div className="mfooter">
          {this.state.user?.accountType === "robot"
            ? this.renderItemMenu(this.state.menuRobots.footer, t)
            : this.renderItemMenu(this.state.menuDrones.footer, t)}
        </div>
      </section>
    );
  }

}

export default withTranslation()(MobileMenu);
MobileMenu.defaultProps = {
  currentRouter: "",
};

MobileMenu.propTypes = {
  currentRouter: PropTypes.string,
};

