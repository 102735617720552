import { memo, useContext, useEffect, useMemo, useState } from "react";
import { KeepScale } from "react-zoom-pan-pinch";
import { MapContext } from "../Tab/planMapKirihaMode";
import { IconDroneFlyingPreview } from "../../images";
import { QUARANT_POSITION } from "../../../utils/constants";

const FlightRouteAnimate = () => {
  const {
    planRouterSetting,
    quarantPositon,
    originPosition,
    calibration,
    axis,
    zoom,
    markerDataCsv,
    setIsShowFlightRoute,
    angle, ratioAxis,
    scale
  } = useContext(MapContext);
  
  const [lines, setLines] = useState([]);
  const [animationName, setAnimationName] = useState('');
  
  const style = useMemo(() => {
    return {
      animationName: animationName,
      animationTimingFunction: 'ease-in-out',
      animationDuration: lines.length - 1 + 's',
      animationDelay: '0s',
      animationIterationCount: 1,
      animationDirection: 'normal',
      animationFillMode: 'forwards',
      position: 'absolute',
      display: 'none'
    }
  }, [animationName, lines])

  function timeOutAnimate (time) {
    setTimeout(() => {
      setIsShowFlightRoute(false);
    }, time);
  }
  
  useEffect(() => {
    return () => clearTimeout(timeOutAnimate);
  }, [])
  
  useEffect(() => {
    const { flight_marker } = planRouterSetting.flight;
    if (flight_marker.length >= 2) {
      const redLines = [];
      flight_marker.forEach((item) => {
        const marker = markerDataCsv.find(mk => Number(mk.id) === item.id && mk.x !== null);

        if (marker) {
          const xMarker = Number(marker.x);
          const yMarker = Number(marker.y);

          const newPoint = rotatePoint({ x: xMarker, y: yMarker }, Number(item.wall_distance));

          redLines.push(newPoint);
        }
      })

      setLines(redLines);
    }
  }, [planRouterSetting, originPosition, calibration, axis, zoom, markerDataCsv])

  useEffect(() => {
    moveDroneByPosition(lines)
    if (lines.length >= 2) {
      timeOutAnimate((lines.length - 1) * 1000);
    }
  }, [lines])



  const rotatePoint = (currentPoint, wallDistance) => {
    const alpha = (angle * Math.PI) / 180;
    const cosAlpha = Math.cos(alpha);
    const sinAlpha = Math.sin(alpha);

    const deltaX = currentPoint.x * ratioAxis;
    const deltaY = currentPoint.y * ratioAxis;

    const xPrime = deltaX * cosAlpha - deltaY * sinAlpha;
    const yPrime = deltaX * sinAlpha + deltaY * cosAlpha;

    const directionWallDistance = (quarantPositon === QUARANT_POSITION.bottomRight || quarantPositon === QUARANT_POSITION.topRight) ? 
      wallDistance * ratioAxis * -1 : 
      wallDistance * ratioAxis;

    return({ 
      x: xPrime + originPosition.left * zoom,
      y: yPrime + originPosition.top * zoom + directionWallDistance
    });
  }

  const moveDroneByPosition = (positions) => {
    let styleSheet = document.styleSheets[0];
    let aniName = `animation${Math.round(Math.random() * 100)}`;
    const moveStep = 100 / (positions.length - 1);
    let keyframeTransforms = '';
    positions.forEach((position, index) => {
      keyframeTransforms += `${moveStep
      * index}% {-webkit-transform:translate(calc(${position.x}px - 50%), calc(${position.y}px - 50%))} `
    })
    let keyframes = `@-webkit-keyframes ${aniName} {${keyframeTransforms}}`;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    styleSheet.insertRule('.drone-icon-in-map { display: block !important }',
        styleSheet.cssRules.length);
    setAnimationName(aniName)
  };

  const renderLine = () => {
    return(
      lines.map((item, index, arr) => {
        if(index >= arr.length - 1) return null 
        return(
          <svg key={index} className="w-100 h-100 position-absolute" style={{ zIndex: 1000 }}>
            <line 
              x1={item.x} y1={item.y} 
              x2={arr[index + 1].x} y2={arr[index + 1].y} 
              style={{
                stroke: '#FF9E66',
                strokeWidth: `${1.5 / scale}`,
              }}
            />
          </svg>
        )
      })
    )
  }

  return (
    <div className="flight-route-animate">
        {renderLine()}
        {lines.length ?
          <div className="drone-icon-in-map" style={style}>
            <KeepScale>
              <IconDroneFlyingPreview />
            </KeepScale>
          </div>
          : null
        }
    </div>
  )
}

export default memo(FlightRouteAnimate);