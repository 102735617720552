import { memo } from "react";

const RadioComponent = (props) => {
  const { title, subTitle, name, value, onChange, defaultChecked, checked, className } = props;

  return (
    <div className={`dps-radio-custom ${className ? className : ""}`}>
      {title && <span>{title}</span>}
      <label className="form-radio-custom" onClick={(e) => e.stopPropagation()}>
        <input type="radio" name={name} value={value} onChange={onChange} checked={checked} defaultChecked={defaultChecked} />
        <span className="checkmark"></span>
      </label>
      {subTitle && <span>{subTitle}</span>}
    </div>
  )
}

export default memo(RadioComponent);