import React, { useState, useEffect, useRef, memo, useLayoutEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SockJsClient from "react-stomp";
import { isMobile, isMobileOnly } from "react-device-detect";
import cloneDeep from "lodash/cloneDeep";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer, toast } from 'react-toastify';

import * as Images from "../images";
import * as Constants from "../../utils/constants";
import { doPost, doPut, getHeader } from "../../services/api.service";
import StatusPlayingTracking from "../statusPlayingTracking";
import { timeConverter, timeMinSecConverter, timeSmallConverter } from "../../utils/utils";
import MapPreviewStatus from "../map/mapPreviewStatus";
import MapPreviewStatusFC from "../map/mapPreviewStatusFC";
import DeviceSonsorPreview from "./deviceSonsorPreview";
import { PanelGroup, Panel } from "../resize-panels/resizePanel";
import ImgaesViewStatusComponent from "./imagesView";
import VideoStreamingComponent from "../videoStreaming";
import SelectCustomComponent from "../form-action-utils/SelectCustomComponent";
import MapPreviewStatusKirihaMode from "../map/KirihaMode/mapPreviewStatusKirihaMode";
import { calculateAngle, generatePointMark, generateRatio, getTotalDistance } from "../../utils/kiriha";

const initialOptionChart = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: tooltipItems => tooltipItems[0].label,
      },
    }
  },
  scales: {
    x: {
      type: 'linear',
      title: {
        display: true,
        text: "Time[s]",
        align: "end"
      },
      ticks: {
        stepSize: undefined,
        precision: 0,
        callback: value => value,
      },
      max: undefined,
      min: undefined
    },
    y: {
      type: 'linear',
      max: undefined,
      min: 0,
      ticks: {
        stepSize: undefined,
        precision: 0,
        callback: value => value,
      },
    }
  },
  elements: {
    line: {
      borderJoinStyle: 'round'
    }
  }
};

const initialChartSetting = {
  statusLegendList: [],
  dataChart: null,
  optionsChart: initialOptionChart,
  isNormalize: false,
  // Axis X
  stepSizeX: undefined,
  minX: null,
  maxX: null,
  isConvertHour: false,
  // Axis Y
  stepSizeY: undefined,
  minY: null,
  maxY: null,
}

const colorSensor = [
  '166, 206, 227', 
  '31, 120, 180', 
  '178, 223, 138', 
  '51, 160, 44', 
  '251, 154, 153', 
  '227, 26, 28', 
  '253, 191, 111', 
  '255, 127, 0', 
  '202, 178, 214', 
  '106, 61, 154'
];

const claerIsStopEmergencyTime = 30; // second

const DeveiceDetail = ({
  deviceList,
  deviceActive,
  droneId,
  stationId,
  deviceIcons,
}) => {
  const { t } = useTranslation();
  const corpId = JSON.parse(localStorage.getItem("user")).corpId;
  const corpKey = JSON.parse(localStorage.getItem("user")).corpKey;
  const accountType = JSON.parse(localStorage.getItem("user")).accountType;
  const KIRIHA_MODE = JSON.parse(localStorage.getItem("user")).kirihaMode;
  
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [droneID, setDroneID] = useState(droneId);
  const [planFlying, setPlanFlying] = useState(null);
  const [deviceDetail, setDeviceDetail] = useState(null);
  const [stationID, setStationID] = useState(stationId);
  const [stationDetail, setStationDetail] = useState(null);
  const [allAlert, setAllAlert] = useState({});
  const [isShowsAlert, setIsShowsAlert] = useState(false);
  const [isShowOptionsAlert, setIsShowOptionsAlert] = useState(false);
  const [stationIDFlightStart, setStationIDFlightStart] = useState(null);
  const [stationFlightStart, setStationFlightStart] = useState(null);
  const [isCheckStationFlightStartNotFound, setIsCheckStationFlightStartNotFound] = useState(false);
  const [stationIDFlightEnd, setStationIDFlightEnd] = useState(null);
  const [stationFlightEnd, setStationFlightEnd] = useState(null);
  const [isCheckStationFlightEndNotFound, setIsCheckStationFlightEndNotFound] = useState(false);
  const [previewSchedule, setPreviewSchedule] = useState([]);
  const [planSimpleList, setPlanSimpleList] = useState([]);
  const [refPlan, setRefPlan] = useState(null);
  const [isFullMapView, setIsFullMapView] = useState(false);
  const [isScaning, setIsScaning] = useState(false); // check flight with marker
  const [massageTakeoffNow, setMassageTakeoffNow] = useState(null);
  const [isCheckStationNotFound, setIsCheckStationNotFound] = useState(false);
  const [contentForPreview, setContentForPreview] = useState(null);
  const [contentForMap, setContentForMap] = useState(null);
  const [contentForPreviewNotFlying, setContentForPreviewNotFlying] = useState(null);
  const [elementMainView, setElementMainView] = useState("map"); //video, sensor, map, images
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  // preview plan
  const [planPreviewDetail, setplanPreviewDetail] = useState(null);
  const [loadingMapPreviewPlan, setLoadingMapPreviewPlan] = useState(false);
  const [zoomPlanMap, setZoomPlanMap] = useState(1);
  const [stationIDPlanStart, setStationIDPlanStart] = useState(null);
  const [stationPlanStart, setStationPlanStart] = useState(null);
  const [isCheckStationPlanStartNotFound, setIsCheckStationPlanStartNotFound] = useState(false);
  const [stationIDPlanEnd, setStationIDPlanEnd] = useState(null);
  const [stationPlanEnd, setStationPlanEnd] = useState(null);
  const [isCheckStationPlanEndNotFound, setIsCheckStationPlanEndNotFound] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [stationTypesDetail, setStationTypesDetail] = useState(null);

  // Sensor
  const [chartSetting, setChartSetting] = useState(initialChartSetting);
  const [dataSensorList, setDataSensorList] = useState(null);
  const [dataSensor, setDataSensor] = useState(null);
  const [isSettingLegendMB, setIsSettingLegendMB] = useState(false);

  const [sizeBrowser, setSizeBrowser] = useState(0);
  const [sizeMain, setSizeMain] = useState(0);

  // Flight Memo
  const [isEditMemo, setIsEditMemo] = useState(false);
  const [memoLatest, setMemoLatest] = useState("");

  const imgPlan = useRef(null);
  const containerImgPlan = useRef(null);
  const textAreaRef = useRef(null);
  const btnAlertOptionsRef = useRef(null);
  const formAlertOptionsRef = useRef(null);
  const droneInfoRef = useRef(null);
  const alertHistoryRef = useRef(null);

  // Kiriha Mode
  const [isKirihaModeByPlan, setIsKirihaModeByPlan] = useState(false);
  const [csvInfo, setCsvInfo] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [faceIdSelected, setFaceIdSelected] = useState(null);
  const [faceIdFlying, setFaceIdFlying] = useState(null);
  const [markerPosition, setMarkerPosition] = useState([]);
  
  // Emergency Stop
  const [isShowBtnEmergency, setIsShowBtnEmergency] = useState(false);
  const [isEmergencyStop, setIsEmergencyStop] = useState({
    active: false,
    blink: false
  });

  const ws = useRef(null);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (deviceDetail?.deviceInfo?.planName !== null) {
      setIsScaning(false);
      setplanPreviewDetail(null);
      setMassageTakeoffNow(null);
    }
    if (deviceDetail && deviceDetail.deviceInfo.planContent) {
      const flightMarker = deviceDetail.deviceInfo.planContent.flight_marker;
      const stationIdFlightStart =
        flightMarker[0].isStation !== "false"
          ? flightMarker[0].isStation
          : false;
      const stationIdFlightEnd =
        flightMarker[flightMarker.length - 1].isStation !== "false"
          ? flightMarker[flightMarker.length - 1].isStation
          : false;

      stationIdFlightStart
        ? getStationFlightStart(stationIdFlightStart)
        : setStationFlightStart(null);
      stationIdFlightEnd
        ? getStationFlightEnd(stationIdFlightEnd)
        : setStationFlightEnd(null);
    }

    if (deviceDetail && deviceDetail.inspection) {
      setFaceIdFlying(deviceDetail.inspection.faceId);

      if (deviceDetail.deviceInfo.planNo !== planFlying) setPlanFlying(deviceDetail.deviceInfo.planNo);
    } else {
      setPlanFlying(null);
    }
  }, [deviceDetail]);

  useEffect(() => {
    if (
      previewSchedule &&
      previewSchedule.length > 0 &&
      deviceDetail.deviceInfo.status === "ready"
    ) {
      setRefPlan(previewSchedule[0].planNo);
    } else {
      setRefPlan(null);
    }
  }, [previewSchedule]);

  useEffect(() => {
    if (refPlan && refPlan !== "marker") {
      const promiseGetPlanDetail = axios.get(Constants.PLAN_DETAIL_URL + refPlan, getHeader());
      const promiseGetTakeoffMemoLatest = axios.get(Constants.PLAN_MEMO_LATEST, { 
        params: {
          planNo: refPlan,
          deviceId: deviceDetail.deviceInfo.id
        }
      }, getHeader());

      const promiseGetMarkerData = axios.get(Constants.CSV_CUSTOMER, {
        params : {
          corpId: corpId,
          flightPlanNo: refPlan,
          mode: "marker"
        },
        headers : { corpKey: corpKey }
      })

      Promise.allSettled([
        promiseGetPlanDetail,
        promiseGetTakeoffMemoLatest,
        promiseGetMarkerData
      ]).then(responseArr => {
        if (responseArr[0].status === "fulfilled" && responseArr[0].value) {
          const { data } = responseArr[0].value;
          const { stationPosition } = data;
          
          getStationTypeDetal(stationPosition);

          setIsKirihaModeByPlan(data.kirihaMode);
          setCsvInfo(data.faceCsvFile);

          const flightMarker = data.jsonContent.flight.flight_marker;
          const stationStart =
            flightMarker[0].isStation !== "false"
              ? flightMarker[0].isStation
              : null;
          const stationEnd =
            flightMarker[flightMarker.length - 1].isStation !== "false"
              ? flightMarker[flightMarker.length - 1].isStation
              : null;

          setStationIDPlanStart(stationStart);
          setStationIDPlanEnd(stationEnd);

          let takeoffItem = {
            id: 0,
            isTakeoff: true,
          };
          let landingItem = {
            id: -1,
            isLanding: true,
          };

          let flightPlanContent = [
            takeoffItem,
            ...data.jsonContent.flight.flight_marker,
            landingItem,
          ];

          setContentForPreviewNotFlying(flightPlanContent);

          stationStart
            ? getStationPlanStart(stationStart)
            : setStationPlanStart(null);
          stationEnd ? getStationPlanEnd(stationEnd) : setStationPlanEnd(null);
          setplanPreviewDetail(data);
          if (!data.mapImage) {
            setLoadingMapPreviewPlan(false);
          }
        }

        if (responseArr[1].status === "fulfilled" && responseArr[1].value && responseArr[1].value.data.takeoffMemo) {
          setMemoLatest(responseArr[1].value.data.takeoffMemo);
          setIsEditMemo(true);
        } else {
          setMemoLatest("");
          setIsEditMemo(false);
        }

        if (responseArr[2].status === "fulfilled" && responseArr[2].value) {
          const markerArr = responseArr[2].value.data.split("\n");
          markerArr.shift();

          const markerPositionCustom = markerArr.map(item => {
            const [id, x ,z] = item.split(',');
            return { id, x, y: "0",z };
          })

          setMarkerPosition(markerPositionCustom);
        }
      })
    } else {
      setMemoLatest("");
      setIsEditMemo(false);
    }
  }, [refPlan]);

  useEffect(() => {
    if (isEditMemo && textAreaRef.current) {
      textAreaRef.current.value = memoLatest;
    }
  }, [isEditMemo])

  useEffect(() => {
    if(formAlertOptionsRef.current && !isMobileOnly) {
      const { bottom, left } = btnAlertOptionsRef.current.getBoundingClientRect();
      formAlertOptionsRef.current.style.left = left + "px";
      formAlertOptionsRef.current.style.top = bottom + 2 + "px";
    }
  }, [isShowOptionsAlert])

  useEffect(() => {
    if(alertHistoryRef.current) {
      const { height } = droneInfoRef.current.getBoundingClientRect();
      alertHistoryRef.current.style.top = height - 1 + "px";
    }
  }, [isShowsAlert])

  useEffect(() => {
    if (csvInfo) {
      axios.get(
        Constants.CSV_CUSTOMER,
        {
          params: {
            corpId: corpId,
            flightPlanNo: planPreviewDetail.planNo,
            mode: "face"
          },
          headers: { corpKey: corpKey }
        },
      ).then(res => {
        const { data } = res;

        if (data) {
          const dataMap = data.split('\n');
          setCsvData(dataMap);
        } else {
          setCsvData([]);
        }
      })
    } else {
      setCsvData(null);
    }
  }, [csvInfo])

  useEffect(() => {
    if (planFlying) {
      axios.get(Constants.CSV_CUSTOMER, {
        params : {
          corpId: corpId,
          flightPlanNo: planFlying,
          mode: "marker"
        },
        headers : { corpKey: corpKey }
      }).then(res => {
        if (res.data) {
          const markerArr = res.data.split("\n");
          markerArr.shift();

          const markerPositionCustom = markerArr.map(item => {
            const [id, x ,z] = item.split(',');
            return { id, x, y: "0",z };
          })

          setMarkerPosition(markerPositionCustom);
        }
      })
    }
  }, [planFlying])

  useEffect(() => {
      imageLoad();
  }, [useWindowSize()])

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSizeBrowser({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return sizeBrowser;
  }

  const fetchData = () => {
    const isDroneNull = !deviceActive.device;
    const isStationNull = !deviceActive.station;
    const isFlying = !isDroneNull && !(
      deviceActive.device.deviceInfo.status === "idle" ||
      deviceActive.device.deviceInfo.status === "charging" ||
      deviceActive.device.deviceInfo.status === "non-charging" ||
      deviceActive.device.deviceInfo.status === "ready"
    )

    let deviceID;

    if(!isDroneNull) {
      deviceID = deviceActive.device.deviceID || deviceActive.device.deviceInfo.deviceID
    }

    const promiseDroneStatus = !isDroneNull && axios.get(Constants.DEVICE_STATUS_URL + droneId, getHeader());
    const promiseStationStatus = !isStationNull && axios.get(Constants.GET_STATION_STATUS + corpId,
      { params: { stationId }, headers: { corpKey } },
      getHeader()
    );
    const promisePlanSimpleList = axios.get(Constants.PLAN_LIST_SIMPLE_URL, getHeader());
    const promisePreviewSchedule = !isDroneNull && axios.get(Constants.SCHEDULE_C_URL, { params: { deviceNo: deviceID } }, getHeader());
    const promiseAllAlert = !isDroneNull && axios.get(Constants.DEVICE_ALERT_ALL_URL + droneId, {
      params: {
        pageSize: 9999999,
        pageNumber: 1
      }
    }, getHeader());

    const promiseSensorInfo = isFlying && axios.get(Constants.SENSORS_INFO, {
      params: {
        corpId: corpId,
        droneId: deviceActive.device.deviceInfo.deviceID,
        flightNo: deviceActive.device.inspection.inspectionNo
      },
      headers: {
        corpKey
      }
    })

    const promiseSensorData = !isDroneNull && axios.get(Constants.SENSORS_INFO + "/" + deviceID, {
      params: { corpId: corpId },
      headers: { corpKey }
    })

    Promise.allSettled([
      promiseDroneStatus,
      promiseStationStatus,
      promisePlanSimpleList,
      promisePreviewSchedule,
      promiseAllAlert,
      promiseSensorInfo,
      promiseSensorData
    ]).then((responseArr) => {
      setLoadingStatus(false);

      if(responseArr[0].value && responseArr[0].status === 'fulfilled') {
        const {data} = responseArr[0].value;
        const { deviceInfo, deviceStatus } = data;

        setDeviceDetail(data);
        setDroneID(deviceInfo.id);
        isFlying && setPlanFlying(deviceInfo.planNo);

        if (
          data &&
          deviceStatus &&
          deviceStatus.length > 0
        ) {
          setContentForPreview(convertDataForPreview(data));
          setContentForMap(convertDataForMap(data));

          if (
            responseArr[6].value && responseArr[6].status === 'fulfilled' &&
            responseArr[6].value.data.markerId !== "-1" &&
            +responseArr[6].value.data.inspectionNo === +data.inspection.inspectionNo
          ) {
            const sensorInfo = {
              sensors: responseArr[6].value.data.content,
              utc: responseArr[6].value.data.measT,
              markerId: responseArr[6].value.data.markerId
            }
      
            setDataSensor(sensorInfo);
          }
        } else {
          if (responseArr[6].value && responseArr[6].status === 'fulfilled') {
            const { data } = responseArr[6].value;
    
            const isFlying = data.markerId !== "-1";
      
            const sensorInfo = {
              sensors: data.content,
              utc: data.measT,
              markerId: isFlying ? data.markerId : ""
            }
      
            setDataSensor(sensorInfo);
          }
        }

        if(responseArr[5].value && responseArr[5].status === 'fulfilled') {
          const takeoffUtc = deviceStatus.find(item => item.status === "takeoff").content.utc;

          setDataSensorList(responseArr[5].value.data);
          initialSettingChart(responseArr[5].value.data, takeoffUtc);
        }

        if (deviceInfo.emergencyStop) {
          const { emergencyStop } = deviceInfo;
          const currentUtc = Date.now() / 1000;
          const isBlink = emergencyStop + claerIsStopEmergencyTime - currentUtc > 0;
          
          setIsShowBtnEmergency(true);
          setIsEmergencyStop(prev => ({
            ...prev, 
            active: true,
            blink: isBlink
          }));

          isBlink && setTimeout(() => {
            setIsEmergencyStop(prev => ({ ...prev, blink: false }));
          }, (emergencyStop + claerIsStopEmergencyTime - currentUtc) * 1000);
        }
      }

      if(responseArr[1].value && responseArr[1].status === 'fulfilled') {
        setStationDetail(responseArr[1].value.data);
        setStationID(responseArr[1].value.data.stationId);
        if(responseArr[1].value.data.isNotFound) {
          setIsCheckStationNotFound(true)
        } else {
          setIsCheckStationNotFound(false)
        }
      } else {
        !isStationNull && setStationDetail(deviceActive.station);
        !isStationNull && setStationID(deviceActive.station?.stationId);
      }

      responseArr[2].value && responseArr[2].status === 'fulfilled' && setPlanSimpleList(responseArr[2].value.data);
      responseArr[3].value && responseArr[3].status === 'fulfilled' && setPreviewSchedule(convertSchedulesPreview(responseArr[3].value.data));
      responseArr[4].value && responseArr[4].status === 'fulfilled' && setAllAlert(responseArr[4].value.data);
    });
  };

  const handleReloadStationDetail = (stationId) => {
    axios
      .get(Constants.GET_STATION_STATUS + corpId, {
        params: { stationId },
        headers: { corpKey },
      })
      .then((res) => {
        if (res.data.droneId === deviceDetail.deviceInfo.deviceID) {
          getStationDetail(stationId);
        }
      });
  };  

  const handleDeviceNotFound = (dataCheck) => {
    const { objectId, objectType } = dataCheck;
    if (+objectType === 1) {
      getDeviceDetail(+objectId);
      setRefPlan(null);
      setIsShowBtnEmergency(false);
    }
    if (+objectType === 2) {
      if (objectId === stationID) {
        getStationDetail(objectId);
      }
      if (objectId === stationIDFlightStart) {
        getStationFlightStart(objectId);
      }
      if (objectId === stationIDFlightEnd) {
        getStationFlightEnd(objectId);
      }
      if (objectId === stationIDPlanStart) {
        getStationPlanStart(objectId);
      }
      if (objectId === stationIDPlanEnd) {
        getStationPlanEnd(objectId);
      }
    }
  };

  const getDeviceDetail = (droneId) => {
    const promiseDroneStatus = axios.get(Constants.DEVICE_STATUS_URL + droneId, getHeader());
    const promiseAllAlert = axios.get(Constants.DEVICE_ALERT_ALL_URL + droneId, { params: { pageSize: 9999999, pageNumber: 1 } }, getHeader());
    
    Promise.all([
      promiseDroneStatus,
      promiseAllAlert
    ]).then((responseArr) => {
      setDroneID(droneId);
      const data = responseArr[0].data;
      const { deviceStatus } = data;

      if (
        data &&
        deviceStatus != null &&
        deviceStatus.length > 0
      ) {
        setContentForPreview(convertDataForPreview(data));
        setContentForMap(convertDataForMap(data));
      } else {
        setFaceIdFlying(null);
      }

      setDeviceDetail(data);
      setAllAlert(responseArr[1].data);
    })
  };

  const getStationDetail = (stationId) => {
    axios
      .get(Constants.GET_STATION_STATUS + corpId, {
        params: { stationId: stationId },
        headers: { corpKey },
      })
      .then((res) => {
        if (
          !deviceActive.device ||
          res.data.droneId === deviceActive.device.deviceInfo.deviceID
        ) {
          if (!deviceDetail) {
            const deviceConnect = deviceList.find(
              (item) => item.device?.deviceInfo.deviceID === res.data.droneId
            )?.device;
            deviceConnect && getDeviceDetail(deviceConnect.deviceInfo.id);
            setStationDetail(res.data);
          } else {
            if (deviceDetail.deviceInfo.deviceID !== res.data.droneId) {
              setStationID(null);
              setStationDetail(null);
            } else {
              setStationDetail(res.data);
              setStationID(res.data.stationId);
            }
          }
          if (!res.data.updated || res.data.isNotFound) {
            setIsCheckStationNotFound(true);
          } else {
            setIsCheckStationNotFound(false);
          }
        } else {
          setStationDetail(null);
        }
      })
      .catch((err) => {
        setStationDetail(deviceActive.station);
        setIsCheckStationNotFound(true);
      });
  };

  const getStationFlightStart = (id) => {
    setStationIDFlightStart(id);

    axios
      .get(Constants.GET_STATION_STATUS + corpId, {
        params: { stationId: id },
        headers: { corpKey },
      })
      .then((res) => {
        setStationFlightStart(res.data);
        res.data.isNotFound
          ? setIsCheckStationFlightStartNotFound(true)
          : setIsCheckStationFlightStartNotFound(false);
      })
      .catch((err) => {
        const station = deviceList.find(
          (item) => item.station?.stationId === id
        );
        station && setStationFlightStart(station.station);
        setIsCheckStationFlightStartNotFound(true);
      });
  };

  const getStationFlightEnd = (id) => {
    setStationIDFlightEnd(id);

    axios
      .get(Constants.GET_STATION_STATUS + corpId, {
        params: { stationId: id },
        headers: { corpKey },
      })
      .then((res) => {
        setStationFlightEnd(res.data);
        res.data.isNotFound
          ? setIsCheckStationFlightEndNotFound(true)
          : setIsCheckStationFlightEndNotFound(false);
      })
      .catch((err) => {
        const station = deviceList.find(
          (item) => item.station?.stationId === id
        );
        station && setStationFlightEnd(station.station);
        setIsCheckStationFlightEndNotFound(true);
      });
  };

  const getStationPlanStart = (id) => {
    setStationIDPlanStart(id);

    axios.get(Constants.GET_STATION_STATUS + corpId, { params: { stationId: id }, headers: { corpKey } }).then((res) => {
      setStationPlanStart(res.data);
      res.data.isNotFound
        ? setIsCheckStationPlanStartNotFound(true)
        : setIsCheckStationPlanStartNotFound(false);
      }).catch((err) => {
        const station = deviceList.find(
          (item) => item.station?.stationId === id
        );
        setStationPlanStart(station.station);
        setIsCheckStationPlanStartNotFound(true);
      });
  };

  const getStationPlanEnd = (id) => {
    setStationIDPlanEnd(id);

    axios
      .get(Constants.GET_STATION_STATUS + corpId, {
        params: { stationId: id },
        headers: { corpKey },
      })
      .then((res) => {
        setStationPlanEnd(res.data);
        res.data.isNotFound
          ? setIsCheckStationPlanEndNotFound(true)
          : setIsCheckStationPlanEndNotFound(false);
      })
      .catch((err) => {
        const station = deviceList.find(
          (item) => item.station?.stationId === id
        );
        setStationPlanEnd(station.station);
        setIsCheckStationPlanEndNotFound(true);
      });
  };

  const getSensorInfo = (message) => {
    if (deviceDetail.deviceInfo.deviceID) {
      const { flightNo, meast } = message;

      const { deviceInfo } = deviceDetail;
      const isCheckFlying =
        deviceInfo.status === "idle" ||
        deviceInfo.status === "charging" ||
        deviceInfo.status === "non-charging" ||
        deviceInfo.status === "ready";

      const promiseGetSensorInfoDetail = axios.get(Constants.SENSORS_INFO + "/" + deviceInfo.deviceID, {
        params: { corpId },
        headers: { corpKey }
      });
      const promiseSensorInfo = !isCheckFlying && axios.get(Constants.SENSORS_INFO, {
        params: { corpId, droneId: deviceInfo.deviceID, flightNo },
        headers: { corpKey }
      })
            
      if (+flightNo !== -1) {
        getSensorList(flightNo);
      }
  
      Promise.allSettled([
        promiseGetSensorInfoDetail,
        promiseSensorInfo
      ]).then((res) => {
        if (res[0].status === "fulfilled" && res[0].value) {
          const { data } = res[0].value;

          const isFlying = data.markerId !== "-1";

          if ((!isCheckFlying && +deviceDetail.inspection.inspectionNo === flightNo) || (isCheckFlying && !isFlying)) {
            const sensorInfo = {
              sensors: data.content,
              utc: data.measT,
              markerId: isFlying ? data.markerId : ""
            }

            if(dataSensor) {
              dataSensor.utc <= meast && setDataSensor(sensorInfo);
            }
          }
        }
        if (res[1].status === "fulfilled" && res[1].value) {
          const { sensor_meas_arr } = res[1].value.data;
          const { statusLegendList } = chartSetting;

          const newStatusLegendList = sensor_meas_arr.map((_, index) => {
            return index < statusLegendList.length ? statusLegendList[index] : false;
          })

          setChartSetting({ ...chartSetting, statusLegendList: newStatusLegendList });
        }
      })
    }
  }

  const getSensorList = (flightNo) => {
    const url = Constants.SENSORS_INFO;
    
    axios.get(url, { 
      params: {
        corpId: corpId,
        droneId: deviceDetail.deviceInfo.deviceID,
        flightNo
      },
      headers: {
        corpKey
      }
    }).then(res => {
      setDataSensorList(res.data);
    }).catch((err) => {
      setDataSensorList(null);
    })
  }

  const getStationTypeDetal = async (stationPs) => {
    const stationTypeIds = [...new Set(stationPs.map(item => item.stationTypeId))];

    try {
      const response = await Promise.allSettled(
        stationTypeIds.map(item => axios.get(Constants.STATION_TYPE_URL + "/" + item, getHeader()).then(res => res.data))
      )

      if (response && response.length) {
        const stationTypeList = [];

        response.forEach(item => {
          if (item.status === "fulfilled" && item.value) {
            stationTypeList.push({
              id: item.value.id,
              path: item.value.imagePath
            })
          }
        })

        setStationTypesDetail(stationTypeList);
      }
    } catch (error) {
      setStationTypesDetail(null);
      console.log(error);
    }
  }

  const initialSettingChart = (sensorsChart, takeoffUtc) => {
    if(sensorsChart) {
      const labels = sensorsChart.meas_t_arr.map(item => +item - takeoffUtc);
      const datasets = sensorsChart.sensor_meas_arr.map((item, index) => {
        const rgb = colorSensor[index];
    
        return {
          label: item.sensor + " [" + item.unit + "]",
          data: item.value_arr,
          borderColor: `rgb(${rgb})`,
          backgroundColor: `rgb(${rgb}, 0.5)`,
        }
      });
      const min = Math.min(...sensorsChart.sensor_meas_arr.map(item => Math.min(...item.value_arr.filter(it => !isNaN(it)))));
      const max = Math.max(...sensorsChart.sensor_meas_arr.map(item => Math.max(...item.value_arr.filter(it => !isNaN(it)))));
      const options = {...initialOptionChart};
      options.scales.y.min = min < 0 ? min : 0;
      options.scales.y.max = undefined;
      options.scales.x.min = 0;
      options.scales.x.max = undefined;
      options.scales.x.ticks.callback = val => val;
  
      const newChartSetting = {...initialChartSetting};
      newChartSetting.statusLegendList = sensorsChart.sensor_meas_arr.map(_ => false);
      newChartSetting.dataChart = { labels, datasets };
      newChartSetting.optionsChart = options;
      newChartSetting.maxX = labels[labels.length - 1];
      newChartSetting.minX = 0;
      newChartSetting.maxY = max;
      newChartSetting.minY = min < 0 ? min : 0;
  
      setChartSetting(newChartSetting);
    }
  }

  const convertSchedulesPreview = (schedules) => {
    const nowUtc = Math.floor(Date.now() / 1000);
    const tempSchedulesConvert = [];

    schedules.forEach((item) => {
      if (+item.utc > nowUtc || item.hour !== "*") {
        tempSchedulesConvert.push(item);
      }
    });

    const schedulesConvert = tempSchedulesConvert.map((item) => {
      if (item.hour === "*") {
        return {
          ...item,
          tempNextUtc: +item.utc,
        };
      }

      let tempNextUtc = getNextTimestamp(
        item.minute,
        item.hour,
        item.day,
        item.month,
        convertDayOfWeek(item.dayOfWeek),
        +item.utc
      );
      return {
        ...item,
        tempNextUtc,
      };
    });

    schedulesConvert.sort((obj_1, obj_2) => {
      if (obj_1.tempNextUtc > obj_2.tempNextUtc) return 1;
      if (obj_1.tempNextUtc < obj_2.tempNextUtc) return -1;
      return 0;
    });

    return schedulesConvert;
  };

  const convertDayOfWeek = (dow) => {
    if (dow === "*") return "*";

    return dow
      .split(",")
      .map((item) => {
        switch (item) {
          case "Sun":
            return 0;
          case "Mon":
            return 1;
          case "Tue":
            return 2;
          case "Wed":
            return 3;
          case "Thu":
            return 4;
          case "Fri":
            return 5;
          case "Sat":
            return 6;

          default:
            return null;
        }
      })
      .join(",");
  };

  const getNextTimestamp = (
    minute,
    hour,
    dayOfMonth,
    month,
    dayOfWeek,
    utcSchedule
  ) => {
    if (minute !== "*" && hour !== "*") {
      const now = new Date();
      let nextTimeStamp = new Date(now.getTime());

      nextTimeStamp.setMinutes(minute);
      nextTimeStamp.setHours(hour);

      if (dayOfMonth !== "*") {
        nextTimeStamp.setDate(parseInt(dayOfMonth));
        if (nextTimeStamp.getTime() < now.getTime()) {
          nextTimeStamp.setMonth(parseInt(now.getMonth() + 1));
        }
      }

      if (dayOfWeek !== "*") {
        const dows = dayOfWeek.split(",").map((item) => +item);
        const nextDow = dows.find((item) => item >= nextTimeStamp.getDay());
        if (nextDow) {
          nextTimeStamp.setDate(
            parseInt(nextTimeStamp.getDate() + nextDow - nextTimeStamp.getDay())
          );
        } else {
          nextTimeStamp.setDate(
            parseInt(
              nextTimeStamp.getDate() + 7 - nextTimeStamp.getDay() + dows[0]
            )
          );
        }

        if (nextTimeStamp.getTime() < now.getTime()) {
          let indexNextDow =
            dows.findIndex((item) => item >= nextTimeStamp.getDay()) + 1;
          if (indexNextDow > dows.length - 1) {
            indexNextDow = 0;
          }
          nextTimeStamp.setDate(
            parseInt(
              nextTimeStamp.getDate() +
                7 -
                nextTimeStamp.getDay() +
                dows[indexNextDow]
            )
          );
        }
      }

      if (nextTimeStamp.getTime() < now.getTime()) {
        nextTimeStamp.setDate(parseInt(now.getDate()) + 1);
      }

      nextTimeStamp.setSeconds(0);
      nextTimeStamp.setMilliseconds(0);

      let nextTimeStampSchedule = nextTimeStamp.getTime() / 1000;

      if (
        utcSchedule &&
        utcSchedule > now.getTime() / 1000 &&
        nextTimeStampSchedule > utcSchedule
      ) {
        nextTimeStampSchedule = utcSchedule;
      }

      return nextTimeStampSchedule;
    }

    return utcSchedule;
  };

  const renderDeviceStatus = (device, station) => {
    if (!device) {
      return (
        <>
          <div className="drone-detail-status-info">
            <div className="device-item">
              <div className="drone device">
                <p className='no-drone'>No drone</p>
              </div>

              {station && 
                <div className="station device">
                  <div className="device-info-wrap">
                    {station && isCheckStationNotFound ? 
                      <>
                        <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag not-found' />
                        <div className='device-info'>
                          <p className="type">Station</p>
                          <p className="name">{station.stationId}</p>
                          <p className="status not-found">Not Found</p>
                        </div>
                      </>
                      :
                      !station ? 
                      <>
                        <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag empty' />
                        <div className='device-info'>
                          <p className="type empty">Station</p>
                          <p className="name"></p>
                          <p className="status"></p>
                        </div>
                      </>
                      :
                      <>
                        {renderStationIcon(station.lid)}
                        <div className='device-info'>
                          <p className="type">Station</p>
                          <p className="name">{station.stationId}</p>
                          <p className={"status " +
                          (getClassName(station.lid)) +
                          (station.lid === "opening" || station.lid === "closing" ? " blink" : "")}>
                            {getStatus(station.lid)}
                          </p>
                        </div>
                      </>
                    }
                  </div>
                  <p className="msg">{station && station.msg.msg1}</p>
                  <p className="msg">{station && station.msg.msg2}</p>
                </div>
              }

              <div className="select-filght-plan disable">
                {renderTakeOffNow()}
              </div>

              <div className="action">
                <button className="btn-takeoff" disabled>Takeoff</button>
              </div>
            </div>

            <div className="flight-process">
              <div className="wrap-result-tracking">
                <div className="no-process">
                  <p>No progress information</p>
                  <p>Select a flight plan</p>
                </div>
              </div>
            </div>
          </div>

          <div className="drone-detail-status-content">
            <PanelGroup
              className="panel-wrapper" 
              onLayout={handleResizeLayout}
              minMain={40}
              minSub={20}
              defaultMain={70}
            >
              <Panel
                className={"main"}
                id={"panel-main"}
              >
                {renderMapPreview(false, true, device)}
              </Panel>

              <Panel className={"sub sub-l"}>
                <div className="preview-schedule">
                  <div className="preview-schedule-header">
                    <h4 className="preview-title">
                      <Images.Icons name="IconSchedule" />
                      {t("status.preview_schedule_title")}
                    </h4>
                  </div>

                  <div className="preview-schedule-content ct-scroll">
                    {renderpreviewSchedule(previewSchedule, t)}
                  </div>
                </div>
              </Panel>

              <Panel className={"sub sub-r"}>
                <div className="preview-sensor">
                  <span className="no-sensor">No sensor</span>
                </div>
              </Panel>
            </PanelGroup>
          </div>
        </>
      );
    } else {
      const { deviceInfo, deviceAlert, deviceStatus, inspection } = device;
      const haveAlert = deviceAlert && deviceAlert.length > 0;
      const isFlying = device && !(
        device.deviceInfo.status === 'idle' ||
        device.deviceInfo.status === 'charging' ||
        device.deviceInfo.status === 'non-charging' ||
        device.deviceInfo.status === 'ready'
      )

      const isCheckDroneNotFound = deviceInfo.status === "idle";
      const isLanded = deviceInfo.status === "landed";

      let takeoffUtc = 0, takeoffMemo;

      if (isFlying && deviceStatus) {
        takeoffUtc = deviceStatus.find(item => item.status === "takeoff").content.utc;
        takeoffMemo = inspection.takeoffMemo;
      }

      const isDisableTakeoff = deviceInfo.status !== "ready" || 
        !refPlan ||
        refPlan !== 'marker' &&
        KIRIHA_MODE && isKirihaModeByPlan &&
        (
          !csvData || 
          !csvData.length ||
          !faceIdSelected
        )

      return (
        <>
          <div className="drone-detail-status-info">
            <div className="device-item">
              <div 
                className="drone device" 
                ref={droneInfoRef} 
                style={haveAlert ? {borderBottom: 0} : undefined}
              >
                <div className="device-info-wrap">
                  {renderDeviceIcon('', device)}
                  <div className='device-info'>
                    <p className="type">{device.deviceInfo.deviceType}</p>
                    <p className="name">{device.deviceInfo.deviceID}</p>
                    <div className='status-battery'>
                      <p 
                        className={
                          "status " + 
                          (isLanded ? "landed" : 
                            getClassName(isFlying ? "now-flying" : device.deviceInfo.status) + (isFlying ? " blink" : "")
                          )
                        }
                      >
                        {isLanded ? "Landed" :
                          isFlying ? "Flying..." : getStatus(device.deviceInfo.status)
                        }
                      </p>
                      <div className={"battery-icon " + (device.deviceInfo.status === 'idle' ? 'not-found' : '')}>
                        <span>{device.deviceInfo.battery || "---"}%</span>
                        <Images.IconBattery />
                      </div>
                    </div>
                  </div>
                  {deviceInfo.status !== "idle" &&
                    <span className={`indicator ${deviceInfo.indicator === "on" ? "active" : "" }`}></span>
                  }
                </div>
                {haveAlert &&
                  <Images.IconAlert />
                }
                <button
                  className={"btn-alert" + (isShowOptionsAlert ? " active" : "")}
                  onClick={handleShowAlertHistory}
                  ref={btnAlertOptionsRef}
                >
                  <Images.IconDots />
                </button>

                {isShowOptionsAlert &&
                  <div className="form-option" ref={formAlertOptionsRef}>
                    <div 
                      className="item" 
                      onClick={() => {
                        setIsShowsAlert(true)
                        setIsShowOptionsAlert(false)
                      }}
                    >Alert history</div>
                    <div className="item bg-reset"></div>
                  </div>
                }

                {deviceInfo.status !== "idle" && deviceInfo.msg && 
                  <p className="msg">{deviceInfo.msg}</p>
                }
              </div>

              {!isFlying &&
                <>
                  {station &&
                    <div className="station device">
                      <div className="device-info-wrap">
                        {station && isCheckStationNotFound ? 
                          <>
                            <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag not-found' />
                            <div className='device-info'>
                              <p className="type">Station</p>
                              <p className="name">{station.stationId}</p>
                              <p className="status not-found">Not Found</p>
                            </div>
                          </>
                          :
                          !station ? 
                          <>
                            <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag empty' />
                            <div className='device-info'>
                              <p className="type empty">Station</p>
                              <p className="name"></p>
                              <p className="status"></p>
                            </div>
                          </>
                          :
                          <>
                            {renderStationIcon(station.lid)}
                            <div className='device-info'>
                              <p className="type">Station</p>
                              <p className="name">{station.stationId}</p>
                              <p className={"status " +
                              (getClassName(station.lid)) +
                              (station.lid === "opening" || station.lid === "closing" ? " blink" : "")}>
                                {getStatus(station.lid)}
                              </p>
                            </div>
                          </>
                        }
                      </div>
                      {(station && station.msg.msg1) && <p className="msg">{station && station.msg.msg1}</p>}
                      {(station && station.msg.msg2) && <p className="msg">{station && station.msg.msg2}</p>}
                    </div>
                  }

                  <div className={"select-filght-plan" + (isCheckDroneNotFound || isLanded ? " disable" : "")}>
                    {renderTakeOffNow()}
                    {deviceInfo.status === "ready" && isKirihaModeByPlan && renderKirihaMode()}
                  </div>

                  <div className="action">
                    <button 
                      className="btn-takeoff"
                      disabled={isDisableTakeoff}
                      onClick={() => takeOffNow(deviceInfo.deviceID, t)}
                    >
                      Takeoff
                    </button>
                    <button 
                      className={"btn-takeoff-memo" + (isEditMemo ? " active" : "")}
                      disabled={deviceInfo.status !== "ready" || !refPlan}
                      onClick={() => setIsEditMemo(!isEditMemo)}
                    >
                      <Images.IconArrowBasic />
                      <Images.IconSensorMd />
                    </button>

                    {isEditMemo &&
                      <textarea 
                        rows={2} 
                        maxLength={256} 
                        className="input-flight-memo"
                        ref={textAreaRef}
                      ></textarea>
                    }

                    <span className="msg-err">{massageTakeoffNow}</span>
                  </div>
                </>
              }
            </div>
            
            {haveAlert &&
              <div className="form-alert">
                <div className="form-alert-header">
                  <div className="alert-icon">
                    Alert
                  </div>
                  <button className="clear-alert" onClick={(e) => clearAlert(e)}>Clear</button>
                </div>
                <div className="form-alert-content">
                  {deviceAlert.map(item => (
                    <div className="alert-item">
                      <p className="time-alert">
                        {isFlying ? 
                          timeMinSecConverter(item.utc - takeoffUtc) + " | " + timeSmallConverter(item.utc)
                          :
                          timeSmallConverter(item.utc)
                        }
                      </p>
                      <p className="text-alert">{item.msg}</p>
                    </div>
                  ))}
                </div>
              </div>
            }

            {isFlying &&
              <>
                <button className="flight-plan" onClick={() => handleViewFlightPlanDetail(inspection.planId)}>{deviceInfo.planName}</button>
                {faceIdFlying && 
                  <p className="face-id">
                    <Images.IconKirihaIcon />
                    <span>Face ID: {faceIdFlying}</span>
                  </p>
                }
              </>
            }

            {isFlying && takeoffMemo &&
              <div className="flight-takeoff-memo">
                <p className="text">
                  <strong>Flight memo :</strong>
                  {takeoffMemo}
                </p>
              </div>
            }

            {(isFlying && deviceInfo.status !== "landed" || isShowBtnEmergency) &&
              <button 
                className={"btn-stop" + (isEmergencyStop.blink ? " blink" : "") + (isEmergencyStop.active ? " active" : "")}
                onClick={() => handleStopFlying(deviceInfo.id)}
              >
                Stop
              </button>
            }

            <StatusPlayingTracking
              data={isFlying ? 
                contentForPreview.length ? contentForPreview : device
                :
                contentForPreviewNotFlying
              }
              contentForPreviewNotFlying={contentForPreviewNotFlying}
              refPlan={refPlan}
              isFlying={isFlying}
              inspectionId={inspection?.id}
              stationStart={isFlying ? stationFlightStart : stationPlanStart}
              stationEnd={isFlying ? stationFlightEnd : stationPlanEnd}
              isStationStartNotFound={isFlying ? isCheckStationFlightStartNotFound : isCheckStationPlanStartNotFound}
              isStationEndNotFound={isFlying ? isCheckStationFlightEndNotFound : isCheckStationPlanEndNotFound}
              t={t}
            />

            {isShowsAlert &&
              <div className="alert-history" ref={alertHistoryRef}>
                <div className="alert-history-header">
                  <h5>Alert history</h5>
                  <button onClick={() => setIsShowsAlert(false)}>
                    <Images.IconClose color="currentColor" />
                  </button>
                </div>

                <div className="alert-history-body">
                  {allAlert.alerts && allAlert.alerts.length ? 
                    allAlert.alerts.map(item => (
                      <div className="alert-history-item">
                        <span>
                          {isFlying ?
                            timeMinSecConverter(+item.utc - +takeoffUtc) + " | " + timeSmallConverter(item.utc)
                            :
                            timeSmallConverter(item.utc)
                          }
                        </span>
                        <p>{item.msg}</p>
                      </div>
                    )) 
                    : undefined
                  }
                </div>

              </div>
            }
          </div>  

          <div className="drone-detail-status-content">
            <PanelGroup
              className="panel-wrapper" 
              onLayout={handleResizeLayout}
              minMain={40}
              minSub={20}
              defaultMain={70}
            >
              {isFlying ? 
                <>
                  <Panel
                    className={
                      (elementMainView === "video" ? "main" : "sub sub-l") +
                      (elementMainView === "video" && isFullScreenMode ? " main-full-screen" : "")
                    }
                    id={elementMainView === "video" ? "panel-main" : ""}
                    onDoubleClick={() => handleDoubleClickPanel("video")}
                  >
                    {renderVideoStreaming(device)}
                  </Panel>

                  <Panel
                    className={
                      (elementMainView === "map" ? "main" :
                      elementMainView === "video" ? "sub sub-l" : "sub sub-r") +
                      (elementMainView === "map" && isFullScreenMode ? " main-full-screen" : "")
                    }
                    id={elementMainView === "map" ? "panel-main" : ""}
                    onDoubleClick={() => handleDoubleClickPanel("map")}
                  >
                    {renderMapPreview(isFlying, isCheckDroneNotFound, device)}
                  </Panel>

                  {elementMainView !== "images" &&
                    <Panel
                      className={
                        (elementMainView === "sensor" ? "main" :"sub sub-r") +
                        (elementMainView === "sensor" && isFullScreenMode ? " main-full-screen" : "")
                      }
                      id={elementMainView === "sensor" ? "panel-main" : ""}
                      onDoubleClick={() => handleDoubleClickPanel("sensor")}
                    >
                      {renderPreviewSensor(device, isFlying, takeoffUtc, elementMainView === "sensor" ? true : false)}
                    </Panel>
                  }

                  {elementMainView === "images" &&
                    <Panel
                      className={
                        "main" + 
                        (elementMainView === "images" && isFullScreenMode ? " main-full-screen" : "")
                      }
                      id={"panel-main"}
                    >
                      <ImgaesViewStatusComponent device={device} />
                    </Panel>
                  }

                  <div 
                    className={"main-actions ct-scroll" + (isFullScreenMode ? " main-actions-full-screen" : "")}
                  >
                    <div className="actions">
                      <button 
                        className="btn-full-screen"
                        onClick={() => setIsFullScreenMode(!isFullScreenMode)}
                      >
                        {isFullScreenMode ?
                          <Images.IconCloseFullScreen color="currentColor" />
                          :
                          <Images.IconFullScreen color="currentColor" />
                        }
                      </button>

                      <button 
                        className={"btn-stream" + (elementMainView === 'video' ? ' active' : "")} 
                        onClick={() => changeViewMain("video")}
                      >
                        <Images.IconCamera color="currentColor" />
                      </button>

                      <button 
                        className={"btn-images" + (elementMainView === 'images' ? ' active' : "")}
                        onClick={() => changeViewMain("images")}
                      >
                        <Images.IconPictureList color="currentColor" />
                      </button>

                      <button 
                        className={"btn-map" + (elementMainView === 'map' ? ' active' : "")} 
                        onClick={() => changeViewMain("map")}
                      >
                        <Images.IconMapProcess color="currentColor" />
                      </button>

                      <button 
                        className={"btn-sensor" + (elementMainView === 'sensor' ? ' active' : "")}
                        onClick={() => changeViewMain("sensor")}
                      >
                        <Images.SensorIcon color="currentColor" />
                      </button>
                    </div>
                    {renderFlightHeight(device, isLanded)}
                  </div>
                </>
                :
                <>
                  <Panel className={"main"} id={"panel-main"} style={{ padding: 0 }}>
                    {renderMapPreview(false, isCheckDroneNotFound, device)}
                  </Panel>

                  <Panel className={"sub sub-l"}>
                    <div className="preview-schedule">
                      <div className="preview-schedule-header">
                        <h4 className="preview-title">
                          <Images.Icons name="IconSchedule" />
                          {t("status.preview_schedule_title")}
                        </h4>
                      </div>

                      <div className="preview-schedule-content ct-scroll">
                        {renderpreviewSchedule(previewSchedule, t)}
                      </div>
                    </div>
                  </Panel>

                  <Panel className={"sub sub-r"}>
                    {dataSensor && !isCheckDroneNotFound ?
                      renderPreviewSensor(device, isFlying, takeoffUtc)
                      :
                      <div className="preview-sensor">
                        <span className="no-sensor">No sensor</span>
                      </div>
                    }
                  </Panel>
                </>
              }
            </PanelGroup>
          </div>
        </>
      );
    }
  };

  const handleViewFlightPlanDetail = (planId) => {
    const { device, station } = deviceActive;
    history.push("/plan", { 
      planId, 
      data: {
        deviceId: device?.deviceInfo?.id, 
        stationId: station?.stationId, 
        deviceActive
      } 
    });
  }

  const handleStopFlying = (deviceId) => {
    if(deviceId && !isEmergencyStop.blink) {
      if (isEmergencyStop.active) {
        confirmAlert({
          title: t("status.emergency_stop.label_clear"),
          message: "",
          buttons: [
            {
              label: t("dialog_delete.option_no"),
            },
            {
              label: t("status.emergency_stop.clear_text"),
              onClick: () => {
                axios.post(Constants.DEVICE_STOP + deviceId + "/clear", { headers: getHeader() }).then(res => {
                  if (res.data) {
                    setIsEmergencyStop(prev => ({ 
                      ...prev, 
                      active: false, 
                      blink: false
                    }));
                  };
                })
              },
            }
          ],
        });
      } else {
        axios.post(Constants.DEVICE_STOP + deviceId, { headers: getHeader() }).then(res => {
          if (res.data) {
            setIsEmergencyStop(prev => ({ 
              ...prev, 
              active: true, 
              blink: true
            }));

            setTimeout(() => {
              setIsEmergencyStop(prev => ({ ...prev, blink: false }));
            }, claerIsStopEmergencyTime * 1000);
          };
        })
      }
    }
  }

  const handleResizeLayout = (sizes) => {
    imageLoad();
    setSizeMain(sizes[0]);
  }

  const changeViewMain = (element) => {
    setElementMainView(element); //video, sensor, map, images
  }

  const handleDoubleClickPanel = (element) => {
    if (elementMainView !== element && !isSettingLegendMB) {
      setElementMainView(element); //video, sensor, map, images
    }
  }

  const renderpreviewSchedule = (data) => {
    if (data.length === 0) {
      return (
        <div className="table-preview">
          <div className="table-row">
            <div className="table-col">No Scheduled</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-preview">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="table-row"
                onClick={() => changeRouteToShedule(item)}
              >
                <div className="table-col">{timeConverter(item.tempNextUtc, t)}</div>
                <div className="table-col">{item.scheduleName}</div>
                <div className="table-col">{item.planName}</div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderPreviewSensor = (device, isFlying, takeoffUtc, isMainView) => {
    let planNo = null;

    if (isFlying) planNo = device.inspection.planNo;

    return (
      <DeviceSonsorPreview
        isMainView={isMainView}
        dataSensorList={dataSensorList}
        dataSensor={dataSensor}
        takeoffUtc={takeoffUtc}
        planNo={planNo}
        isFlying={isFlying}
        sizeBrowser={sizeBrowser}
        sizeMain={sizeMain}
        isSettingLegendMB={isSettingLegendMB}
        setIsSettingLegendMB={(status) => setIsSettingLegendMB(status)}
        elementMainView={elementMainView}
        chartSetting={chartSetting}
        updateChartSetting={(newChartSetting) => setChartSetting(newChartSetting)}
        colorSensor={colorSensor}
      />
    )
  }

  const renderFlightHeight = (device, isLanded) => {
    const { inspection: { jsonContent: { flight: { flight_marker } } }, deviceStatus } = device;
    const currentMarkerIndex = deviceStatus.filter(item => item.status === "marker_arv").length;

    let heightTry = 100;
    let maxHeight = 0,
        height = flight_marker[currentMarkerIndex - 1]?.height;
    flight_marker.forEach((item) => {
      if (maxHeight < +item.height) {
        maxHeight = Math.ceil(+item.height) + 3;
      }
    });

    if (KIRIHA_MODE && device.inspection.jsonContent.kirihaMode && device.inspection.kirihaData) {
      const { content: { dronePosition: { z } } } = deviceStatus.at(-1);

      if (z !== null) {
        height = Number(z);
      }
    }

    if (isLanded) {
      height = 0;
    }

    heightTry = maxHeight * 25;

    let position = heightTry * (height / maxHeight);

    const getHeightDrone = {
      transform: `translate(-50%, -${position}px)`,
    };

    const getLineStep = () => {
      let temp = "";
      Array.from(Array(maxHeight).keys()).forEach((item, index, arr) => {
        temp += `0 ${(item + 1) * 25}px 0 #fff`;
  
        if (index !== arr.length - 1) {
          temp += ", ";
        }
      })

      return temp;
    }

    return (
      <div className="flight-height">
        <div className="flight-height_content">
          {Array.from(Array(maxHeight + 1).keys()).map(item => (
            <span className="step_height">{item}m</span>
          ))}
        </div>
        <div className="flight-height_try">
          <div
            className="try_vertical"
            style={{ height: heightTry + "px" }}
          ></div>
          <div className="try_horizontal" style={{ boxShadow: getLineStep() }}></div>
          <div className="drone_position" style={getHeightDrone}></div>
        </div>
      </div>
    );
  }

  const changeRouteToShedule = (item) => {
    if (!isMobileOnly) {
      history.push({ pathname: "/schedule", state: { data: item } });
    }
  };

  const renderDeviceIcon = (c, item) => {
    if (accountType === "robot") {
      let iconUrl;
      deviceIcons.map((item) => {
        if (item.key === "ROBOT_ICON" && item.value) {
          iconUrl = Constants.getResourceUrl() + item.value;
        }
      });

      if (!iconUrl) {
        iconUrl = Images.IconRobot;
      }

      return (
        <img
          alt=""
          className={"device-icon-tag " + (c ? c : "")}
          src={iconUrl}
        />
      );
    } else if (item) {
      const { deviceInfo } = item;
      if (deviceInfo.imagePath) {
        let iconUrl = Constants.getResourceUrl() + deviceInfo.imagePath;

        return (
          <img
            alt=""
            className={
              "device-icon-tag " +
              (c ? c : "") +
              (deviceInfo.status === "idle" ? "not-found" : "")
            }
            src={iconUrl}
          />
        );
      } else {
        return (
          <Images.Icons
            name="IconPlane"
            className={
              "device-icon-tag " +
              (c ? c : "") +
              (deviceInfo.status === "idle" ? "not-found" : "")
            }
          />
        );
      }
    } else {
      return (
        <Images.Icons name="IconPlane" className="device-icon-tag empty" />
      );
    }
  };

  const renderStationIcon = (status, isCheckNotFound) => {
    if (isCheckNotFound)
      return (
        <img
          src={require("../../assets/images/cs-closed.png")}
          alt=""
          className="device-icon-tag not-found"
        />
      );

    switch (status) {
      case "closed":
        return (
          <img src={require("../../assets/images/cs-closed.png")} alt="" className="device-icon-tag" />
        );
      case "closing":
        return (
          <img src={require("../../assets/images/cs-closeing.png")} alt="" className="device-icon-tag" />
        );
      case "opening":
        return (
          <img src={require("../../assets/images/cs-opening.png")} alt="" className="device-icon-tag" />
        );
      case "opened":
        return (
          <img src={require("../../assets/images/cs-opened.png")} alt="" className="device-icon-tag" />
        );

      default:
        return (
          <img
            src={require("../../assets/images/cs-closed.png")}
            alt=""
            className="device-icon-tag not-found"
          />
        );
    }
  };

  const renderTakeOffNow = () => {
    const data = [];

    data.push({ value: "marker", label: "Scan marker" }); // add scan option

    planSimpleList.forEach(item => {
      data.push({
        value: item.planNo,
        label: `[${item.planNo}] - ${item.planName}`
      })
    })

    return (
      <SelectCustomComponent
        firstTitle={t("status.default_select")}
        value={refPlan}
        data={data}
        onChange={(e) => changePlanPreview(e)}
      />
    );
  };

  const takeOffNow = (deviceNo) => {
    const url = Constants.SCHEDULE_TAKEOFF_NOW_URL + deviceNo;
    
    const params = {
      marker: refPlan === "marker",
      planNo: refPlan === "marker" || !refPlan ? "" : refPlan,
      takeoffMemo: "",
      faceId: faceIdSelected || ""
    };

    if(textAreaRef.current) {
      params.takeoffMemo = textAreaRef.current.value;
    }

    if (!params.planNo && refPlan !== "marker") {
      NotificationManager.error(t("status.take_off_now.pls_select_plan"), "", 2000);
      setMassageTakeoffNow(null);
    } else if (
      refPlan !== "marker" &&
      params.planNo &&
      stationDetail &&
      stationIDPlanStart !== stationDetail.stationId
    ) {
      setMassageTakeoffNow(t("status.take_off_now.check_plan_takeoff"));
    } else {
      refPlan === "marker" && setIsScaning(true);
      doPut(
        url,
        null,
        () => {
          toast.success(t("status.take_off_now.success"), {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true
          });
          NotificationManager.success(
            t("status.take_off_now.success"),
            "",
            2000
          );
          setMassageTakeoffNow(null);
          setIsShowBtnEmergency(true);
          setIsEmergencyStop({ 
            active: isEmergencyStop.active || false, 
            blink: isEmergencyStop.blink || false 
          });
        },
        () => {
          NotificationManager.error(t("status.take_off_now.error"), "", 2000);
        },
        params
      );
    }
  };

  const renderKirihaMode = useCallback(() => {
    if (KIRIHA_MODE && refPlan && refPlan !== 'marker') {
      if (isKirihaModeByPlan && (!csvData || !csvData.length)) {
        return (
          <div className="kiriha-mode">
            <span className="no-data-kiriha">No Kiriha information available.</span>
          </div>
        )
      }

      const csvDataHandle = [...csvData];
      csvDataHandle.shift();
      const dataSelect = csvDataHandle.map(item => {
        const itemData = item.split(',')[0];
        return {
          value: itemData,
          label: itemData
        };
      })
      
      return (
        <div className="kiriha-mode">
          <span className="kiriha-icon"><Images.IconKirihaIcon /></span>
          <SelectCustomComponent
            firstTitle={"Face ID"}
            value={faceIdSelected}
            data={dataSelect}
            id='kiriha-select'
            onChange={(kirihaNo) => setFaceIdSelected(kirihaNo)}
          />
        </div>
      )
    }
  }, [isKirihaModeByPlan, csvInfo, csvData, refPlan, faceIdSelected])

  const renderMapPreview = (isCheckFlying, isCheckDroneNotFound, device) => {
    if (refPlan === "marker" && !isCheckFlying) {
      if (isScaning) {
        return (
          <p className="choose-marker opening">
            Scanning marker to get flight plan...
          </p>
        );
      } else {
        return (
          <p className="choose-marker">Flight plan will be got by marker</p>
        );
      }
    } else {
      return (
        <>
          {isCheckFlying ? 
            renderDeviceTracking(device) 
            : 
            isCheckDroneNotFound || device.deviceInfo.status === 'landed' ||  !planPreviewDetail ?
              <div className="pls-select">
                <p>No plan view</p>
                <p>Select a flight plan</p>
              </div> 
              :
              renderPreviewPlanMap(planPreviewDetail, t)
          }
        </>
      );
    }
  };

  const renderMarkerInMapKirihaMode = (data, stationPs) => {
    const { axis, originPosition, calibration } = data;
    const { dronePosition } = deviceDetail.deviceInfo;
    
    if (axis.x !== null && axis.y !== null && markerPosition) {
      const angle = calculateAngle(
        { x: originPosition.left, y: originPosition.top },
        { x: calibration.left, y: calibration.top },
        axis, zoomPlanMap
      );

      const pointMark = generatePointMark(
        { x: originPosition.left + 1, y: originPosition.top },
        { x: originPosition.left, y: originPosition.top },
        angle, zoomPlanMap
      );

      const ratio =  generateRatio(
        { x: originPosition.left, y: originPosition.top },
        pointMark, 
        { x: calibration.left, y: calibration.top },
        axis, angle, zoomPlanMap
      )

      const rotatePoint = (currentPoint) => {
        const alpha = (angle * Math.PI) / 180;
        const cosAlpha = Math.cos(alpha);
        const sinAlpha = Math.sin(alpha);
        
        const deltaX = Number(currentPoint.x) * ratio;
        const deltaY = Number(currentPoint.y) * ratio * -1;
        
        const xPrime = deltaX * cosAlpha - deltaY * sinAlpha;
        const yPrime = deltaX * sinAlpha + deltaY * cosAlpha;
    
        return({ x: xPrime, y: yPrime });
      }

      const dronePs = dronePosition ? rotatePoint(dronePosition) : undefined;
      
      return (
        <div className="marker-preview-ready">
          {markerPosition.map(marker => {
            if (marker.x && marker.y) {
              const markerRotate = rotatePoint({ x: marker.x, y: marker.y });

              return (
                <div 
                  className="marker-item"
                  style={{
                    transform: `translate(
                      ${originPosition.left * zoomPlanMap + markerRotate.x}px,
                      ${originPosition.top * zoomPlanMap + markerRotate.y}px
                    )`
                  }}
                >
                  <div className="marker-icon">
                    <Images.IconMarkerPreview />
                  </div>
                </div>
              )
            }
          })}

          {stationPs && stationPs.length && stationPs.map(station => {
            if (station.x !== null && station.y !== null) {
              const point = rotatePoint({ x: station.x, y: station.y });
              const url = stationTypesDetail?.find(item => item.id === station.stationTypeId)?.path;

              return (
                <div 
                  className="station-preview" 
                  style={{
                    transform: `translate(
                      calc(${originPosition.left * zoomPlanMap + point.x}px - 50%), 
                      calc(${originPosition.top * zoomPlanMap + point.y}px - 50%)
                    )`
                  }}
                >
                  <div className="station-icon">
                    <img 
                      alt="station"
                      src={Constants.getResourceUrl() + url}
                    />
                  </div>
                </div>
              )
            }
          })}

          {dronePs &&
            <div 
              className="drone-flight"
              style={{
                transform: `translate(
                  calc(${originPosition.left * zoomPlanMap + dronePs.x}px - 50%), 
                  calc(${originPosition.top * zoomPlanMap + dronePs.y}px - 50%)
                )`
              }}
            >
              <div className="drone-icon">
                <span className="ripple pinkBg"></span>
                <span className="ripple pinkBg"></span>
                <Images.IconDroneFlyingPreview />
              </div>
            </div>
          }
        </div>
      )
    }
  }

  const renderPreviewPlanMap = (plan) => {
    const { planContent: { flight: { speed, flight_marker }, user: { place, division } }, planNo, kirihaMode, mapEditorContentKiriha, stationPosition, markerPosition } = plan;

    const totalDistance = getTotalDistance(flight_marker, markerPosition, stationPosition)
    const imageUrl = plan.mapEditorImage;

    return (
      <div className="preview-plan-map">
        <div className="preview-plan-map-img">
          {imageUrl ? (
            <div className="status-map-view" ref={containerImgPlan}>
              {loadingMapPreviewPlan &&
                <div className="loading">
                  <img
                    alt="loading"
                    src={require("../../assets/images/loading.gif")}
                  />
                </div>
              }

              <div className="status-map-view-wrapper">
                <img
                  ref={imgPlan}
                  onLoad={imageLoad}
                  src={Constants.getResourceUrl() + imageUrl}
                  alt=""
                />

                {mapEditorContentKiriha && 
                  renderMarkerInMapKirihaMode(mapEditorContentKiriha, stationPosition)
                }
              </div>
            </div>
          ) : (
            <p className="not-setting">
              Not Setting
            </p>
          )}
        </div>

        <div className="preview-plan-map-info">
          <div className="preview-plan-map-info-item">
            <p>Flight Plan No</p>
            <span>{planNo}</span>
          </div>
          <div className="preview-plan-map-info-item">
            <p>Flight Place</p>
            <span>{place}</span>
          </div>
          <div className="preview-plan-map-info-item">
            <p>Division</p>
            <span>{division}</span>
          </div>
          <div className="preview-plan-map-info-item">
            <p>Speed</p>
            <span>{speed}</span>
          </div>
          <div className="preview-plan-map-info-item">
            <p>Number of marker</p>
            <span>{flight_marker.length}</span>
          </div>
          <div className="preview-plan-map-info-item">
            <p>Total distance</p>
            <span>{totalDistance}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderVideoStreaming = (device) => {
    const channelName = device.deviceInfo.deviceID;
    const ipServerStreaming = Constants.STAND_ALONE_IP_SERVER_MEDIAMTX;

    let urlStreaming = ""; 

    if (Constants.STAND_ALONE) {
      urlStreaming = "http://" + ipServerStreaming + ":8889/" + channelName + "/";
    } else {
      urlStreaming = "https://" + ipServerStreaming + "/" + channelName + "/";
    }

    return (
      <div className="streaming">
        <VideoStreamingComponent videoStreamUrl={urlStreaming} />
      </div>
    )
  }

  const imageLoad = () => {
    const elem = imgPlan.current;
    const imgContainer = containerImgPlan.current;

    if(imgContainer && elem) {
      const zoomX = imgContainer.clientWidth / elem.naturalWidth;
      const zoomY = imgContainer.clientHeight / elem.naturalHeight;

      const zoom = Math.min(zoomX, zoomY);

      elem.width = elem.naturalWidth * zoom;
      elem.height = elem.naturalHeight * zoom;


      setIsImageLoaded(true)
      setZoomPlanMap(zoom);
      setLoadingMapPreviewPlan(false);
    }
  };

  const changePlanPreview = (val) => {
    setIsImageLoaded(false);
    setMassageTakeoffNow(null);
    setFaceIdSelected(null);

    if (val === "marker") {
      setRefPlan(val);
      setLoadingMapPreviewPlan(false);
      setplanPreviewDetail(null);
      setContentForPreviewNotFlying(null);
    } else if (val !== "false") {
      setRefPlan(val);
      setLoadingMapPreviewPlan(true);
    } else {
      setplanPreviewDetail(null);
      setRefPlan(null);

      setContentForPreviewNotFlying(null);
    }
  };

  const renderDeviceTracking = (data) => {
    if (data && data.deviceStatus != null && data.deviceStatus.length > 0) {
      return (
        <MapPreviewStatusKirihaMode
          inspection={data.inspection}
          deviceStatus={data.deviceStatus}
          sizeMain={sizeMain}
          elementMainView={elementMainView}
          isFullScreenMode={isFullScreenMode}
        />
      );
    }
  };

  const handleFullMapOpen = () => {
    setIsFullMapView(true);
  };

  const renderMapModal = (mapContentDisplay, currentMarker, endMarker) => {
    let data = deviceDetail;

    if (data) {
      return (
        <Modal
          centered
          className="full-map-view"
          dialogClassName="modal-fullscreen"
          show={isFullMapView}
          onHide={(e) => handleZoomClose(e)}
          style={{ zIndex: 999999 }}
        >
          <Modal.Header>
            <div className="modal-title">
              <h3>
                <Images.IconPlane />
                {data.deviceInfo.deviceID}
              </h3>
              <h3>
                <Images.IconScan />
                {data.deviceInfo.planName}
              </h3>
            </div>
            <button onClick={(e) => handleZoomClose(e)} style={{ marginLeft: 'auto' }}>
              <Images.IconClose />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div
              className="title-modal-status-mobile"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                className="modal-status-mobile"
                style={{ width: "100%", flexGrow: 1 }}
              >
                <MapPreviewStatusFC
                  url={data.inspection.mapImage}
                  markers={contentForMap}
                  markerPreview={contentForPreview}
                  id="2"
                  currentMarker={currentMarker}
                  endMarker={endMarker}
                  t={t}
                  isShow={true}
                />
              </div>
              <div className="play-full_screen">
                {stationFlightStart ? (
                  <div className="charging-station-status">
                    <span
                      className={
                        isCheckStationFlightStartNotFound
                          ? ""
                          : getClassName(stationFlightStart.lid)
                      }
                    >
                      {isCheckStationFlightStartNotFound
                        ? "Not Found"
                        : getStatus(stationFlightStart.lid)}
                    </span>
                    <p>{stationFlightStart.stationId}</p>
                    {renderStationIcon(
                      stationFlightStart.lid,
                      isCheckStationFlightStartNotFound
                    )}
                  </div>
                ) : (
                  <div className="charging-station-status">
                    <p>Empty</p>
                    <img
                      src={require("../../assets/images/cs-closed.png")}
                      alt=""
                      className="empty"
                    />
                  </div>
                )}
                <StatusPlayingTracking
                  data={data.deviceStatus.length > 0 ? contentForPreview : data}
                  inspectionId={data.inspection.id}
                  t={t}
                />
                {stationFlightEnd ? (
                  <div className="charging-station-status">
                    <span className={isCheckStationFlightEndNotFound ? ""
                      : getClassName(stationFlightEnd.lid)}
                    >
                      {isCheckStationFlightEndNotFound
                        ? "Not Found"
                        : getStatus(stationFlightEnd.lid)}
                    </span>
                    <p>{stationFlightEnd.stationId}</p>
                    {renderStationIcon(
                      stationFlightEnd.lid,
                      isCheckStationFlightEndNotFound
                    )}
                  </div>
                ) : (
                  <div className="charging-station-status">
                    <p>Empty</p>
                    <img
                      src={require("../../assets/images/cs-closed.png")}
                      alt=""
                      className="empty"
                    />
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  };

  const handleZoomClose = () => {
    setTimeout(() => {
      setIsFullMapView(false);
    }, 10);
  };

  const clearAlert = (e) => {
    e.preventDefault();
    const url =
      Constants.DEVICE_STATUS_URL + deviceDetail.deviceInfo.id + "/clearAlert";
    const alertIds = deviceDetail.deviceAlert.map((alert) => alert.id);

    doPost(
      url,
      {
        ids: alertIds,
      },
      () => {
        const device = { ...deviceDetail, deviceAlert: [] };
        setDeviceDetail(device);
      }
    );
  };

  const getClassName = (param) => {
    switch (param) {
      //drone
      case "idle":
        return "not-found";
      case "charging":
        return "charging";
      case "non-charging":
        return "non-charging";
      case "ready":
        return "ready";
      case "landed":
        return "landed";
      case "empty":
        return "empty";
      case "now-flying":
        return "now-flying";
      case "charging-empty":
        return "charging-empty";

      //station
      case "opened":
        return "opened";
      case "closed":
        return "closed";
      case "opening":
        return "opening";
      case "closing":
        return "closing";

      default:
        return;
    }
  };

  const getStatus = (param) => {
    switch (param) {
      //drone
      case "idle":
        return "Not Found";
      case "charging":
        return "Charging";
      case "non-charging":
        return "Non Charging";
      case "ready":
        return "Ready";
      case "takeoff":
        return "";
      case "marker_arv":
        return "";
      case "marker_left":
        return "";
      case "image":
        return "";
      case "video":
        return "";
      case "landed":
        return "Landed";

      //station
      case "closed":
        return "Closed";
      case "opened":
        return "Opened";
      case "closing":
        return "Closing";
      case "opening":
        return "Opening";

      default:
        return "Not Found";
    }
  };

  const convertDataForPreview = (data) => {
    let mapContentDisplay = [];
    const { deviceStatus: deviceStatusHandle, deviceInfo } = data;
    const deviceStatus = deviceStatusHandle.filter(item => item.status !== "kiriha_st" && item.status !== "kiriha_end" && item.status !== "btw_marker");
    const planMarker = deviceInfo.planContent.flight_marker;
    const lastPointInPlan = deviceInfo.planContent.flight_marker[deviceInfo.planContent.flight_marker.length - 1];
    let isBreak = false,
      latestIndex = 0,
      latestMarker,
      flightPlanContent = data.inspection.jsonContent.flight.flight_marker;

    if (accountType !== "robot") {
      let takeoffItem = {
        id: 0,
        isTakeoff: true,
      };
      let landingItem = {
        id: -1,
        isLanding: true,
      };

      flightPlanContent = [
        takeoffItem,
        ...data.inspection.jsonContent.flight.flight_marker,
        landingItem,
      ];
    } else {
      flightPlanContent = data.inspection.jsonContent.flight.flight_marker;
    }

    const deviceStatusMapPlanContent = deviceStatus.filter(item => item.type !== "media" && item.status !== "takeoff" && item.status !== "landed");
    let totalMarkerPass = deviceStatusMapPlanContent.filter(item => item.status === "marker_arv")?.length;

    flightPlanContent.map((item, i, arr) => {
      if (accountType !== "robot") {
        if (item.isTakeoff) {
          const takeoff = deviceStatus.find((it) => it.status === "takeoff");
          mapContentDisplay.push({ ...takeoff, ...item });
          latestMarker = 0;
          latestIndex = 1;
          return;
        }
        if (item.isLanding) {
          const landing = deviceStatus.find((it) => it.status === "landed");
          mapContentDisplay.push({ ...landing, ...item });
          latestMarker = -1;
          return;
        }
  
        let markerContent = {},
          trackingPassItem = false,
          makerArrTime = null,
          makerLeftTime = null,
          markerInfo;

        if(i > totalMarkerPass) isBreak = true;

        if (!isBreak) {
          for (let index = latestIndex; index < deviceStatus.length; index++) {
            const markerStatus = deviceStatus[index];
            if (
              markerStatus.type !== "media" && 
              markerStatus.status === "marker_arv" &&
              (latestIndex !== index || +markerStatus.content.marker_id !== +latestMarker)
            ) {
              latestMarker = markerStatus.content.marker_id;
              latestIndex = index;
              break;
            }
          }

          for (let index = latestIndex; index < deviceStatus.length; index++) {
            const markerStatus = deviceStatus[index];

            if(markerStatus.type === "media") continue;
            
            if (
              +markerStatus.content.marker_id !== +latestMarker || 
              (markerStatus.status === "marker_arv" && latestIndex !== index) ||
              +item.id !== +latestMarker
            ) break;
  
            if (markerStatus.status === "marker_arv") {
              makerArrTime = markerStatus.content.utc;
            }

            if (markerStatus.status === "marker_left") {
              makerLeftTime = markerStatus.content.utc;
              if (i < planMarker.length && (planMarker.length || containsObject(lastPointInPlan, planMarker))) {
                item["nextMarker"] = planMarker[i].id;
              } else {
                item["nextMarker"] = -1;
              }
            }
            
            markerInfo = { ...markerStatus };
            markerContent = {
              ...markerStatus.content,
              ...markerContent,
            };
            trackingPassItem = true;
  
            if (index === deviceStatus.length - 1 && i < arr.length - 1) {
              isBreak = true;
            }
          }
        }

        item = { ...markerInfo, ...item };
        item["content"] = markerContent;
        item["trackingPassItem"] = trackingPassItem;
        item["makerArrTime"] = makerArrTime;
        item["makerLeftTime"] = makerLeftTime;
  
        mapContentDisplay.push(item);
      } else {
        let markerInfo, markerContent;
        let trackingPassItem = false;

        deviceStatus
          .filter((filterItem) => "" + filterItem.content.id === "" + item.id)
          .map((markerStatus) => {
            markerInfo = { ...markerStatus };
            markerContent = { ...markerContent, ...markerStatus.content };
            trackingPassItem = true;
          });
        item = { ...markerInfo, ...item };
        item["content"] = markerContent;
        item["trackingPassItem"] = trackingPassItem;

        if ("" + item.id === "1") {
          var takeoff = deviceStatus.find(function (result) {
            return result.status === "takeoff";
          });

          item["content"] = takeoff.content;
          item["status"] = "marker_left";
          item["trackingPassItem"] = true;
        }

        mapContentDisplay.push(item);
      }
    });

    const medias = deviceStatus.filter(item => item.status === "image");

    if(medias.length) {
      mapContentDisplay = mapContentDisplay.map((item, index, arr) => {
        if(index < arr.length - 1) {
          if(item.isTakeoff) {
            const markerLeftImages = medias.filter(it => {
              if(arr[index + 1].makerArrTime) return +it.content.utc >= +item.content.utc && +it.content.utc < +arr[index + 1].makerArrTime
              return +it.content.utc >= +item.content.utc
            });
            let urlsMarkerLeft = "";
            if(markerLeftImages.length) {
              urlsMarkerLeft = markerLeftImages.map(it => it.content.url).join("");
            }
            return {
              ...item, 
              content: {
                ...item.content,
                markerLeftImages: urlsMarkerLeft
              }
            }
          }

          if(!item.makerLeftTime) {
            const markerImages = medias.filter(it => +it.content.utc >= +item.makerArrTime && item.makerArrTime);
            let urlsMarker = "";
            if(markerImages.length) {
              urlsMarker = markerImages.map(it => it.content.url).join("");
            }

            return {
              ...item,
              content: {
                ...item.content,
                url: urlsMarker,
              }
            };
          }

          const markerImages = medias.filter(it => +it.content.utc >= +item.makerArrTime && +it.content.utc < +item.makerLeftTime);
          let markerLeftImages = medias.filter(it => {
            if(item.nextMarker === -1) return +it.content.utc >= +item.makerLeftTime;
            if(arr[index + 1].makerArrTime) return +it.content.utc >= +item.makerLeftTime && +it.content.utc < +arr[index + 1].makerArrTime;
            return +it.content.utc >= +item.makerLeftTime;
          });
          let urlsMarker = "", urlsMarkerLeft = "";

          if(markerImages.length) {
            urlsMarker = markerImages.map(it => it.content.url).join("");
          }
          
          if(markerLeftImages.length) {
            urlsMarkerLeft = markerLeftImages.map(it => it.content.url).join("");
          }

          return {
            ...item,
            content: {
              ...item.content,
              url: urlsMarker,
              markerLeftImages: urlsMarkerLeft
            }
          };
        } 
        return item;
      });
    }

    return mapContentDisplay;
  };

  const convertDataForMap = (data) => {
    let mapContentDisplay = [];
    let flightPlanContent;
    let deviceStatus = data.deviceStatus.filter(item => item.status !== "kiriha_st" && item.status !== "kiriha_end");
    let devicePlan = cloneDeep(data.deviceInfo.planContent);
    let lastPointInPlan =
      data.deviceInfo.planContent.flight_marker[
        data.deviceInfo.planContent.flight_marker.length - 1
      ];
    if (data.inspection.mapContent) {
      flightPlanContent = data.inspection.mapContent;
    } else {
      if (accountType !== "robot") {
        let takeoffItem = {
          id: 0,
          isTakeoff: true,
        };
        let landingItem = {
          id: -1,
          isLanding: true,
        };

        flightPlanContent = [
          takeoffItem,
          ...data.inspection.jsonContent.flight.flight_marker,
          landingItem,
        ];
      } else {
        flightPlanContent = data.inspection.jsonContent.flight.flight_marker;
      }
    }

    flightPlanContent.map((item, index) => {
      if (accountType !== "robot") {
        if ("" + item.id === "0") {
          var takeoff = deviceStatus.find(function (result) {
            return result.status === "takeoff";
          });
          item = { ...takeoff, ...item };

          mapContentDisplay.push(item);
        } else {
          let markerInfo, markerContent;
          let trackingPassItem = false;
          let devicePlan = cloneDeep(data.deviceInfo.planContent);
          let planMarker = devicePlan.flight_marker;
          let makerArrTime = null;
          deviceStatus
            .filter((filterItem) => filterItem.status === "marker_arv")
            .map((markerStatus) => {
              if (markerStatus.status === "marker_arv") {
                var i = planMarker.length;
                if (
                  markerStatus.content &&
                  "" + markerStatus.content.id === "" + item.id
                ) {
                  makerArrTime = +markerStatus.content.utc;
                }
                planMarker.some(function (itemP, indexP, objectP) {
                  if (itemP.id === markerStatus.content.marker_id) {
                    objectP.splice(indexP, 1);
                    return true;
                  }
                  return false;
                });
              }
            });
          let countMarkerArv = 0;
          deviceStatus
            .filter(
              (filterItem) =>
                "takeoff" !== filterItem.status &&
                ("" + filterItem.content.id === "" + item.id ||
                  filterItem.content.marker_id === "" + item.id)
            )
            .forEach((markerStatus, index) => {
              if (markerStatus.status == "marker_arv") {
                countMarkerArv++;
              }
              markerInfo = { ...markerStatus };
              // if (markerStatus.status === "image" && markerContent) {
              //   let url = markerStatus.content.url;
              //   if (!markerContent.url) {
              //     markerContent.url = markerStatus.content.url;
              //   } else {
              //     if (markerStatus.content.url) {
              //       let arr = markerStatus.content.url.split(" ");
              //       for (let el of arr) {
              //         if (el && !markerContent.url.includes(el.trim())) {
              //           markerContent.url += " " + el.trim();
              //         }
              //       }
              //     }
              //   }
              //   markerStatus.content.url = markerContent.url;
              // }
              markerContent = { ...markerContent, ...markerStatus.content };
              trackingPassItem = true;

              if (markerStatus.status === "marker_left") {
                if (
                  planMarker.length == 0 ||
                  !containsObject(lastPointInPlan, planMarker)
                ) {
                  item["nextMarker"] = -1;
                } else {
                  let nextPlanIndex = countMarkerArv;
                  item["nextMarker"] = planMarker[nextPlanIndex]?.id;
                }
              }
            });
          item = { ...markerInfo, ...item };
          item["content"] = markerContent;
          item["trackingPassItem"] = trackingPassItem;
          item["makerArrTime"] = makerArrTime;
          mapContentDisplay.push(item);
        }
      } else {
        let markerInfo, markerContent;
        let trackingPassItem = false;

        deviceStatus
          .filter((filterItem) => "" + filterItem.content.id === "" + item.id)
          .map((markerStatus) => {
            markerInfo = { ...markerStatus };
            markerContent = { ...markerContent, ...markerStatus.content };
            trackingPassItem = true;
          });
        item = { ...markerInfo, ...item };
        item["content"] = markerContent;
        item["trackingPassItem"] = trackingPassItem;

        if (index == 0) {
          var takeoff = deviceStatus.find(function (result) {
            return result.status === "takeoff";
          });

          item["content"] = takeoff.content;
          item["status"] = "marker_left";
          item["trackingPassItem"] = true;
        }

        mapContentDisplay.push(item);
      }
    });

    return mapContentDisplay;
  };

  const containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id && list[i].direction === obj.direction) {
        return true;
      }
    }

    return false;
  };

  const handleShowAlertHistory = (e) => {
    setIsShowOptionsAlert(!isShowOptionsAlert);
  }

  const renderDeviceStatusMobile = (device, station) => {
    if (!device) {
      return (
        <>
          <div className="drone-detail-status-info">
            <div className="device-item">
              <div className="drone device">
                <p className='no-drone'>No drone</p>
              </div>

              {station && 
                <div className="station device">
                  <div className="device-info-wrap">
                    {station && isCheckStationNotFound ? 
                      <>
                        <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag not-found' />
                        <div className='device-info'>
                          <p className="type">Station</p>
                          <p className="name">{station.stationId}</p>
                          <p className="status not-found">Not Found</p>
                        </div>
                      </>
                      :
                      !station ? 
                      <>
                        <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag empty' />
                        <div className='device-info'>
                          <p className="type empty">Station</p>
                          <p className="name"></p>
                          <p className="status"></p>
                        </div>
                      </>
                      :
                      <>
                        {renderStationIcon(station.lid)}
                        <div className='device-info'>
                          <p className="type">Station</p>
                          <p className="name">{station.stationId}</p>
                          <p className={"status " +
                          (getClassName(station.lid)) +
                          (station.lid === "opening" || station.lid === "closing" ? " blink" : "")}>
                            {getStatus(station.lid)}
                          </p>
                        </div>
                      </>
                    }
                  </div>
                  <p className="msg">{station && station.msg.msg1}</p>
                  <p className="msg">{station && station.msg.msg2}</p>
                </div>
              }

              <div className="select-filght-plan disable">
                {renderTakeOffNow()}
              </div>

              <div className="action">
                <button className="btn-takeoff" disabled>Takeoff</button>
              </div>
            </div>

            <div className="flight-process">
              <div className="wrap-result-tracking">
                <div className="no-process">
                  <p>No progress information</p>
                  <p>Select a flight plan</p>
              </div>
              </div>
            </div>
          </div>

          <div className="drone-detail-status-content">
            <div className="card-content">
              {renderMapPreview(false, true, device)}
            </div>
            <div className="card-content">
              <div className="preview-schedule">
                <div className="preview-schedule-header">
                  <h4 className="preview-title">
                    <Images.Icons name="IconSchedule" />
                    {t("status.preview_schedule_title")}
                  </h4>
                </div>

                <div className="preview-schedule-content ct-scroll">
                  {renderpreviewSchedule(previewSchedule, t)}
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="preview-sensor">
                <span className="no-sensor">No sensor</span>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      const { deviceInfo, deviceAlert, deviceStatus, inspection } = device;
      const haveAlert = deviceAlert && deviceAlert.length > 0;
      const isFlying = device && !(
        device.deviceInfo.status === 'idle' ||
        device.deviceInfo.status === 'charging' ||
        device.deviceInfo.status === 'non-charging' ||
        device.deviceInfo.status === 'ready'
      )

      const isCheckDroneNotFound = deviceInfo.status === "idle";
      const isLanded = deviceInfo.status === "landed";

      let takeoffUtc = 0, takeoffMemo;

      if (isFlying && deviceStatus) {
        takeoffUtc = deviceStatus.find(item => item.status === "takeoff").content.utc;
        takeoffMemo = inspection.takeoffMemo;
      }

      return (
        <>
          <ToastContainer />
          <div className="drone-detail-status-info">
            <div className="device-item">
              <div 
                className="drone device" 
                ref={droneInfoRef} 
                style={haveAlert ? {borderBottom: 0} : undefined}
              >
                <div className="device-info-wrap">
                  {renderDeviceIcon('', device)}
                  <div className='device-info'>
                    <p className="type">{device.deviceInfo.deviceType}</p>
                    <p className="name">{device.deviceInfo.deviceID}</p>
                    <div className='status-battery'>
                      <p 
                        className={
                          "status " + 
                          (isLanded ? "landed" : 
                            getClassName(isFlying ? "now-flying" : device.deviceInfo.status) + (isFlying ? " blink" : "")
                          )
                        }
                      >
                        {isLanded ? "Landed" :
                          isFlying ? "Flying..." : getStatus(device.deviceInfo.status)
                        }
                      </p>
                      <div className={"battery-icon " + (device.deviceInfo.status === 'idle' ? 'not-found' : '')}>
                        <span>{device.deviceInfo.battery || "---"}%</span>
                        <Images.IconBattery />
                      </div>
                    </div>
                  </div>
                </div>
                {haveAlert &&
                  <Images.IconAlert />
                }
                <button
                  className={"btn-alert" + (isShowOptionsAlert ? " active" : "")}
                  onClick={handleShowAlertHistory}
                  ref={btnAlertOptionsRef}
                >
                  <Images.IconDots />
                </button>

                {isShowOptionsAlert &&
                  <div className="form-option" ref={formAlertOptionsRef}>
                    <div 
                      className="item" 
                      onClick={() => {
                        setIsShowsAlert(true)
                        setIsShowOptionsAlert(false)
                      }}
                    >Alert history</div>
                    <div className="item bg-reset"></div>
                  </div>
                }

                {deviceInfo.status !== "idle" && deviceInfo.msg && 
                  <p className="msg">{deviceInfo.msg}</p>
                }
              </div>

              {!isFlying &&
                <>
                  {station &&
                    <div className="station device">
                      <div className="device-info-wrap">
                        {station && isCheckStationNotFound ? 
                          <>
                            <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag not-found' />
                            <div className='device-info'>
                              <p className="type">Station</p>
                              <p className="name">{station.stationId}</p>
                              <p className="status not-found">Not Found</p>
                            </div>
                          </>
                          :
                          !station ? 
                          <>
                            <img src={require('../../assets/images/cs-closed.png')} alt="" className='device-icon-tag empty' />
                            <div className='device-info'>
                              <p className="type empty">Station</p>
                              <p className="name"></p>
                              <p className="status"></p>
                            </div>
                          </>
                          :
                          <>
                            {renderStationIcon(station.lid)}
                            <div className='device-info'>
                              <p className="type">Station</p>
                              <p className="name">{station.stationId}</p>
                              <p className={"status " +
                              (getClassName(station.lid)) +
                              (station.lid === "opening" || station.lid === "closing" ? " blink" : "")}>
                                {getStatus(station.lid)}
                              </p>
                            </div>
                          </>
                        }
                      </div>
                      {(station && station.msg.msg1) && <p className="msg">{station && station.msg.msg1}</p>}
                      {(station && station.msg.msg2) && <p className="msg">{station && station.msg.msg2}</p>}
                    </div>
                  }

                  <div className={"select-filght-plan" + (isCheckDroneNotFound || isLanded ? " disable" : "")}>
                    {renderTakeOffNow()}
                    {deviceInfo.status === "ready" && isKirihaModeByPlan && renderKirihaMode()}
                  </div>

                  <div className="action">
                    {isShowBtnEmergency &&
                      <button 
                        className={"btn-stop" + (isEmergencyStop.blink ? " blink" : "") + (isEmergencyStop.active ? " active" : "")}
                        onClick={() => handleStopFlying(deviceInfo.id)}
                      >
                        Stop
                      </button>
                    }
                    
                    <button 
                      className="btn-takeoff"
                      disabled={deviceInfo.status !== "ready" || !refPlan}
                      onClick={() => takeOffNow(deviceInfo.deviceID, t)}
                    >
                      Takeoff
                    </button>
                    <button 
                      className={"btn-takeoff-memo" + (isEditMemo ? " active" : "")}
                      disabled={deviceInfo.status !== "ready" || !refPlan}
                      onClick={() => setIsEditMemo(!isEditMemo)}
                    >
                      <Images.IconArrowBasic />
                      <Images.IconSensorMd />
                    </button>

                    {isEditMemo &&
                      <textarea 
                        rows={2} 
                        maxLength={256} 
                        className="input-flight-memo"
                        ref={textAreaRef}
                      ></textarea>
                    }

                    <span className="msg-err">{massageTakeoffNow}</span>
                  </div>
                </>
              }
            </div>
            
            {haveAlert &&
              <div className="form-alert">
                <div className="form-alert-header">
                  <div className="alert-icon">
                    Alert
                  </div>
                  <button className="clear-alert" onClick={(e) => clearAlert(e)}>Clear</button>
                </div>
                <div className="form-alert-content">
                  {deviceAlert.map(item => (
                    <div className="alert-item">
                      <p className="time-alert">
                        {isFlying ? 
                          timeMinSecConverter(item.utc - takeoffUtc) + " | " + timeSmallConverter(item.utc)
                          :
                          timeSmallConverter(item.utc)
                        }
                      </p>
                      <p className="text-alert">{item.msg}</p>
                    </div>
                  ))}
                </div>
              </div>
            }

            {isFlying &&
              <>
                <div className="flight-plan">
                  <button className="btn-swith-plan" onClick={() => handleViewFlightPlanDetail(inspection.planId)}>{deviceInfo.planName}</button>

                  {(isFlying && deviceInfo.status !== "landed" || isShowBtnEmergency) &&
                    <button 
                      className={"btn-stop" + (isEmergencyStop.blink ? " blink" : "") + (isEmergencyStop.active ? " active" : "")}
                      onClick={() => handleStopFlying(deviceInfo.id)}
                    >
                      Stop
                    </button>
                  }
                </div>
                {faceIdFlying && 
                  <p className="face-id">
                    <Images.IconKirihaIcon />
                    <span>Face ID: {faceIdFlying}</span>
                  </p>
                }
              </>
            }

            {isFlying && takeoffMemo &&
              <div className="flight-takeoff-memo">
                <p className="text">
                  <strong>Flight memo :</strong>
                  {takeoffMemo}
                </p>
              </div>
            }

            <StatusPlayingTracking
              data={isFlying ? 
                contentForPreview.length ? contentForPreview : device
                :
                contentForPreviewNotFlying
              }
              contentForPreviewNotFlying={contentForPreviewNotFlying}
              refPlan={refPlan}
              isFlying={isFlying}
              inspectionId={inspection?.id}
              stationStart={isFlying ? stationFlightStart : stationPlanStart}
              stationEnd={isFlying ? stationFlightEnd : stationPlanEnd}
              isStationStartNotFound={isFlying ? isCheckStationFlightStartNotFound : isCheckStationPlanStartNotFound}
              isStationEndNotFound={isFlying ? isCheckStationFlightEndNotFound : isCheckStationPlanEndNotFound}
              t={t}
            />

            {isShowsAlert &&
              <div className="alert-history" ref={alertHistoryRef}>
                <div className="alert-history-header">
                  <h5>Alert history</h5>
                  <button onClick={() => setIsShowsAlert(false)}>
                    <Images.IconClose color="currentColor" />
                  </button>
                </div>

                <div className="alert-history-body">
                  {allAlert.alerts && allAlert.alerts.length ? 
                    allAlert.alerts.map(item => (
                      <div className="alert-history-item">
                        <span>
                          {isFlying ?
                            timeMinSecConverter(+item.utc - +takeoffUtc) + " | " + timeSmallConverter(item.utc)
                            :
                            timeSmallConverter(item.utc)
                          }
                        </span>
                        <p>{item.msg}</p>
                      </div>
                    )) 
                    : undefined
                  }
                </div>

              </div>
            }
          </div>  

          <div className="drone-detail-status-content">
            {isFlying ?
              <div className="drone-detail-status-content-wrap-mb">
                <div className="main-actions">
                  <div className="actions">
                    <button 
                      className={"btn-sensor" + (elementMainView === 'sensor' ? ' active' : "")}
                      onClick={() => changeViewMain("sensor")}
                    >
                      <Images.SensorIcon color="currentColor" />
                    </button>

                    <button 
                      className={"btn-map" + (elementMainView === 'map' ? ' active' : "")} 
                      onClick={() => changeViewMain("map")}
                    >
                      <Images.IconMapProcess color="currentColor" />
                    </button>

                    <button 
                      className={"btn-images" + (elementMainView === 'images' ? ' active' : "")}
                      onClick={() => changeViewMain("images")}
                    >
                      <Images.IconPictureList color="currentColor" />
                    </button>

                    <button 
                      className={"btn-stream" + (elementMainView === 'video' ? ' active' : "")} 
                      onClick={() => changeViewMain("video")}
                    >
                      <Images.IconCamera color="currentColor" />
                    </button>
                  </div>
                </div>

                {elementMainView === "images" &&
                  <div className="card-content active">
                    <ImgaesViewStatusComponent device={device} />
                  </div>
                } 

                <div 
                  className={"card-content" + (elementMainView === "video" ? " active" : "")}
                  onClick={() => handleDoubleClickPanel("video")}
                >
                  {renderVideoStreaming(device)}
                </div>

                <div 
                  className={"card-content" + (elementMainView === "map" ? " active" : "")}
                  onClick={() => handleDoubleClickPanel("map")}
                >
                  {renderMapPreview(isFlying, isCheckDroneNotFound, device)}
                </div>

                <div 
                  className={"card-content" + (elementMainView === "sensor" ? " active" : "")}
                  onClick={() => handleDoubleClickPanel("sensor")}
                >
                  {renderPreviewSensor(device, isFlying, takeoffUtc, elementMainView === "sensor" ? true : false)}
                </div>
              </div>
              :
              <>
                <div className="card-content">
                  {renderMapPreview(false, isCheckDroneNotFound, device)}
                </div>

                <div className="card-content">
                  <div className="preview-schedule">
                    <div className="preview-schedule-header">
                      <h4 className="preview-title">
                        <Images.Icons name="IconSchedule" />
                        {t("status.preview_schedule_title")}
                      </h4>
                    </div>

                    <div className="preview-schedule-content ct-scroll">
                      {renderpreviewSchedule(previewSchedule, t)}
                    </div>
                  </div>
                </div>

                <div className="card-content" style={dataSensor && !dataSensor.sensors ? { display: "none" } : undefined}>
                  {dataSensor && !isCheckDroneNotFound ?
                    renderPreviewSensor(device, isFlying, takeoffUtc)
                    :
                    <div className="preview-sensor">
                      <span className="no-sensor">No sensor</span>
                    </div>
                  }
                </div>
              </>
            }
          </div>
        </>
      );
    }
  }

  if(loadingStatus) {
    return (
      <div className="loading">
        <img
          alt="loading"
          src={require("../../assets/images/loading.gif")}
        />
      </div>
    )
  }

  return (
    <>
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/not-found/" + JSON.parse(localStorage.getItem("user")).id]}
        onMessage={(msg) => {
          handleDeviceNotFound(msg);
        }}
        ref={ws}
      />
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/drone/" + JSON.parse(localStorage.getItem("user")).id]}
        onMessage={(msg) => {
          if (msg === droneID) {
            getDeviceDetail(msg);
          }
        }}
        ref={ws}
      />
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/station/" + JSON.parse(localStorage.getItem("user")).id]}
        onMessage={(msg) => {
          if (!stationID) {
            handleReloadStationDetail(msg);
          }
          if (msg === stationID) {
            getStationDetail(msg);
          }
          if (msg === stationIDPlanStart) {
            getStationPlanStart(msg);
          }
          if (msg === stationIDPlanEnd) {
            getStationPlanEnd(msg);
          }
          if (msg === stationIDFlightStart) {
            getStationFlightStart(msg);
          }
          if (msg === stationIDFlightEnd) {
            getStationFlightEnd(msg);
          }
        }}
        ref={ws}
      />
      <SockJsClient
        url={Constants.WS_URL}
        topics={["/topic/drone/sensor/" + JSON.parse(localStorage.getItem("user")).id]}
        onMessage={(msg) => {
          getSensorInfo(msg);
        }}
        ref={ws}
      />

      <div className="drone-detail-status">
        {isMobileOnly ?
          renderDeviceStatusMobile(deviceDetail, stationDetail)
          :
          renderDeviceStatus(deviceDetail, stationDetail)
        }
      </div>
    </>
  );
};

export default memo(DeveiceDetail);
